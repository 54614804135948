import React from 'react';

import DropdownIcon from '../../../assets/images/components/DropdownIcon';
import './shopFilter.scss';
import ShopColorFilterContent from './ShopColorFilterContent/ShopColorFilterContent';
import ShopTextFilterContent from './ShopTextFilterContent/ShopTextFilterContent';
import ShopChipsFilterContent from './ShopChipsFilterContent/ShopChipsFilterContent';
import ShopPriceFilterContent from './ShopPriceFilterContent/ShopPriceFilterContent';
import ShopDistanceFilterContent from './ShopDistanceFilterContent/ShopDistanceFilterContent';

/**
 * Usage:
 * <ShopTextFilter title="title">
 *     content
 * </ShopTextFilter>
 */

const OPTION_ALL_INDEX = -1;
const OPTION_TEXT_TYPE = 'text';
const OPTION_COLOR_TYPE = 'color';
const OPTION_CHIPS_TYPE = 'chips';
const OPTION_PRICE_TYPE = 'price';
const OPTION_DISTANCE_TYPE = 'distance';

class ShopFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: props.filterData.isActive,
      currentOptionSelected: OPTION_ALL_INDEX
    };
  }

  dropdownToggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  renderFilterContent() {
    const {
      filterData,
      selectedFilterData,
      toggleSelectedFilterOptionsAndFilterProducts,
      selectAllFilterOptionAndFilterProducts,
      activatePriceFilterAndFilterProducts,
      deactivatePriceFilterAndFilterProducts,
      activateDistanceFilterAndFilterProducts,
      deactivateDistanceFilterAndFilterProducts
    } = this.props;
    let filterContent;

    switch (filterData.type) {
      case OPTION_TEXT_TYPE:
        filterContent = (
          <ShopTextFilterContent
            filterData={filterData}
            selectedFilterData={selectedFilterData}
            toggleSelectedFilterOptionsAndFilterProducts={
              toggleSelectedFilterOptionsAndFilterProducts
            }
            selectAllFilterOptionAndFilterProducts={
              selectAllFilterOptionAndFilterProducts
            }
          />
        );
        break;
      case OPTION_COLOR_TYPE:
        filterContent = (
          <ShopColorFilterContent
            filterData={filterData}
            selectedFilterData={selectedFilterData}
            toggleSelectedFilterOptionsAndFilterProducts={
              toggleSelectedFilterOptionsAndFilterProducts
            }
            selectAllFilterOptionAndFilterProducts={
              selectAllFilterOptionAndFilterProducts
            }
          />
        );
        break;
      case OPTION_CHIPS_TYPE:
        filterContent = (
          <ShopChipsFilterContent
            filterData={filterData}
            selectedFilterData={selectedFilterData}
            toggleSelectedFilterOptionsAndFilterProducts={
              toggleSelectedFilterOptionsAndFilterProducts
            }
            selectAllFilterOptionAndFilterProducts={
              selectAllFilterOptionAndFilterProducts
            }
          />
        );
        break;
      case OPTION_PRICE_TYPE:
        filterContent = (
          <ShopPriceFilterContent
            filterData={filterData}
            selectedFilterData={selectedFilterData}
            activatePriceFilterAndFilterProducts={
              activatePriceFilterAndFilterProducts
            }
            deactivatePriceFilterAndFilterProducts={
              deactivatePriceFilterAndFilterProducts
            }
          />
        );
        break;
      case OPTION_DISTANCE_TYPE:
        filterContent = (
          <ShopDistanceFilterContent
            filterData={filterData}
            selectedFilterData={selectedFilterData}
            activateDistanceFilterAndFilterProducts={
              activateDistanceFilterAndFilterProducts
            }
            deactivateDistanceFilterAndFilterProducts={
              deactivateDistanceFilterAndFilterProducts
            }
          />
        );
        break;
    }

    return filterContent;
  }

  render() {
    const { isActive } = this.state;
    const { className = '', color = '#000', filterData } = this.props;

    return (
      <div
        className={`ikt-ws_shop-filter${isActive ? ` ikt-ws_is-active ` : ``}${
          className ? ` ${className}` : ``
        }`}
      >
        <div
          className="ikt-ws_shop-filter-trigger"
          onClick={this.dropdownToggle}
        >
          <div className="ikt-ws_shop-filter-trigger-title">
            {filterData.title}
          </div>
          <DropdownIcon
            className="ikt-ws_dropdown-custom-chevron"
            color={color}
          />
        </div>
        {this.renderFilterContent()}
      </div>
    );
  }
}

export default ShopFilter;
