import React, {Component} from 'react';

class Icon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isIconHover: false
        };
    }

    toggleIconHover = () => {
        this.setState({
            isIconHover: !this.state.isIconHover
        })
    }

    render() {
        const { icon, color, colorHover, ...rest } = this.props;
        const { isIconHover } = this.state;

        return icon({
                color: isIconHover ? colorHover : color,
                onMouseEnter: this.toggleIconHover,
                onMouseLeave: this.toggleIconHover,
                ...rest
                });
    }
}

export default Icon;
