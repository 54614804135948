import React from 'react';

const DropdownIcon = (props) => {
    const { color = '#000', className = '' } = props;

    return (
        <svg className={className} width="11px" height="7px" viewBox="0 0 11 7"
             version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>UI/dropdown</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1909.000000, -239.000000)" stroke={color}>
                    <polyline id="UI/dropdown" points="1910 240 1914.87175 244.871752 1919.7435 240"></polyline>
                </g>
            </g>
        </svg>
    );
}

export default DropdownIcon;
