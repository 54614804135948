import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getFeaturedProductsSuccessful,
  getFeaturedProductsFailed
} from '../store/actions/featuredProductsActions';
import * as FeaturedProductsActionTypes from '../store/actionTypes/featuredProductsActionTypes';
import { featuredProducts } from './epicsData';

export const getFeaturedProducts = actions$ => {
  return actions$.pipe(
    ofType(FeaturedProductsActionTypes.GET_FEATURED_PRODUCTS),
    delay(2000),
    mapTo(getFeaturedProductsSuccessful(featuredProducts)),
    catchError((error, caught) =>
      merge(of(getFeaturedProductsFailed(error)), caught)
    )
  );
};
