import * as UserAccountActionTypes from '../actionTypes/userAccountActionTypes';
import { customCabinets } from '../../epics/epicsData';

const initState = {
  userProfile: {
    image: null
  },
  orders: [],
  customCabinets: [],
  userAccountError: null,
  userAccountBusy: false
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case UserAccountActionTypes.GET_USER_ACCOUNT_DATA:
      return {
        ...state,
        userAccountBusy: true
      };
    case UserAccountActionTypes.GET_USER_ACCOUNT_DATA_SUCCESSFUL:
      const userAccountData = action.payload;

      return {
        ...userAccountData,
        userAccountBusy: false
      };
    case UserAccountActionTypes.GET_USER_ACCOUNT_DATA_FAILED:
      return {
        ...state,
        userAccountError: action.payload.message,
        userAccountBusy: false
      };
    case UserAccountActionTypes.ADD_CUSTOM_CABINET:
      const cabinet = action.payload;
      customCabinets.push(cabinet);

      return {
        ...state,
        customCabinets
      };
    default:
      return state;
  }
};

export default authReducer;
