import React from 'react';
import DropdownImage from '../../../common/DropdownImage/DropdownImage';
import './selectMaterial.scss';

export const SelectMaterial = props => {
  const { heading, hint, items, onClick } = props;

  return (
    <div className="ikt-ws_select-materials-container">
      <div className="ikt-ws_select-materials-heading">{heading}</div>
      <div className="ikt-ws_select-materials-hint">{hint}</div>
      <DropdownImage
        className="ikt-ws_select-materials-dropdown"
        items={items}
        showPlaceholder={true}
        onClick={onClick}
      />
    </div>
  );
};

export default SelectMaterial;
