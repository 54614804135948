import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { toast } from 'react-toastify';

import {addProduct, removeProduct} from '../../../store/actions/cartActions';
import ButtonShaded from '../../common/ButtonShaded/ButtonShaded';
import { renderPriceWithoutCents } from '../../../helpers/priceHelpers';
import Counter from '../../common/Counter/Counter';
import RemoveIcon from '../../../assets/images/btnIcons/delete.svg';

class Product extends React.Component {
    handleCounterChange = (amount) => {
        const {data} = this.props;
        const newProduct = {...data};
        newProduct.amount = amount;
        this.props.addProduct(newProduct);
    };

    handleRemoveProduct = () => {
        const {data} = this.props;
        this.props.removeProduct(data.id);
    };

    handleOrderAgain = () => {
        const {data} = this.props;
        const newProduct = {...data};
        this.props.addProduct(newProduct);
        toast.success(`Product "${newProduct.title}" was added to cart (with amount ${newProduct.amount})`);
    };

    render () {
        const {data, isOrderPage, isOrdersPage, sizes, materials, colors} = this.props;

        let size = sizes && sizes.find((currSize) => {
            return currSize.id === data.sizeId
        });
        size = size || {};

        let material = materials && materials.find((currMaterial) => {
            return currMaterial.id === data.materialId
        });
        material = material || {};

        let color = colors && colors.find((currColor) => {
            return currColor.id === data.colorId
        });
        color = color || {};

        return (
            <div className="product">
                <div className="columns is-marginless product-content">
                    <div className="column is-1">
                        <div style={{backgroundImage: `url(${data.image})`}} className="product-img"/>
                    </div>
                    <div className="column is-3 product-title">
                        <div className="title">{data.title}</div>
                        <div className="description">{`${material.value} (${size.value})`}</div>
                    </div>
                    <div className="column is-1 product-color">
                        <span>Color</span>
                        <div className="color-selection" style={{backgroundColor: color.value}}/>
                    </div>
                    <div className="column is-2">
                        {
                            (isOrderPage || isOrdersPage)
                                ? <div>{`X ${data.amount}`}</div>
                                : <Counter onChange={this.handleCounterChange} initVal={data.amount}/>
                        }
                    </div>
                    <div className="column is-2 product-price">{`$ ${data.price}`}</div>
                    <div className="column is-1 product-total-price">
                        {`$ ${data.amount ? data.amount * data.price : data.price}`}
                    </div>
                    <div className="column is-2 product-remove-btn">
                        {
                            isOrdersPage
                                ? <ButtonShaded onClick={this.handleOrderAgain}>
                                    ORDER AGAIN
                                  </ButtonShaded>
                                : !isOrderPage
                                    ? <div onClick={this.handleRemoveProduct}><img src={RemoveIcon} /></div>
                                    : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        colors: state.colors.colors,
        materials: state.materials.materials,
        sizes: state.sizes.sizes,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        removeProduct: (product) => dispatch(removeProduct(product)),
        addProduct: (product) => dispatch(addProduct(product)),
    }
};

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Product));
