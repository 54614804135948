import React from 'react';

import CheckboxNormal from '../../../assets/images/checkboxes/chbox1-norm.svg';
import CheckboxHover from '../../../assets/images/checkboxes/chbox1-hover.svg';
import CheckboxChecked from '../../../assets/images/checkboxes/chbox10-pressed.svg';
import './checkboxTeal.scss';

class CheckboxTeal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.isChecked || false,
      isHover: false
    };
  }

  toggleChecked = () => {
    const { onClick } = this.props;
    const isChecked = !this.state.isChecked;
    this.setState({
      isChecked
    });
    onClick(isChecked);
  };

  toggleIconHover = () => {
    this.setState({
      isHover: !this.state.isHover
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isChecked } = this.state;
    if (isChecked !== this.props.isChecked) {
      this.setState({
        isChecked: this.props.isChecked
      });
    }
  }

  render() {
    const { isChecked, isHover } = this.state;
    const { className } = this.props;

    let iconImg;
    if (isChecked) {
      iconImg = CheckboxChecked;
    } else if (isHover) {
      iconImg = CheckboxHover;
    } else {
      iconImg = CheckboxNormal;
    }

    return (
      <div
        className={`ikt-ws_checkbox-teal${className ? ` ${className}` : ``}`}
      >
        <img
          onClick={this.toggleChecked}
          onMouseEnter={this.toggleIconHover}
          onMouseLeave={this.toggleIconHover}
          src={iconImg}
        />
      </div>
    );
  }
}

export default CheckboxTeal;
