import * as UserAccountActionTypes from '../actionTypes/userAccountActionTypes';

export const getUserAccountData = () => {
  return { type: UserAccountActionTypes.GET_USER_ACCOUNT_DATA, payload: null };
};

export const getUserAccountDataSuccessful = userAccountData => {
  return {
    type: UserAccountActionTypes.GET_USER_ACCOUNT_DATA_SUCCESSFUL,
    payload: userAccountData
  };
};

export const getUserAccountDataFailed = (error) => {
  return {
    type: UserAccountActionTypes.GET_USER_ACCOUNT_DATA_FAILED,
    payload: error
  };
};

export const addCustomCabinet = cabinet => {
  return { type: UserAccountActionTypes.ADD_CUSTOM_CABINET, payload: cabinet };
};
