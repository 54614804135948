import React, { Component } from 'react';
import ManufacturerItem from './ManufacturerItem/ManufacturerItem';
import FilterTriangleIcon from '../../../../assets/images/filterIcons/triangle.svg';
import {
  manufacturerTableColumnNames,
  manufacturerTableColumnTypes,
  manufacturerTableOrderTypes,
  manufacturerTableColumns,
  orderFilterOptionIds
} from '../../../../constants/constants';
import './manufacturerList.scss';
import { deepClone } from '../../../../helpers/deepCloneHelper';

class ManufacturerList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      selectedManufacturerId: nextProps.selectedManufacturerId
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedManufacturerId: null
    };
  }

  onManufacturerCheckboxClick = manufacturerId => {
    return () => {
      const { onManufacturerCheckboxClick } = this.props;

      if (onManufacturerCheckboxClick) {
        onManufacturerCheckboxClick(manufacturerId);
      }

      this.setState({
        activeManufacturerId: manufacturerId
      });
    };
  };

  renderManufacturerItems() {
    const { manufacturers, selectedColumn } = this.props;
    const { selectedManufacturerId } = this.state;

    let manufacturersCopy = deepClone(manufacturers);

    switch (selectedColumn.columnName) {
      case manufacturerTableColumnNames.MANUFACTURER:
        if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerA.title.toLowerCase() >
              manufacturerB.title.toLowerCase()
              ? 1
              : -1;
          });
        } else {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerA.title.toLowerCase() <
              manufacturerB.title.toLowerCase()
              ? 1
              : -1;
          });
        }
        break;
      case manufacturerTableColumnNames.DELIVERY_DATE:
        if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            const timeManufacturerA = new Date(
              manufacturerA.deliveryDate
            ).getTime();
            const timeManufacturerB = new Date(
              manufacturerB.deliveryDate
            ).getTime();
            return timeManufacturerA - timeManufacturerB;
          });
        } else {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            const timeManufacturerA = new Date(
              manufacturerA.deliveryDate
            ).getTime();
            const timeManufacturerB = new Date(
              manufacturerB.deliveryDate
            ).getTime();
            return timeManufacturerB - timeManufacturerA;
          });
        }
        break;
      case manufacturerTableColumnNames.DELIVERY_PRICE:
        if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerA.deliveryPrice - manufacturerB.deliveryPrice;
          });
        } else {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerB.deliveryPrice - manufacturerA.deliveryPrice;
          });
        }
        break;
      case manufacturerTableColumnNames.CABINET_PRICE:
        if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerA.cabinetPrice - manufacturerB.cabinetPrice;
          });
        } else {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            return manufacturerB.cabinetPrice - manufacturerA.cabinetPrice;
          });
        }
        break;
      case manufacturerTableColumnNames.TOTAL_PRICE:
        if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            const totalPriceA =
              manufacturerA.deliveryPrice + manufacturerA.cabinetPrice;
            const totalPriceB =
              manufacturerB.deliveryPrice + manufacturerB.cabinetPrice;
            return totalPriceA - totalPriceB;
          });
        } else {
          manufacturersCopy.sort(function(manufacturerA, manufacturerB) {
            const totalPriceA =
              manufacturerA.deliveryPrice + manufacturerA.cabinetPrice;
            const totalPriceB =
              manufacturerB.deliveryPrice + manufacturerB.cabinetPrice;
            return totalPriceB - totalPriceA;
          });
        }
        break;
    }

    return (
      <div className="ikt-ws_manufacturer-list-content">
        {manufacturersCopy.map((manufacturer, index) => {
          const isActive = manufacturer.id === selectedManufacturerId;
          return (
            <ManufacturerItem
              key={index}
              manufacturer={manufacturer}
              isActive={isActive}
              onClick={this.onManufacturerCheckboxClick(manufacturer.id)}
              selectedColumn={selectedColumn}
            />
          );
        })}
      </div>
    );
  }

  selectColumn = columnName => {
    return () => {
      const { onColumnHeadingClick } = this.props;
      onColumnHeadingClick(columnName);
    };
  };

  render() {
    const { manufacturers, selectedColumn } = this.props;

    return (
      manufacturers &&
      manufacturers.length > 0 && (
        <div className="ikt-ws_manufacturer-list-container">
          <div className="ikt-ws_manufacturer-list-heading">
            <div
              className={`ikt-ws_manufacturer-list-heading-manufacturer
                        ikt-ws_manufacturer-list-heading-item
                        ${
                          selectedColumn.columnName ===
                          manufacturerTableColumnNames.MANUFACTURER
                            ? ` active`
                            : ``
                        }
                        ${
                          selectedColumn.order ===
                          manufacturerTableOrderTypes.ASCENDING
                            ? ` ascending`
                            : ` descending`
                        }`}
              onClick={this.selectColumn(
                manufacturerTableColumnNames.MANUFACTURER
              )}
            >
              <div
                className="ikt-ws_manufacturer-list-heading-manufacturer-content
                            ikt-ws_manufacturer-list-heading-item-content"
              >
                Manufacturer
                <img src={FilterTriangleIcon} />
              </div>
            </div>
            <div
              className={`ikt-ws_manufacturer-list-heading-delivery-date
                        ikt-ws_manufacturer-list-heading-item
                        ${
                          selectedColumn.columnName ===
                          manufacturerTableColumnNames.DELIVERY_DATE
                            ? ` active`
                            : ``
                        }
                        ${
                          selectedColumn.order ===
                          manufacturerTableOrderTypes.ASCENDING
                            ? ` ascending`
                            : ` descending`
                        }`}
              onClick={this.selectColumn(
                manufacturerTableColumnNames.DELIVERY_DATE
              )}
            >
              <div
                className="ikt-ws_manufacturer-list-heading-delivery-date-content
                            ikt-ws_manufacturer-list-heading-item-content"
              >
                Delivery Date
                <img src={FilterTriangleIcon} />
              </div>
            </div>
            <div
              className={`ikt-ws_manufacturer-list-heading-delivery-price
                        ikt-ws_manufacturer-list-heading-item
                        ${
                          selectedColumn.columnName ===
                          manufacturerTableColumnNames.DELIVERY_PRICE
                            ? ` active`
                            : ``
                        }
                        ${
                          selectedColumn.order ===
                          manufacturerTableOrderTypes.ASCENDING
                            ? ` ascending`
                            : ` descending`
                        }`}
              onClick={this.selectColumn(
                manufacturerTableColumnNames.DELIVERY_PRICE
              )}
            >
              <div
                className="ikt-ws_manufacturer-list-heading-delivery-price-content
                            ikt-ws_manufacturer-list-heading-item-content"
              >
                Delivery Price
                <img src={FilterTriangleIcon} />
              </div>
            </div>
            <div
              className={`ikt-ws_manufacturer-list-heading-cabinet-price
                        ikt-ws_manufacturer-list-heading-item
                        ${
                          selectedColumn.columnName ===
                          manufacturerTableColumnNames.CABINET_PRICE
                            ? ` active`
                            : ``
                        }
                        ${
                          selectedColumn.order ===
                          manufacturerTableOrderTypes.ASCENDING
                            ? ` ascending`
                            : ` descending`
                        }`}
              onClick={this.selectColumn(
                manufacturerTableColumnNames.CABINET_PRICE
              )}
            >
              <div
                className="ikt-ws_manufacturer-list-heading-cabinet-price-content
                            ikt-ws_manufacturer-list-heading-item-content"
              >
                Cabinet Price
                <img src={FilterTriangleIcon} />
              </div>
            </div>
            <div
              className={`ikt-ws_manufacturer-list-heading-total-price
                        ikt-ws_manufacturer-list-heading-item
                        ${
                          selectedColumn.columnName ===
                          manufacturerTableColumnNames.TOTAL_PRICE
                            ? ` active`
                            : ``
                        }
                        ${
                          selectedColumn.order ===
                          manufacturerTableOrderTypes.ASCENDING
                            ? ` ascending`
                            : ` descending`
                        }`}
              onClick={this.selectColumn(
                manufacturerTableColumnNames.TOTAL_PRICE
              )}
            >
              <div
                className="ikt-ws_manufacturer-list-heading-total-price-content
                            ikt-ws_manufacturer-list-heading-item-content"
              >
                Total Price
                <img src={FilterTriangleIcon} />
              </div>
            </div>
          </div>
          {this.renderManufacturerItems()}
        </div>
      )
    );
  }
}

export default ManufacturerList;
