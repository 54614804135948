import React from 'react';

import ButtonTeal from '../ButtonTeal/ButtonTeal';

class OrderSummary extends React.Component {
    handleBtnClick = () => {
        const {onBtnClick} = this.props;
        if (typeof onBtnClick === "function") {
            onBtnClick();
        }
    };
    render () {
        const {btnText, to, totalPrice, isShowBtn} = this.props;
        return (
            <div className="rows order-sum-page">
                <div className="row order-sum-title">Order Summary</div>
                <div className="row rows order-sum-content">
                    <div className="row order-sum-item">
                        <span>Shipping</span>
                        <span className="sum-value">Free</span>
                    </div>
                    <div className="row order-sum-item">
                        <span>Subtotal</span>
                        <span className="sum-value">{`$ ${totalPrice}`}</span>
                    </div>
                    <div className="row order-sum-item">
                        <span>Total</span>
                        <span className="sum-value">{`$ ${totalPrice}`}</span>
                    </div>
                </div>
                <div className="row">
                    {
                        isShowBtn &&
                        <ButtonTeal className="checkout-btn" to={to} onClick={this.handleBtnClick}>{btnText}</ButtonTeal>
                    }
                </div>
            </div>
        )
    }
}

export default OrderSummary;
