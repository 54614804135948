import React from 'react';
import {connect} from 'react-redux';

import {removeProduct} from '../../store/actions/cartActions';
import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import CartItem from './CartItem';
import OrderSummary from '../common/OrderSummary';

const items = [
    {
        itemsTitle: 'Prebuilt items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            },
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            }
        ]
    },
    {
        itemsTitle: 'Custom items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    }
                ]
            }
        ]
    }
];

class CartPage extends React.Component {
    getTotalPrice = () => {
        const {cartItems} = this.props;
        let totalPrice = 0;
        cartItems.map(item => totalPrice += item.amount ? item.amount*item.price : item.price);
        return totalPrice;
    };

    render () {
        const {cartItems} = this.props;
        const isShowContents = cartItems.length !== 0;
        const totalPrice = this.getTotalPrice();
        return (
            <div className="columns is-marginless cart-page">
                <div className="column is-9">
                    <div className="rows cart-content">
                        <div className="row cart-page-title">
                            <div className="title">
                                <span>Cart</span>
                                {
                                    isShowContents &&
                                    <div className="item-count">{cartItems.length}</div>
                                }
                            </div>
                            <div className="order-nmb">
                                <span>Order #554466</span>
                            </div>
                        </div>
                        <div className="row">
                            {
                                isShowContents &&
                                <CartItem item={items[0]} totalPrice={totalPrice} products={cartItems}/>
                            }
                        </div>
                        <div className="row">
                            {
                                isShowContents &&
                                <ButtonTeal className="checkout-btn" to="/order">CHECKOUT ></ButtonTeal>
                            }
                        </div>
                    </div>
                </div>
                <div className="column is-3 order-sum">
                    <OrderSummary
                        btnText={"CHECKOUT >"}
                        to="/order"
                        totalPrice={totalPrice}
                        isShowBtn={isShowContents}

                    />
                </div>
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        cartItems: state.cart.products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeProduct: (product) => dispatch(removeProduct(product))
    }
};

export default connect(mapStateToProp, mapDispatchToProps)(CartPage);
