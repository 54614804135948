import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import MainLayout from './components/layouts/MainLayout/MainLayout';
import PageNotFound from './components/static/PageNotFound';
import HomePage from './components/home/HomePage';
import CartPage from './components/cart';
import CabinetPage from './components/cabinet';
import ProductDetailsPage from './components/productDetails/ProductDetailsPage';
import MyOrdersPage from './components/myOrders/MyOrdersPage';
import OrderDetailsPage from './components/orderDetails';
import SelectCabinetManufacturerPage from './components/makeCabinet/SelectCabinetManufacturer/SelectCabinetManufacturerPage';
import ShopPage from './components/shop/ShopPage';
import GetStarted from './components/makeCabinet/GetStarted/GetStarted';
import CabinetBuilderPage from './components/makeCabinet/CabinetBuilder/CabinetBuilderPage';
import SelectCabinetMaterialsPage from './components/makeCabinet/SelectCabinetMaterials/SelectCabinetMaterialsPage';
import ThankYou from './components/makeCabinet/ThankYou/ThankYouPage';
import MyCabinetsPage from './components/myCabinets/MyCabinetsPage';
import OrderPage from './components/order/';
import SuccessPage from './components/order/SuccessPage';
import FailurePage from './components/order/Failure';
import Auth0Callback from './components/Auth0Callback/Auth0Callback';
import { getCities } from './store/actions/cityActions';
import { getMaterials } from './store/actions/materialsActions';
import { getStyles } from './store/actions/stylesActions';
import { getColors } from './store/actions/colorsActions';
import { getDepartments } from './store/actions/departmentsActions';
import { getSizes } from './store/actions/sizesActions';
import { checkCurrentAuthStatus } from './store/actions/authActions';
import { getManufacturers } from './store/actions/manufacturersActions';
import history from './utils/history';
import ContactsPage from './components/contacts/ContactsPage';
import DeliveryTermsPage from './components/deliveryTerms/DeliveryTermsPage';
import FaqPage from './components/faq/FaqPage';
import GalleryPage from './components/gallery/GalleryPage';

class App extends Component {
  componentDidMount() {
    this.props.getCities();
    this.props.getMaterials();
    this.props.getStyles();
    this.props.getColors();
    this.props.getDepartments();
    this.props.getSizes();
    this.props.checkCurrentAuthStatus();
    this.props.getManufacturers();
  }

  render() {
    return (
      <BrowserRouter history={history}>
        <MainLayout>
          <ToastContainer />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/auth0-callback" component={Auth0Callback} />
            <Route exact path="/login" component={HomePage} />
            <Route exact path="/register" component={HomePage} />
            <Route exact path="/reset-password" component={HomePage} />
            <Route path="/cart" component={CartPage} />
            <Route path="/cabinet/:id" component={CabinetPage} />
            <Route exact path="/shop" component={ShopPage} />
            <Route path="/order" component={OrderPage} />
            <Route path="/success" component={SuccessPage} />
            <Route path="/failure" component={FailurePage} />
            <Route path="/contacts" component={ContactsPage} />
            <Route path="/terms" component={DeliveryTermsPage} />
            <Route path="/faq" component={FaqPage} />
            <Route path="/gallery" component={GalleryPage} />
            <Route
              exact
              path="/product-details/:id"
              component={ProductDetailsPage}
            />
            <Route
              exact
              path="/make-cabinet/get-started"
              component={GetStarted}
            />
            <Route
              exact
              path="/make-cabinet/cabinet-builder"
              component={CabinetBuilderPage}
            />
            <Route
              exact
              path="/make-cabinet/select-cabinet-materials"
              component={SelectCabinetMaterialsPage}
            />
            <Route
              exact
              path="/make-cabinet/select-cabinet-manufacturer"
              component={SelectCabinetManufacturerPage}
            />
            <Route exact path="/make-cabinet/thank-you" component={ThankYou} />
            <Route exact path="/cabinets" component={MyCabinetsPage} />
            <Route exact path="/orders" component={MyOrdersPage} />
            <Route exact path="/orders/:id" component={OrderDetailsPage} />
            <Route component={PageNotFound} />
          </Switch>
        </MainLayout>
      </BrowserRouter>
    );
  }
}

const mapStateToProp = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    getCities: () => dispatch(getCities('Germany')),
    getMaterials: () => dispatch(getMaterials()),
    getStyles: () => dispatch(getStyles()),
    getColors: () => dispatch(getColors()),
    getDepartments: () => dispatch(getDepartments()),
    getSizes: () => dispatch(getSizes()),
    checkCurrentAuthStatus: () => dispatch(checkCurrentAuthStatus()),
    getManufacturers: () => dispatch(getManufacturers())
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(App);
