import * as FiltersActionTypes from '../actionTypes/filtersActionTypes';
import { applyFeaturedProductFilters } from './featuredProductsActions';
import { applyNewProductFilters } from './newProductsActions';

export const getFilters = searchParams => {
  return { type: FiltersActionTypes.GET_FILTERS, payload: searchParams };
};

export const getFiltersSuccessful = filters => {
  return { type: FiltersActionTypes.GET_FILTERS_SUCCESS, payload: filters };
};

export const getFiltersFailed = error => {
  return { type: FiltersActionTypes.GET_FILTERS_ERROR, payload: error };
};

export const toggleSelectedFilterOptions = filterData => {
  return {
    type: FiltersActionTypes.TOGGLE_SELECTED_FILTER_OPTIONS,
    payload: filterData
  };
};

export const toggleSelectedFilterOptionsAndFilterProducts = filterData => {
  return (dispatch, getState) => {
    dispatch(toggleSelectedFilterOptions(filterData));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type:
        FiltersActionTypes.TOGGLE_SELECTED_FILTER_OPTIONS_AND_FILTER_PRODUCT,
      payload: null
    };
  };
};

export const selectAllFilterOption = filterId => {
  return {
    type: FiltersActionTypes.SELECT_ALL_FILTER_OPTION,
    payload: filterId
  };
};

export const selectAllFilterOptionAndFilterProducts = filterId => {
  return (dispatch, getState) => {
    dispatch(selectAllFilterOption(filterId));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.SELECT_ALL_FILTER_OPTION_AND_FILTER_PRODUCTS,
      payload: null
    };
  };
};

export const activatePriceFilter = filterData => {
  return {
    type: FiltersActionTypes.ACTIVATE_PRICE_FILTER,
    payload: filterData
  };
};

export const activatePriceFilterAndFilterProducts = filterData => {
  return (dispatch, getState) => {
    dispatch(activatePriceFilter(filterData));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.ACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS,
      payload: null
    };
  };
};

export const deactivatePriceFilter = filterData => {
  return {
    type: FiltersActionTypes.DEACTIVATE_PRICE_FILTER,
    payload: filterData
  };
};

export const deactivatePriceFilterAndFilterProducts = filterId => {
  return (dispatch, getState) => {
    dispatch(deactivatePriceFilter(filterId));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.DEACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS,
      payload: null
    };
  };
};

export const activateDistanceFilter = filterData => {
  return {
    type: FiltersActionTypes.ACTIVATE_DISTANCE_FILTER,
    payload: filterData
  };
};

export const activateDistanceFilterAndFilterProducts = filterData => {
  return (dispatch, getState) => {
    dispatch(activateDistanceFilter(filterData));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.ACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS,
      payload: null
    };
  };
};

export const deactivateDistanceFilter = filterData => {
  return {
    type: FiltersActionTypes.DEACTIVATE_DISTANCE_FILTER,
    payload: filterData
  };
};

export const deactivateDistanceFilterAndFilterProducts = filterId => {
  return (dispatch, getState) => {
    dispatch(deactivateDistanceFilter(filterId));
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.DEACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS,
      payload: null
    };
  };
};

export const filterNewProducts = () => {
  return (dispatch, getState) => {
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyNewProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.FILTER_NEW_PRODUCTS,
      payload: null
    };
  };
};

export const filterFeaturedProducts = () => {
  return (dispatch, getState) => {
    const selectedFilters = getState().filters.selectedFilters;
    dispatch(applyFeaturedProductFilters(selectedFilters));

    return {
      type: FiltersActionTypes.FILTER_FEATURED_PRODUCTS,
      payload: null
    };
  };
};
