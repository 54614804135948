import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import Input from '../../common/Input/Input';
import { withRouter } from 'react-router-dom';
import LoginSocials from './LoginSocials/LoginSocials';

import './login.scss';

class Login extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.auth.isLoginPageShown &&
      !nextProps.auth.isRegistrationPageShown
    ) {
      return {
        loginInputError: false,
        passwordInputError: false
      };
    }

    let result = {
      loginInputError: !!nextProps.auth.loginErrors.email,
      passwordInputError: !!nextProps.auth.loginErrors.password
    };

    return result;
  }
  constructor(props) {
    super(props);

    this.loginWrapperRef = React.createRef();
    this.loginInputRef = React.createRef();
    this.passwordInputRef = React.createRef();

    this.state = {
      loginInputError: false,
      passwordInputError: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    if (!prevProps.shown && this.props.shown) {
      this.loginWrapperRef.current &&
        this.loginWrapperRef.current.scrollTo(0, 0);
    }
    if (
      !auth.isLoginPageShown &&
      !auth.isRegistrationPageShown &&
      !auth.isResetPasswordPageShown
    ) {
      this.loginInputRef.current.value = '';
      this.passwordInputRef.current.value = '';
    }
  }

  goToRegisterPage = () => {
    this.props.showRegistrationPage();
  };

  goToResetPasswordPage = () => {
    this.props.showResetPasswordPage();
  };

  onLogin = () => {
    const { checkAuthorization, setLoginErrors } = this.props;

    const login = this.loginInputRef.current.value;
    const password = this.passwordInputRef.current.value;
    let errors = {};
    if (login.length === 0) {
      const errorMessage = `Please fill in login field`;
      toast.error(errorMessage);
      errors.email = errorMessage;
    }

    if (password.length === 0) {
      const errorMessage = `Please fill in password field`;
      toast.error(errorMessage);
      errors.password = errorMessage;
    }
    setLoginErrors(errors);

    if (Object.keys(errors).length === 0) {
      checkAuthorization({ credentials: { email: login, password: password } });
    }
  };

  onLoginInputChange = event => {
    const { resetLoginErrors } = this.props;
    resetLoginErrors(['email']);
  };

  onInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.onLogin();
    }
  };

  onPasswordInputChange = () => {
    const { resetLoginErrors } = this.props;
    resetLoginErrors(['password']);
  };

  render() {
    const { className } = this.props;
    const { loginInputError, passwordInputError } = this.state;

    const isAddLink = this.props.location.pathname === '/login';

    return (
      <div
        className={`ikt-ws_login-container${className ? ` ${className}` : ``} `}
      >
        <div className="ikt-ws_login-wrapper" ref={this.loginWrapperRef}>
          <div className="ikt-ws_login-top">
            <div className="ikt-ws_login-text">Log In</div>
            <Input
              className="ikt-ws_login-email"
              placeholder="Email"
              error={loginInputError}
              onChange={this.onLoginInputChange}
              onKeyDown={this.onInputKeyDown}
              ref={this.loginInputRef}
            />
            <Input
              className="ikt-ws_login-password"
              placeholder="Password"
              type="password"
              error={passwordInputError}
              onChange={this.onPasswordInputChange}
              onKeyDown={this.onInputKeyDown}
              ref={this.passwordInputRef}
            />
            <ButtonTeal
              className="ikt-ws_login-login-btn"
              onClick={this.onLogin}
            >
              LOG IN
            </ButtonTeal>
            <ButtonLinkEmpty
              className="ikt-ws_login-forgot-password"
              to={isAddLink ? `/reset-password` : null}
              onClick={this.goToResetPasswordPage}
            >
              Forgot your password?
            </ButtonLinkEmpty>
            <div className="ikt-ws_login-socials">
              <div className="ikt-ws_login-socials-text">
                Log In via Social Account
              </div>
              <LoginSocials />
            </div>
          </div>
          <div className="ikt-ws_login-register-container">
            <div className="ikt-ws_login-register-text">
              Don’t have Account?
            </div>
            <ButtonLinkEmpty
              className="ikt-ws_login-register-btn"
              to={isAddLink ? `/register` : null}
              onClick={this.goToRegisterPage}
            >
              REGISTER NOW
            </ButtonLinkEmpty>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
