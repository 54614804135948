import React from 'react';
import Slider from 'react-slick';

import ProductCard from '../../common/ProductCard/ProductCard';
import ButtonBlue from '../../common/ButtonBlue/ButtonBlue';
import NextArrow from '../../../assets/images/arrows/gallery-arrow-r.svg';
import PrevArrow from '../../../assets/images/arrows/gallery-arrow-l.svg';


function SampleNextArrow(props) {
    const {style, onClick} = props;
    return (
        <div
            onClick={onClick}
            style={{...style, display: "block", margin: "auto", width:"140px"}}
        >
            <img src={NextArrow}/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {style, onClick} = props;
    return (
        <div
            onClick={onClick}
            style={{...style, display: "block", margin: "auto", width:"140px"}}
        >
            <img src={PrevArrow}/>
        </div>
    );
}

class ProductSlider extends React.Component {
    render () {
        const {name, products} = this.props;
        const sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 3,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <div className="row product-slider">
                <div className="row is-2 is-offset-4 slider-title">{name}</div>
                <Slider style={{display: "flex"}} {...sliderSettings}>
                    {
                        products && products.map((elem, index) => {
                            return (
                                <ProductCard
                                    className="ikt-ws_shop-page-product-card"
                                    data={elem}
                                    key={index}
                                />
                            )
                        })
                    }
                </Slider>
                <div className="row">
                    <ButtonBlue className="product-slider-btn">VIEW ALL</ButtonBlue>
                </div>
            </div>
        )
    }
}

export default ProductSlider;
