import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {getDepartments} from '../../store/actions/departmentsActions';
import {getFeaturedProducts} from '../../store/actions/featuredProductsActions';
import {getNewProducts} from '../../store/actions/newProductsActions';

import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import FooterSignUp from '../common/FooterSignUp';
import ProductSlider from './ProductSlider';
import ProductCard from '../common/ProductCard/ProductCard';
import ShopDepartments from './ShopDepartments';
import TopSlider from './TopSlider';

import CadRenderingImg from '../../assets/images/home/rendering-cad.png';
import Arrow from '../../assets/images/arrows/arrow-category.svg';
import MeasureIndividually from '../../assets/images/home/measure-individually.png';
import MasterCraftsman from '../../assets/images/home/master-craftsman.png';
import Forest from '../../assets/images/home/forest.png';
import Manufacturing from '../../assets/images/home/manufacturing.png';
import Laptop from '../../assets/images/home/laptop.png';
import BulletHands from '../../assets/images/home/bullet-hands.png';
import BulletBars from '../../assets/images/home/bullet-bars.png';
import BulletPlant from '../../assets/images/home/bullet-plant.png';
import Bullet1 from '../../assets/images/bullets/01.svg';
import Bullet2 from '../../assets/images/bullets/02.svg';
import Bullet3 from '../../assets/images/bullets/03.svg';

class HomePage extends Component {
    
    renderingCadQualityFeaturesContainer = React.createRef();
    qualityFeaturesFirstArea = React.createRef();

    renderProducts = () => {
        return this.props.products && this.props.products.map((product, index) => {
            return (
                <ProductCard
                    className="ikt-ws_shop-page-product-card"
                    data={product}
                    key={index}
                />
            );
        });
    };

    componentDidMount() {
        this.props.getFeaturedProducts();
        this.props.getNewProducts();
        this.props.getDepartments();
    }

    render() {
        const { t } = this.props;

        const cadQualityFeaturesContainerStyle = {};
        let backgroundStyleValue = 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 14.999%, rgba(232,245,246,1) 15%, rgba(255,255,255,1) 100%)';
        if (this.renderingCadQualityFeaturesContainer.current && this.qualityFeaturesFirstArea.current) {
            const qualityFeaturesFirstAreaRect = this.qualityFeaturesFirstArea.current.getBoundingClientRect();
            const renderingCadQualityFeaturesContainerRect = this.renderingCadQualityFeaturesContainer.current.getBoundingClientRect();
            const percent = (qualityFeaturesFirstAreaRect.top - renderingCadQualityFeaturesContainerRect.top + (qualityFeaturesFirstAreaRect.bottom - qualityFeaturesFirstAreaRect.top) / 2) / (renderingCadQualityFeaturesContainerRect.bottom - renderingCadQualityFeaturesContainerRect.top);
            const percentagePoint = 100 - 100 * 1.0175 * percent;
            backgroundStyleValue = `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) ${percentagePoint - 0.001}%, rgba(232,245,246,1) ${percentagePoint}%, rgba(255,255,255,1) 100%)`;
        }
        cadQualityFeaturesContainerStyle['background'] = backgroundStyleValue;

        let buttonExtraWidthClassName = '';
        if (i18n.language == 'de') {
            buttonExtraWidthClassName = 'btn-extra-width';
        }
        return (
                <div>
                    <div className="row is-8 top-container">
                        <TopSlider/>
                        <div className="top-info-tabs">
                            <div className="columns">
                                <div className="column info-tab">{ t('design_your_cabinet') }</div>
                                <div className="column info-tab">{ t('use_online_cabient') }</div>
                                <div className="column info-tab">{ t('be_creative') }</div>
                                <div className="column info-tab">{ t('have_a_question') }</div>
                            </div>
                        </div>
                    </div>
                    <div className="row is-full rendering-cad-quality-features-container" style={cadQualityFeaturesContainerStyle} ref={this.renderingCadQualityFeaturesContainer}>
                        <div className="columns is-marginless rendering-cad-container">
                            <div className="column">
                                <div className="column">
                                    <div className="row title">{ t('your_furniture_title') }</div>
                                    <div className="row">
                                        <p>
                                            { t('your_furniture_body') }
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <ButtonTeal className={`btn ${buttonExtraWidthClassName}`}>
                                        { t('more_info').toUpperCase() }
                                    </ButtonTeal>
                                </div>
                            </div>
                            <div className="column" style={{paddingLeft: "0"}}>
                                <img src={CadRenderingImg} alt="cad-rendering-illustration" />
                            </div>
                        </div>
                        <div className="quality-features-container">
                            <div className="title">{ t("quality_features") }</div>
                            <div className="columns">
                                <div className="column area-container" ref={this.qualityFeaturesFirstArea}>
                                    <div className="columns">
                                        <div className="column text-container">
                                            <div className="row area-title">{ t('buy_master_craftsman_title') }</div>
                                            <div className="row">{ t('buy_master_craftsman_body') }</div>
                                        </div>
                                        <div className="column img-container">
                                            <img src={MasterCraftsman} alt="master-craftsman-img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="column area-container">
                                    <div className="columns">
                                        <div className="column text-container">
                                            <div className="row area-title">{ t('measure_individually_title') }</div>
                                            <div className="row">{ t('measure_individually_body') }</div>
                                        </div>
                                        <div className="column img-container">
                                            <img src={MeasureIndividually} alt="measure-individually-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="company-philosophy-container">
                        <div className="title">{ t("company_philosophy") }</div>
                        <div className="columns">
                            <div className="column forest-container">
                                <img src={Forest} className="forest-image" alt="forest-img" />
                            </div>
                            <div className="column">
                                <div className="sustainability-container">
                                    <div className="row area-title">{ t('sustainability') }</div>
                                    <div className="row area-body">
                                        <p>{ t('sustainability_paragraph_1') }</p>
                                        <p>{ t('sustainability_paragraph_2') }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns buy-locally-container">
                            <div className="column text-container">
                                <div className="row area-title">{ t('buy_locally') }</div>
                                <div className="row area-row">
                                    <div className="img-helper">
                                        <img src={BulletHands} alt="bullet-hands" />
                                    </div>
                                    <div>
                                        <p className="p-title">{ t('strenghten_home_region_title') }</p>
                                        <p className="p-body">{ t('strenghten_home_region_body') }</p>
                                    </div>
                                </div>
                                <div className="row area-row">
                                    <div className="img-helper">
                                        <img src={BulletBars} alt="bullet-bars" />
                                    </div>
                                    <div>
                                        <p className="p-title">{ t('jobs_title') }</p>
                                        <p className="p-body">{ t('jobs_body') }</p>
                                    </div>
                                </div>
                                <div className="row area-row">
                                    <div className="img-helper">
                                        <img src={BulletPlant} alt="bullet-plant" />
                                    </div>
                                    <div>
                                        <p className="p-title">{ t('environment_title') }</p>
                                        <p className="p-body">{ t('environment_body') }</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column image-container">
                                <img src={Manufacturing} className="manufacturing-image" alt="manufacturing-img" />
                            </div>
                        </div>
                    </div>
                    <div className="another-section-container">
                        <div className="upper-background-container"></div>
                        <div className="inner-section-container">
                            <div className="title">Another Section</div>
                            <div className="columns">
                                <div className="column">
                                    <img src={Laptop} alt="laptop-img" />
                                </div>
                                <div className="column">
                                    <div className="subtitle-container">
                                        <div className="row area-title">{ t('get_started_title') }</div>
                                        <div className="row area-body">
                                            { t('get_started_body') }
                                        </div>
                                        <div className="row">
                                            <ButtonTeal className={`btn ${buttonExtraWidthClassName}`}>
                                                { t('get_started_button').toUpperCase() }
                                            </ButtonTeal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*<div className="row is-full cabinet-container">
                        <div className="columns is-marginless">
                            <div className="column is-offset-1">
                                <div className="row cabinet-title">Design your own cabinet</div>
                                <div className="row columns">
                                    <div className="column is-1 own-cabinet-point">
                                        <img src={Bullet1} />
                                    </div>
                                    <div className="column is-8 own-cabinet-text">Prepare your design in .cad? format and upload it to website</div>
                                </div>
                                <div className="row columns">
                                    <div className="column is-1 own-cabinet-point">
                                        <img src={Bullet2} />
                                    </div>
                                    <div className="column is-8 own-cabinet-text">Send us design and recive the price</div>
                                </div>
                                <div className="row columns">
                                    <div className="column is-1 own-cabinet-point">
                                        <img src={Bullet3} />
                                    </div>
                                    <div className="column is-8 own-cabinet-text">Wait for your order 3-10 day. You can track it easily</div>
                                </div>
                                <div className="row ">
                                    <ButtonTeal className="own-cabinet-btn">
                                        MAKE YOUR OWN CABINET
                                    </ButtonTeal>
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                    </div> */}
                    <div className="row is-full lg-btn-container">
                        <div className="columns is-marginless">
                            <div className="column is-vcentered lg-btn cab-hardware-btn">
                                <div className="columns cab-btn-container is-vcentered">
                                    <div className="column is-offset-1 is-4 cab-btn-text">
                                        CABINET HARDWARE
                                    </div>
                                    <div className="column is-offset-6 is-1 min-col-width">
                                        <img src={Arrow} />
                                    </div>
                                </div>
                            </div>
                            <div className="column lg-btn led-lights-btn">
                                <div className="columns led-btn-container is-vcentered">
                                    <div className="column is-offset-1 is-2 led-btn-text">
                                        LED LIGHTS
                                    </div>
                                    <div className="column is-offset-8 is-1 min-col-width">
                                        <img src={Arrow} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterSignUp />
                </div>
        );
    }
}

const mapStateToProp = (state) => {
    return {
        featuredProducts: state.featuredProducts.featuredProducts,
        newProducts: state.newProducts.newProducts,
        departments: state.departments.departments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFeaturedProducts: (searchParams) => dispatch(getFeaturedProducts(searchParams)),
        getNewProducts: (searchParams) => dispatch(getNewProducts(searchParams)),
        getDepartments: () => dispatch(getDepartments()),
    }
};

export default connect(mapStateToProp, mapDispatchToProps)(withTranslation()(HomePage));
