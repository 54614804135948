import React, { Component } from 'react';
import { withRouter } from 'react-router';
import LessIcon from '../../../assets/images/components/LessIcon';
import MoreIcon from '../../../assets/images/components/MoreIcon';
import './counter.scss';

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCounterVal: props.initVal || 1,
      isDecBtnHover: false,
      isIncBtnHover: false
    };
  }

  toggleDecBtnHover = () => {
    this.setState({
      isDecBtnHover: !this.state.isDecBtnHover
    });
  };

  toggleIncBtnHover = () => {
    this.setState({
      isIncBtnHover: !this.state.isIncBtnHover
    });
  };

  increaseCounter = () => {
    const { onChange } = this.props;
    const newCounterVal = this.state.currentCounterVal + 1;
    this.setState({
      currentCounterVal: newCounterVal
    });
    onChange(newCounterVal);
  };

  decreaseCounter = () => {
    const { onChange } = this.props;
    let newCounterVal = this.state.currentCounterVal - 1;
    newCounterVal = newCounterVal > 0 ? newCounterVal : 1;

    this.setState({
      currentCounterVal: newCounterVal
    });
    onChange(newCounterVal);
  };

  render() {
    const { currentCounterVal, isDecBtnHover, isIncBtnHover } = this.state;

    return (
      <div className="ikt-ws_counter-container">
        <button
          className="ikt-ws_counter-dec-btn"
          onClick={this.decreaseCounter}
          onMouseEnter={this.toggleDecBtnHover}
          onMouseLeave={this.toggleDecBtnHover}
        >
          <LessIcon
            className="ikt-ws_counter-dec-icon"
            color={isDecBtnHover ? '#1da6b2' : '#1899A5'}
          />
        </button>

        <div className="ikt-ws_counter-value">{currentCounterVal}</div>
        <button
          className="ikt-ws_counter-inc-btn"
          onClick={this.increaseCounter}
          onMouseEnter={this.toggleIncBtnHover}
          onMouseLeave={this.toggleIncBtnHover}
        >
          <MoreIcon
            className="ikt-ws_counter-inc-icon"
            color={isIncBtnHover ? '#1da6b2' : '#1899A5'}
          />
        </button>
      </div>
    );
  }
}

export default Counter;
