import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router";

import AddressCard from '../common/AddressCard';
import CartItem from '../cart/CartItem';
import OrderSummary from '../common/OrderSummary';
import PaymentMethodCard from '../common/PaymentMethodCard';

const items = [
    {
        itemsTitle: 'Prebuilt items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            },
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            }
        ]
    },
    {
        itemsTitle: 'Custom items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    }
                ]
            }
        ]
    }
];

class OrderDetails extends React.Component {
    render () {
        const {orders} = this.props;
        const orderId = parseInt(this.props.match.params.id);
        const order = orders.filter( item => item.id === orderId);
        return (
            <div className="columns is-marginless cart-page">
                <div className="column is-9">
                    <div className="rows cart-content">
                        <div className="row cart-page-title">
                            <div className="title">
                                <span>{`Order # ${orderId}`}</span>
                            </div>
                        </div>
                        <div className="rows cart-content">
                            <div className="row">
                                <CartItem
                                    isOrderPage={true}
                                    item={items[0]}
                                    products={order.length !== 0 && order[0].items}
                                    totalPrice={2500}
                                />
                            </div>
                            <div className="row">
                                <AddressCard/>
                            </div>
                            <div className="row">
                                <PaymentMethodCard />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-3 order-sum">
                    <OrderSummary
                        btnText={"REPEAT ORDER"}
                        totalPrice={"212121"}
                        isShowBtn={true}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        orders: state.userAccount.orders,
    };
};

export default connect(mapStateToProp, null)(withRouter(OrderDetails));
