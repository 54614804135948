import React from 'react';

import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import FooterSignUp from '../common/FooterSignUp';
import FailureIcon from '../../assets/images/btnIcons/fail.svg';

class FailurePage extends React.Component {
    render () {
        return (
            <div className="rows is-marginless failure-page">
                <div className="row img-content">
                    <div className="success-img">
                        <img src={FailureIcon}/>
                    </div>
                </div>
                <div className="row text-content">
                    <div className="failure-title">Something went wrong</div>
                    <div className="failure-text">Explanation of the issue</div>
                    <ButtonTeal className="back-to-shop-btn" to="/shop">BACK TO SHOP</ButtonTeal>
                </div>
                <FooterSignUp/>
            </div>
        )
    }
}

export default FailurePage;
