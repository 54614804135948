import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './Header';
import Footer from './Footer';
import { resetSingleProduct } from '../../../store/actions/singleProductActions';
import {
  checkAuthorization,
  showLoginPage,
  hideAuthPages,
  showRegistrationPage,
  showResetPasswordPage,
  logOut,
  resetLoginErrors,
  setLoginErrors,
  resetRegistrationErrors,
  setRegistrationErrors,
  resetPassword,
  register
} from '../../../store/actions/authActions';
import DarkOverlay from '../../common/DarkOverlay/DarkOverlay';
import Login from '../../auth/Login/Login';
import Registration from '../../auth/Registration/Registration';
import ResetPassword from '../../auth/ResetPassword/ResetPassword';

import './mainLayout.scss';

const calcIsAnimateAuthPage = (
  prevPathName,
  prevIsLoginPageShown,
  prevIsRegistrationPageShown,
  prevIsResetPasswordPageShown
) => {
  return (
    prevPathName !== '/login' &&
    prevPathName !== '/register' &&
    prevPathName !== '/reset-password' &&
    !prevIsLoginPageShown &&
    !prevIsRegistrationPageShown &&
    !prevIsResetPasswordPageShown
  );
};

class MainLayout extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const isAnimateAuthPage = calcIsAnimateAuthPage(
      prevState.prevPathName,
      prevState.prevIsLoginPageShown,
      prevState.prevIsRegistrationPageShown,
      prevState.prevIsResetPasswordPageShown
    );

    return {
      isAnimateAuthPage: isAnimateAuthPage,
      prevPathName: nextProps.location.pathname,
      prevIsLoginPageShown: nextProps.auth.isLoginPageShown,
      prevIsRegistrationPageShown: nextProps.auth.isRegistrationPageShown,
      prevIsResetPasswordPageShown: nextProps.auth.isResetPasswordPageShown
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      isAnimateAuthPage: false,
      prevPathName: null,
      prevIsLoginPageShown: false,
      prevIsRegistrationPageShown: false,
      prevIsResetPasswordPageShown: false
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const { resetSingleProduct } = this.props;
      resetSingleProduct();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onDarkOverlayClick = () => {
    const { auth, hideAuthPages, location } = this.props;

    if (
      auth.isLoginPageShown ||
      auth.isRegistrationPageShown ||
      auth.isResetPasswordPageShown ||
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/reset-password'
    ) {
      hideAuthPages();
    }
  };

  render() {
    const {
      children,
      location,
      auth,
      showLoginPage,
      showRegistrationPage,
      showResetPasswordPage,
      checkAuthorization,
      userProfile,
      logOut,
      email,
      resetLoginErrors,
      setLoginErrors,
      resetRegistrationErrors,
      setRegistrationErrors,
      resetPassword,
      register
    } = this.props;
    const { isAnimateAuthPage } = this.state;
    const isLoginRoute = location.pathname === '/login';
    const isRegisterRoute = location.pathname === '/register';
    const isResetPasswordRoute = location.pathname === '/reset-password';
    const isAuthRoute = isLoginRoute || isRegisterRoute || isResetPasswordRoute;
    const isHomePage = location.pathname === '/' || isAuthRoute;
    const isLoginShown = isLoginRoute || auth.isLoginPageShown;
    const isRegisterShown = isRegisterRoute || auth.isRegistrationPageShown;
    const isResetPasswordShown =
      isResetPasswordRoute || auth.isResetPasswordPageShown;
    const isDarkOverlay =
      isLoginShown || isRegisterShown || isResetPasswordShown;
    const authPageAnimateClassName = isAnimateAuthPage ? ' is-animate' : '';

    let isDisplayFooter = true;
    if (
      location.pathname === '/make-cabinet/cabinet-builder' ||
      location.pathname === '/make-cabinet/select-cabinet-materials' ||
      location.pathname === '/make-cabinet/select-cabinet-manufacturer'
    ) {
      isDisplayFooter = false;
    }

    let darkOverlay = (
      <DarkOverlay
        className={isDarkOverlay ? `shown` : ``}
        onClick={this.onDarkOverlayClick}
      />
    );
    if (isAuthRoute) {
      darkOverlay = <Link to="/">{darkOverlay}</Link>;
    }

    return (
      <div
        className={`ikt-ws_main-layout${
          isDarkOverlay ? ` ikt-ws_main-layout-overflow-hidden` : ``
        }`}
      >
        <Header
          isDarkTheme={isHomePage}
          showLoginPage={showLoginPage}
          isAuthorized={auth.isAuthorized}
          userProfile={userProfile}
          logOut={logOut}
        />
        <div className="row is-full">{children}</div>
        {isDisplayFooter ? <Footer /> : null}
        {darkOverlay}
        <Login
          shown={isLoginShown}
          className={
            isLoginShown
              ? `ikt-ws_is-active${authPageAnimateClassName}`
              : `${authPageAnimateClassName}`
          }
          showRegistrationPage={showRegistrationPage}
          showResetPasswordPage={showResetPasswordPage}
          checkAuthorization={checkAuthorization}
          auth={auth}
          resetLoginErrors={resetLoginErrors}
          setLoginErrors={setLoginErrors}
        />
        <Registration
          shown={isRegisterShown}
          className={
            isRegisterShown
              ? `ikt-ws_is-active${authPageAnimateClassName}`
              : `${authPageAnimateClassName}`
          }
          showLoginPage={showLoginPage}
          auth={auth}
          register={register}
          resetRegistrationErrors={resetRegistrationErrors}
          setRegistrationErrors={setRegistrationErrors}
        />
        <ResetPassword
          shown={isResetPasswordShown}
          className={
            isResetPasswordShown
              ? `ikt-ws_is-active${authPageAnimateClassName}`
              : `${authPageAnimateClassName}`
          }
          showLoginPage={showLoginPage}
          email={email}
          resetPassword={resetPassword}
        />
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    auth: state.auth,
    userProfile: state.userAccount.userProfile,
    email: state.signUp.email
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSingleProduct: () => dispatch(resetSingleProduct()),
    showLoginPage: () => dispatch(showLoginPage()),
    showRegistrationPage: () => dispatch(showRegistrationPage()),
    showResetPasswordPage: () => dispatch(showResetPasswordPage()),
    hideAuthPages: () => dispatch(hideAuthPages()),
    checkAuthorization: options => dispatch(checkAuthorization(options)),
    logOut: () => dispatch(logOut()),
    resetLoginErrors: fields => dispatch(resetLoginErrors(fields)),
    setLoginErrors: errors => dispatch(setLoginErrors(errors)),
    resetPassword: email => dispatch(resetPassword(email)),
    register: options => dispatch(register(options)),
    resetRegistrationErrors: (fields) => dispatch(resetRegistrationErrors(fields)),
    setRegistrationErrors: errors => dispatch(setRegistrationErrors(errors))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(MainLayout));
