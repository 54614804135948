import React from 'react';

const PageNotFound = () => {
    return (
        <div className="container">
            <h2>Sorry! Page not found</h2>
        </div>
    )
};

export default PageNotFound;