import React, { Component } from 'react';
import './darkOverlay.scss';

const DarkOverlay = props => {
  const { className, ...rest } = props;
  return (
    <div
      {...rest}
      className={`ikt-ws_dark-overlay${
        props.className ? ` ${props.className}` : ``
      }`}
    ></div>
  );
};

export default DarkOverlay;
