import React from 'react';
import FacebookIcon from '../../../../assets/images/components/FacebookIcon';
import TwitterIcon from '../../../../assets/images/components/TwitterIcon';
import LinkedinIcon from '../../../../assets/images/components/LinkedinIcon';
import Icon from '../../../../assets/images/components/Icon';
import { useAuth0 } from '../../auth0';

import './loginSocials.scss';

const LoginSocials = props => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div className="ikt-ws_login-socials-icon-container">
      <Icon
        className="ikt-ws_login-socials-icon"
        color="#3B5998"
        colorHover="#607ebd"
        icon={FacebookIcon}
        onClick={() => loginWithRedirect({})}
      />
      <Icon
        className="ikt-ws_login-socials-icon"
        color="#55ACEE"
        colorHover="#81c0f0"
        icon={TwitterIcon}
        onClick={() => loginWithRedirect({})}
      />
      <Icon
        className="ikt-ws_login-socials-icon"
        color="#007AB9"
        colorHover="#29a4e3"
        icon={LinkedinIcon}
        onClick={() => loginWithRedirect({})}
      />
    </div>
  );
};

export default LoginSocials;
