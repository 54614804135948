import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getDepartmentsSuccessful,
  getDepartmentsFailed
} from '../store/actions/departmentsActions';
import * as DepartmentsActionTypes from '../store/actionTypes/departmentsActionTypes';

import image1 from '../assets/images/products/image1.jpg';
import image2 from '../assets/images/products/image2.jpg';
import image3 from '../assets/images/products/image3.jpg';

const dummyDepartments = [
  {
    id: 1,
    value: 'Kitchen',
    img: image1
  },
  {
    id: 2,
    value: 'Living Room',
    img: image2
  },
  {
    id: 3,
    value: 'Wardrobe',
    img: image3
  },
  {
    id: 4,
    value: 'Bedroom',
    img: image1
  },
  {
    id: 5,
    value: 'For Kids',
    img: image2
  },
  {
    id: 6,
    value: 'Garage',
    img: image3
  }
];

export const getDepartments = actions$ => {
  return actions$.pipe(
    ofType(DepartmentsActionTypes.GET_DEPARTMENTS),
    delay(2000),
    mapTo(getDepartmentsSuccessful(dummyDepartments)),
    catchError((error, caught) =>
      merge(of(getDepartmentsFailed(error)), caught)
    )
  );
};
