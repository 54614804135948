import { combineReducers } from 'redux';

import cartReducer from './cartReducer';
import citiesReducer from './cityReducer';
import colorsReducer from './colorsReducer';
import departmentsReducer from './departmentsReducer';
import featuredProductsReducer from './featuredProductsReducer';
import filtersReducer from './filtersReducer';
import newProductsReducer from './newProductsReducer';
import materialsReducer from './materialsReducer';
import settingsReducer from './settingsReducer';
import signUpReducer from './signUpReducer';
import singleProductReducer from './singleProductReducer';
import sizesReducer from './sizesReducer';
import stylesReducer from './stylesReducer';
import authReducer from './authReducer';
import userAccountReducer from './userAccountReducer';
import makeCustomCabinetReducer from './makeCustomCabinetReducer';
import manufacturersReducer from './manufacturersReducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  cities: citiesReducer,
  colors: colorsReducer,
  departments: departmentsReducer,
  featuredProducts: featuredProductsReducer,
  filters: filtersReducer,
  newProducts: newProductsReducer,
  materials: materialsReducer,
  settings: settingsReducer,
  signUp: signUpReducer,
  singleProduct: singleProductReducer,
  sizes: sizesReducer,
  styles: stylesReducer,
  auth: authReducer,
  userAccount: userAccountReducer,
  makeCustomCabinet: makeCustomCabinetReducer,
  manufacturers: manufacturersReducer
});

export default rootReducer;
