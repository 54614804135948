import React from 'react';

const DropdownIcon = (props) => {
    const { color = '#000', className = '' } = props;

    return (
        <svg className={className} width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>UI/checkmark-filter</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1714.000000, -274.000000)" stroke={color}>
                    <polyline id="UI/checkmark-filter" points="1715 278.590838 1717.72324 282 1724.90637 274.81687"></polyline>
                </g>
            </g>
        </svg>
    );
}

export default DropdownIcon;
