import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import CustomCabinet from '../CustomCabinet/CustomCabinet';
import Loader from '../../common/Loader/Loader';
import { selectCabinetManufacturer } from '../../../store/actions/makeCustomCabinetActions';
import { addCustomCabinet } from '../../../store/actions/userAccountActions';
import ManufacturerList from './ManufacturerList/ManufacturerList';
import { addProduct } from '../../../store/actions/cartActions';
import './selectCabinetManufacturerPage.scss';
import { deepClone } from '../../../helpers/deepCloneHelper';
import {
  manufacturerTableColumnNames,
  manufacturerTableOrderTypes
} from '../../../constants/constants';

class SelectCabinetManufacturerPage extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedManufacturerId === null) {
      return {
        selectedManufacturerId:
          (nextProps.manufacturers &&
            nextProps.manufacturers[0] &&
            nextProps.manufacturers[0].id) ||
          null
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedManufacturerId: null,
      selectedColumn: {
        columnName: manufacturerTableColumnNames.TOTAL_PRICE,
        order: manufacturerTableOrderTypes.ASCENDING
      }
    };
  }

  renderCabinet() {
    const { cabinet, sizes, materials, handlesMaterials } = this.props;

    return (
      <CustomCabinet
        cabinet={cabinet}
        sizes={sizes}
        materials={materials}
        handlesMaterials={handlesMaterials}
        isShowMaterials={true}
      />
    );
  }

  onManufacturerCheckboxClick = manufacturerId => {
    const { selectCabinetManufacturer } = this.props;

    this.setState({
      selectedManufacturerId: manufacturerId
    });
    selectCabinetManufacturer(manufacturerId);
  };

  onAddToCart = () => {
    const {
      addProduct,
      cabinet,
      history,
      manufacturers,
      addCustomCabinet,
      isAuthorized
    } = this.props;
    const { selectedManufacturerId } = this.state;

    const manufacturer = manufacturers.find(manufacturer => {
      return manufacturer.id === selectedManufacturerId;
    });

    let newCabinet = deepClone(cabinet);
    newCabinet.price = manufacturer.deliveryPrice + manufacturer.cabinetPrice;
    newCabinet.id = Date.now();

    let product = deepClone(newCabinet);
    product.amount = 1;
    addProduct(product);

    if (isAuthorized) {
      addCustomCabinet(newCabinet);
    }
    history.push('/make-cabinet/thank-you');
  };

  onColumnHeadingClick = columnName => {
    const { selectedColumn } = this.state;
    let newColumnOrder = manufacturerTableOrderTypes.ASCENDING;
    if (columnName === selectedColumn.columnName) {
      if (selectedColumn.order === manufacturerTableOrderTypes.ASCENDING) {
        newColumnOrder = manufacturerTableOrderTypes.DESCENDING;
      } else {
        newColumnOrder = manufacturerTableOrderTypes.ASCENDING;
      }
    }

    this.setState({
      selectedColumn: {
        columnName: columnName,
        order: newColumnOrder
      }
    });
  };

  isAllDataReady() {
    const {
      cabinet,
      sizes,
      handlesMaterials,
      materials,
      manufacturers
    } = this.props;
    return (
      Object.keys(cabinet).length > 0 &&
      sizes.length > 0 &&
      handlesMaterials.length > 0 &&
      materials.length > 0 &&
      manufacturers.length > 0
    );
  }

  render() {
    const { selectedManufacturerId, selectedColumn } = this.state;
    const { manufacturers } = this.props;

    return (
      <div className="ikt-ws_select-cabinet-manufacturer-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_select-cabinet-manufacturer-page-top">
              <div className="ikt-ws_select-cabinet-manufacturer-left-col">
                {this.renderCabinet()}
              </div>
              <div className="ikt-ws_select-cabinet-manufacturer-right-col">
                <div className="ikt-ws_select-cabinet-manufacturer-right-col-heading">
                  Select Manufacturer
                </div>
                <ManufacturerList
                  manufacturers={manufacturers}
                  selectedManufacturerId={selectedManufacturerId}
                  selectedColumn={selectedColumn}
                  onManufacturerCheckboxClick={this.onManufacturerCheckboxClick}
                  onColumnHeadingClick={this.onColumnHeadingClick}
                />
              </div>
            </div>
            <div className="ikt-ws_select-cabinet-manufacturer-page-bottom">
              <ButtonLinkEmpty
                to="/make-cabinet/get-started"
                className="ikt-ws_select-cabinet-manufacturer-cancel-btn"
              >
                CANCEL
              </ButtonLinkEmpty>
              <ButtonTeal
                className="ikt-ws_select-cabinet-manufacturer-add-to-cart-btn"
                onClick={this.onAddToCart}
              >
                ADD TO CART
              </ButtonTeal>
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    cabinet: state.makeCustomCabinet.cabinet,
    materials: state.materials.materials,
    handlesMaterials: state.materials.handlesMaterials,
    sizes: state.sizes.sizes,
    manufacturers: state.manufacturers.manufacturers,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectCabinetManufacturer: manufacturerId => {
      dispatch(selectCabinetManufacturer(manufacturerId));
    },
    addProduct: cabinet => dispatch(addProduct(cabinet)),
    addCustomCabinet: cabinet => dispatch(addCustomCabinet(cabinet))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(SelectCabinetManufacturerPage));
