import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getNewProductsSuccessful,
  getNewProductsFailed
} from '../store/actions/newProductsActions';
import * as NewProductsActionTypes from '../store/actionTypes/newProductsActionTypes';
import { newProducts } from './epicsData';

export const getNewProducts = actions$ => {
  return actions$.pipe(
    ofType(NewProductsActionTypes.GET_NEW_PRODUCTS),
    delay(2000),
    mapTo(getNewProductsSuccessful(newProducts)),
    catchError((error, caught) =>
      merge(of(getNewProductsFailed(error)), caught)
    )
  );
};
