import * as ColorsActionTypes from '../actionTypes/colorsActionTypes';

export const getColors = () => {
  return { type: ColorsActionTypes.GET_COLORS };
};

export const getColorsSuccessful = colors => {
  return { type: ColorsActionTypes.GET_COLORS_SUCCESS, payload: colors };
};

export const getColorsFailed = error => {
  return { type: ColorsActionTypes.GET_COLORS_ERROR, payload: error };
};
