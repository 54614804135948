import * as ManufacturersActionTypes from '../actionTypes/manufacturersActionTypes';

const initState = {
  manufacturers: [],
  manufacturersError: null,
  manufacturersBusy: false
};

const manufactoriesReducer = (state = initState, action) => {
  switch (action.type) {
    case ManufacturersActionTypes.GET_MANUFACTURERS:
      return {
        ...state,
        manufacturersBusy: true
      };
    case ManufacturersActionTypes.GET_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        manufacturers: action.payload,
        manufacturersError: null,
        manufacturersBusy: false
      };
    case ManufacturersActionTypes.GET_MANUFACTURERS_ERROR:
      return {
        ...state,
        manufacturersError: action.payload.message,
        manufacturersBusy: false
      };
    default:
      return state;
  }
};

export default manufactoriesReducer;
