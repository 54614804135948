import React from 'react';
import {connect} from 'react-redux';
import { languages } from '../../../constants/constants';

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

class TopBar extends React.Component {

    renderSelectedLanguage = () => {
        const selectedLanguage = languages.find(el => el.id == i18n.language);
        const alternativeLanguage = languages.find(el => el.id != i18n.language);
        if (selectedLanguage) {
            return (
                <div onClick={() => this.onChangeLanguage(alternativeLanguage)}>
                    <img src={selectedLanguage.icon} className="country-flag"/>
                    <span className="language-name">{selectedLanguage.name}</span>
                </div>
            );
        }
        return null;
    }

    renderLanguage = (language) => {
        return (
            <div className="dropdown-item" onClick={() => this.onChangeLanguage(language)}>
                { language.name }
            </div>
        )
    };

    onChangeLanguage = (language) => {
        i18n.changeLanguage(language.id);
        this.forceUpdate();
    }

    render() {
        const { t } = this.props;
        return (
            <nav className="top-bar">
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="note-ad">{ t("header_topbar_title")}</p>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <p className="contacts">{ t("call_us_today")} +49 30 3083020</p>
                        </div>
                        <div className="level-item">
                            <p className="contacts">MON-FRI:8:00 - 19:00(ETC)</p>
                        </div>
                        <div className="level-item">
                            <div className="dropdown language-dropdown">
                                { this.renderSelectedLanguage() }
                            </div>
                        </div>
                    </div>
                </nav>
            </nav>
        )
    }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopBar));
