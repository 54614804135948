import React from 'react';

import Product from '../../common/Product';

class CartProducts extends React.Component {
    render () {
        const {products, totalPrice, isOrderPage} = this.props;
        return (
            <div className="cart-products">
                {products && products.map((elem, index) =>
                    <Product
                        data={{...elem}}
                        isOrderPage={isOrderPage}
                        key={index}
                    />
                )}
                <div className="products-estimates is-marginless columns">
                    <div className="column is-offset-1 is-3 delivery-est">
                        <div className="del-est-title">Estimated Delivery</div>
                        <div className="del-est-text">2 Days (1 September, 2019: My Home)</div>
                    </div>
                    <div className="column is-offset-3 is-1">
                        <div className="del-est-title">Shipping</div>
                        <div className="del-est-title">Subtotal</div>
                    </div>
                    <div className="column is-1 is-offset-1">
                        <div className="del-est-text">Free</div>
                        <div className="del-est-text">{`€ ${totalPrice}`}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CartProducts;
