import React, { Component } from "react";
import img11 from "../../assets/images/gallery/11.png";
import img12 from "../../assets/images/gallery/12.png";
import img13 from "../../assets/images/gallery/13.png";
import img21 from "../../assets/images/gallery/21.png";
import img22 from "../../assets/images/gallery/22.png";
import img23 from "../../assets/images/gallery/23.png";
import img31 from "../../assets/images/gallery/31.png";
import img32 from "../../assets/images/gallery/32.png";
import img33 from "../../assets/images/gallery/33.png";
import FooterSignUp from "../common/FooterSignUp";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./galleryPage.scss";

const images = [
  img11,
  img12,
  img13,
  img21,
  img22,
  img23,
  img31,
  img32,
  img33
];

class GalleryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div className="ikt-ws_gallery-page-container">
        <>
          <div className="ikt-ws_gallery-page-content">
            <div className="ikt-ws_gallery-page-title">Renderings</div>
            <div className="ikt-ws_gallery-page-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <div className="ikt-ws_gallery-content">
              <div className="gallery-column">
                <img className="gallery-img" src={img11} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:0 })}/>
                <img className="gallery-img" src={img12} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:1 })}/>
                <img className="gallery-img" src={img13} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:2 })}/>
              </div>
              <div className="gallery-column">
                <img className="gallery-img" src={img21} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:3 })}/>
                <img className="gallery-img" src={img22} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:4 })}/>
                <img className="gallery-img" src={img23} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:5 })}/>
              </div>
              <div className="gallery-column">
                <img className="gallery-img" src={img31} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:6 })}/>
                <img className="gallery-img" src={img32} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:7 })}/>
                <img className="gallery-img" src={img33} alt="gallery-img" onClick={() => this.setState({ isOpen: true, photoIndex:8 })}/>
              </div>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </div>
          <FooterSignUp />
        </>
      </div>
    );
  }
}

export default GalleryPage;
