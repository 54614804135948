import React from 'react';

import CheckmarkFilterIcon from '../../../../assets/images/components/CheckmarkFilterIcon';
import './shopPriceFilterContent.scss';

class ShopPriceFilterContent extends React.Component {
  constructor(props) {
    super(props);

    const { selectedFilterData } = props;

    this.state = {
      isPriceCheckmarkHover: false,
      priceFrom: selectedFilterData.priceFrom || '',
      priceTo: selectedFilterData.priceTo || ''
    };

    this.priceFromRef = React.createRef();
    this.priceToRef = React.createRef();
  }

  togglePriceCheckmarkHover = () => {
    this.setState({
      isPriceCheckmarkHover: !this.state.isPriceCheckmarkHover
    });
  };

  onActivateFilter = () => {
    const priceFrom = parseFloat(this.priceFromRef.current.value);
    const priceTo = parseFloat(this.priceToRef.current.value);

    const { activatePriceFilterAndFilterProducts, filterData } = this.props;
    activatePriceFilterAndFilterProducts({
      filterId: filterData.id,
      priceFrom,
      priceTo
    });
  };

  onInputKeyDown = event => {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode === ENTER_KEY_CODE) {
      this.onActivateFilter();
    }
  };

  onAnyPriceClick = () => {
    const { deactivatePriceFilterAndFilterProducts, filterData } = this.props;
    deactivatePriceFilterAndFilterProducts(filterData.id);
  };

  onPriceFromChange = event => {
    this.setState({
      priceFrom: event.target.value
    });
  };

  onPriceToChange = event => {
    this.setState({
      priceTo: event.target.value
    });
  };

  render() {
    const { isPriceCheckmarkHover, priceFrom, priceTo } = this.state;
    const { selectedFilterData } = this.props;

    const isActive = selectedFilterData.isActive;

    let priceCheckmarkColor;
    if (isPriceCheckmarkHover) {
      priceCheckmarkColor = '#fff';
    }

    return (
      <div className="ikt-ws_shop-filter-price-content">
        <div
          className={`ikt-ws_shop-filter-any-item${
            !isActive ? ` ikt-ws_is-active` : ``
          }`}
          onClick={this.onAnyPriceClick}
        >
          <div className="ikt-ws_shop-price-filter-left-side-checkmark"></div>
          <div className="ikt-ws_shop-price-filter-price-any-item-text">
            Any
          </div>
        </div>
        <div
          className={`ikt-ws_shop-price-filter-bottom${
            isActive ? ` ikt-ws_is-active` : ``
          }`}
        >
          <div className="ikt-ws_shop-price-filter-left-side-checkmark"></div>
          <input
            ref={this.priceFromRef}
            type="number"
            placeholder="From"
            value={priceFrom}
            className={`ikt-ws_shop-price-filter-input-from`}
            onKeyDown={this.onInputKeyDown}
            onChange={this.onPriceFromChange}
          />
          <div className="ikt-ws_shop-price-filter-hyphen"></div>
          <input
            ref={this.priceToRef}
            type="number"
            placeholder="To"
            value={priceTo}
            className={`ikt-ws_shop-price-filter-input-to`}
            onKeyDown={this.onInputKeyDown}
            onChange={this.onPriceToChange}
          />
          <div
            className="ikt-ws_shop-price-filter-price-checkmark"
            onMouseEnter={this.togglePriceCheckmarkHover}
            onMouseLeave={this.togglePriceCheckmarkHover}
            onClick={this.onActivateFilter}
          >
            <CheckmarkFilterIcon color={priceCheckmarkColor} />
          </div>
        </div>
      </div>
    );
  }
}

export default ShopPriceFilterContent;
