import React from 'react';

import DropdownIcon from '../../../../assets/images/components/DropdownIcon';
import './shopTextFilterContent.scss';

class ShopTextFilterContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderFilterOptions(filterData, selectedFilterData) {
    return (
      filterData.options &&
      filterData.options.map((option, index) => {
        const isActive =
          selectedFilterData.selectedOptions &&
          selectedFilterData.selectedOptions.includes(option.id);

        return (
          <div
            key={index}
            className={`ikt-ws_shop-text-filter-item${
              isActive ? ` ikt-ws_is-active` : ``
            }`}
            onClick={this.onOptionsClick(option.id)}
          >
            <div className="ikt-ws_shop-filter-all-item-checkmark" />
            <div href="#" className="ikt-ws_shop-text-filter-item-text">
              {option.value}
            </div>
          </div>
        );
      })
    );
  }

  onAllOptionClick = filterId => {
    return () => {
      const { selectAllFilterOptionAndFilterProducts } = this.props;
      selectAllFilterOptionAndFilterProducts(filterId);
    };
  };

  onOptionsClick = optionId => {
    return () => {
      const {
        toggleSelectedFilterOptionsAndFilterProducts,
        filterData
      } = this.props;
      toggleSelectedFilterOptionsAndFilterProducts({
        filterId: filterData.id,
        optionId
      });
    };
  };

  render() {
    const {
      filterData,
      selectedFilterData,
      toggleSelectedFilterOptions
    } = this.props;

    const isAllOptionActive =
      selectedFilterData.selectedOptions &&
      selectedFilterData.selectedOptions.length === 0;

    return (
      <div className="ikt-ws_shop-filter-text-content">
        <div
          href="#"
          className={`ikt-ws_shop-filter-all-item${
            isAllOptionActive ? ` ikt-ws_is-active` : ``
          }`}
          onClick={this.onAllOptionClick(filterData.id)}
        >
          <div className="ikt-ws_shop-filter-all-item-checkmark" />
          <div className="ikt-ws_shop-filter-all-item-text">All</div>
        </div>
        {this.renderFilterOptions(
          filterData,
          selectedFilterData,
          toggleSelectedFilterOptions
        )}
      </div>
    );
  }
}

export default ShopTextFilterContent;
