import * as CityActionTypes from '../actionTypes/cityActionTypes';

export const getCities = country => {
  return { type: CityActionTypes.GET_CITIES, payload: country };
};

export const getCitiesSuccessful = colors => {
  return { type: CityActionTypes.GET_CITIES_SUCCESS, payload: colors };
};

export const getCitiesFailed = error => {
  return { type: CityActionTypes.GET_CITIES_ERROR, payload: error };
};
