import React from 'react';
import './customCabinet.scss';

const Color = props => {
  return (
    <div className="ikt-ws_cabinet-item-color">
      <div className={`ikt-ws_cabinet-item-color-btn-content`}>
        <div className="ikt-ws_cabinet-item-color-btn-outer">
          <div
            className="ikt-ws_cabinet-item-color-btn-inner"
            style={{ backgroundColor: props.color.value }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export const CustomCabinet = props => {
  const {
    cabinet,
    sizes,
    materials,
    handlesMaterials,
    isShowMaterials
  } = props;

  const size = sizes.find(size => {
    return size.id === cabinet.sizeId;
  });

  let skeletonMaterial;
  let doorsMaterial;
  let handlesMaterial;

  if (isShowMaterials) {
    skeletonMaterial = materials.find(material => {
      return material.id === cabinet.skeletonMaterialId;
    });
    doorsMaterial = materials.find(material => {
      return material.id === cabinet.doorsMaterialId;
    });
    handlesMaterial = handlesMaterials.find(material => {
      return material.id === cabinet.handlesMaterialId;
    });
  }

  return (
    <div className="ikt-ws_custom-cabinet-container">
      <div
        className="ikt-ws_custom-cabinet-image"
        style={{ backgroundImage: `url(${cabinet.image})` }}
      ></div>
      <div className="ikt-ws_custom-cabinet-bottom">
        <div className="ikt-ws_custom-cabinet-title">{cabinet.title}</div>
        <div className="ikt-ws_custom-cabinet-skeleton-mat-n-size">
          {`${
            isShowMaterials
              ? `${skeletonMaterial ? `${skeletonMaterial.value} ` : ``}`
              : ``
          }(${size.value})`}
          {isShowMaterials ? <Color color={{ value: '#b1a396' }} /> : null}
        </div>

        {isShowMaterials ? (
          <>
            <div className="ikt-ws_custom-cabinet-doors-mat">
              {doorsMaterial ? `${doorsMaterial.value}` : null}
              {isShowMaterials ? <Color color={{ value: '#b0b0b0' }} /> : null}
            </div>
            <div className="ikt-ws_custom-cabinet-handles-mat">
              {handlesMaterial ? `${handlesMaterial.value} Handles` : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomCabinet;
