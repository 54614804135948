import * as SizesActionTypes from '../actionTypes/sizesActionTypes';

const initState = {
  sizes: [],
  sizesError: null,
  sizesBusy: false
};

const sizesReducer = (state = initState, action) => {
  switch (action.type) {
    case SizesActionTypes.GET_SIZES:
      return {
        ...state,
        sizesBusy: true
      };
    case SizesActionTypes.GET_SIZES_SUCCESS:
      return {
        ...state,
        sizes: action.payload,
        sizesError: null,
        sizesBusy: false
      };
    case SizesActionTypes.GET_SIZES_ERROR:
      return {
        ...state,
        sizesError: action.payload.message,
        sizesBusy: false
      };
    default:
      return state;
  }
};

export default sizesReducer;
