import React from 'react';

const LessIcon = (props) => {
    const { color = '#1899A5', className = '', onClick = () => {} } = props;

    return (
        <svg onClick={onClick}
             className={className}
             width="14px" height="2px" viewBox="0 0 14 2" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>UI/Buttons/Teal/less</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1457.000000, -339.000000)" fill={color}>
                    <rect id="UI/Buttons/Teal/less" x="1457" y="339" width="14" height="2"></rect>
                </g>
            </g>
        </svg>
    );
}

export default LessIcon;
