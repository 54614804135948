import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CabinetBuilderPlaceholderImage from '../../../assets/images/placeholders/cabinet-builder.png';
import ButtonGold from '../../common/ButtonGold/ButtonGold';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import { dummyMakeCustomCabinet } from '../../../epics/epicsData';
import { deepClone } from '../../../helpers/deepCloneHelper';
import { addCustomCabinet } from '../../../store/actions/makeCustomCabinetActions';
import './cabinetBuilderPage.scss';
import Iframe from 'react-iframe';

class CabinetBuilderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onCancelBtnClick = () => {
    this.props.history.push('/make-cabinet/get-started');
  };

  onNextBtnClick = () => {
    const customCabinet = deepClone(dummyMakeCustomCabinet);
    this.props.addCustomCabinet(customCabinet);
    this.props.history.push('/make-cabinet/select-cabinet-manufacturer');
  };

  render() {
    return (
      <div className="ikt-ws_cabinet-builder-page-container">
        <div className="ikt-ws_cabinet-builder-page-top">
          <div className="cabinet-maker-iframe-wrapper">
            <Iframe
              url="https://wcm.mregio.com"
              width="100%"
              height="500px!important"
              styles="border:0px #000000 none;height:450px;"
              scrolling="no"
              className="cabinet-maker-iframe"
              frameborder="1"
            />
          </div>
        </div>
        <div className="ikt-ws_cabinet-builder-page-bottom">
          <ButtonLinkEmpty
            className="ikt-ws_cabinet-builder-cancel-btn"
            onClick={this.onCancelBtnClick}
          >
            CANCEL
          </ButtonLinkEmpty>
          <ButtonTeal
            className="ikt-ws_cabinet-builder-next-btn"
            onClick={this.onNextBtnClick}
          >
            NEXT
          </ButtonTeal>
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addCustomCabinet: cabinet => {
      dispatch(addCustomCabinet(cabinet));
    }
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CabinetBuilderPage));
