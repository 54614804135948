import React from "react";
import "./FaqPage.scss";
import { useTranslation } from 'react-i18next';
import Accordion from "./Accordion";
import FooterSignUp from "../common/FooterSignUp";

function FaqPage() {
  const { t, i18n } = useTranslation();

  const faqs = [
    {
      question: t('faq_question_1'),
      answer: t('faq_answer_1'),
    },
    {
      question: t('faq_question_2'),
      answer: t('faq_answer_2'),
    },
    {
      question: t('faq_question_3'),
      answer: t('faq_answer_3'),
    },
    {
      question: t('faq_question_4'),
      answer: t('faq_answer_4'),
    },
    {
      question: t('faq_question_5'),
      answer: t('faq_answer_5'),
    },
    {
      question: t('faq_question_6'),
      answer: t('faq_answer_6'),
    },
    {
      question: t('faq_question_7'),
      answer: t('faq_answer_7'),
    },
    {
      question: t('faq_question_8'),
      answer: t('faq_answer_8'),
    },
    {
      question: t('faq_question_9'),
      answer: t('faq_answer_9'),
    },
  ];
  return (
    <div className="ikt-faq-page-container">
      <div className="ikt-ws_faq-page-top">
        <div className="ikt-ws_faq-page-title">FAQ</div>
        {/*<div className="ikt-ws_faq-page-description"></div>*/}
      </div>
      <div className="ikt-ws_faq-page-content">
        {faqs.map((faq) => (
          <Accordion {...faq} />
        ))}
      </div>
      <FooterSignUp />
    </div>
  );
}

export default FaqPage;
