export const GET_FILTERS = 'providers/GET_FILTERS';
export const GET_FILTERS_SUCCESS = 'providers/GET_FILTERS_SUCCESS';
export const GET_FILTERS_ERROR = 'providers/GET_FILTERS_ERROR';
export const TOGGLE_SELECTED_FILTER_OPTIONS =
  'providers/TOGGLE_SELECTED_FILTER_OPTIONS';
export const TOGGLE_SELECTED_FILTER_OPTIONS_AND_FILTER_PRODUCT =
  'providers/TOGGLE_SELECTED_FILTER_OPTIONS_AND_FILTER_PRODUCT';
export const SELECT_ALL_FILTER_OPTION = 'providers/SELECT_ALL_FILTER_OPTION';
export const SELECT_ALL_FILTER_OPTION_AND_FILTER_PRODUCTS =
  'providers/SELECT_ALL_FILTER_OPTION_AND_FILTER_PRODUCTS';
export const ACTIVATE_PRICE_FILTER = 'providers/ACTIVATE_PRICE_FILTER';
export const ACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS =
  'providers/ACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS';
export const DEACTIVATE_PRICE_FILTER = 'providers/DEACTIVATE_PRICE_FILTER';
export const DEACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS =
  'providers/DEACTIVATE_PRICE_FILTER_AND_FILTER_PRODUCTS';
export const ACTIVATE_DISTANCE_FILTER = 'providers/ACTIVATE_DISTANCE_FILTER';
export const ACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS =
  'providers/ACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS';
export const DEACTIVATE_DISTANCE_FILTER = 'providers/DEACTIVATE_DISTANCE_FILTER';
export const DEACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS =
  'providers/DEACTIVATE_DISTANCE_FILTER_AND_FILTER_PRODUCTS';
export const FILTER_NEW_PRODUCTS = 'providers/FILTER_NEW_PRODUCTS';
export const FILTER_FEATURED_PRODUCTS = 'providers/FILTER_FEATURED_PRODUCTS';
