import React from "react";
import GoogleMapReact from "google-map-react";
import "./contactsPage.scss";
import LocationPin from "./LocationPin";

const defaultMapOptions = {
  fullscreenControl: false,
};

const Map = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_IKT_WEBSHOP_GOOGLE_API_KEY,
        }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
        defaultOptions={defaultMapOptions}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
