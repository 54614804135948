import React from 'react';

import DropdownIcon from '../../../assets/images/components/DropdownIcon';
import './dropdownCustom.scss';

/**
 * Usage:
 * <DropdownCustom title="title">
 *     content
 * </DropdownCustom>
 */

class DropdownCustom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true
    };
  }

  dropdownToggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    const { isActive } = this.state;
    const { className = '', title, children, color = '#000' } = this.props;

    return (
      <div
        className={`ikt-ws_dropdown-custom${
          isActive ? ` ikt-ws_is-active ` : ``
        }${className ? ` ${className}` : ``}`}
      >
        <div
          className="ikt-ws_dropdown-custom-trigger"
          onClick={this.dropdownToggle}
        >
          <div className="ikt-ws_dropdown-custom-trigger_title">{title}</div>
          <DropdownIcon
            className="ikt-ws_dropdown-custom-chevron"
            color={color}
          />
        </div>
        <div className="ikt-ws_dropdown-content">{children}</div>
      </div>
    );
  }
}

export default DropdownCustom;
