import * as SingleProductsActionTypes from '../actionTypes/singleProductActionTypes';

export const getSingleProduct = productId => {
  return {
    type: SingleProductsActionTypes.GET_SINGLE_PRODUCT,
    payload: productId
  };
};

export const getSingleProductSuccessful = product => {
  return {
    type: SingleProductsActionTypes.GET_SINGLE_PRODUCT_SUCCESS,
    payload: product
  };
};

export const getSingleProductFailed = error => {
  return {
    type: SingleProductsActionTypes.GET_SINGLE_PRODUCT_ERROR,
    payload: error
  };
};

export const resetSingleProduct = () => {
  return {
    type: SingleProductsActionTypes.RESET_SINGLE_PRODUCT,
    payload: null
  };
};
