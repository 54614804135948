import productImage1 from '../assets/images/products/image1.jpg';
import productImage2 from '../assets/images/products/image2.jpg';
import productImage3 from '../assets/images/products/image3.jpg';

import productImage4 from '../assets/images/products/image4.jpg';
import productImage5 from '../assets/images/products/image5.jpg';
import productImage6 from '../assets/images/products/image6.jpg';

import manufacturerImage1 from '../assets/images/manufacturers/manufacturer1.png';
import manufacturerImage2 from '../assets/images/manufacturers/manufacturer2.png';

import pineWoodMaterialImage from '../assets/images/materials/pine-wood.jpeg';
import stainlessSteelMaterialImage from '../assets/images/materials/stainless-steel.jpeg';

import userImage1 from '../assets/images/users/photo1.jpg';
import userImage2 from '../assets/images/users/photo2.jpg';

import cabinetImage from '../assets/images/placeholders/cabinet-image.png';

import { orderStates } from '../constants/constants';
import { deepClone } from '../helpers/deepCloneHelper';

const details = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel`;

export const departments = [
  {
    id: 1,
    value: 'Kitchen',
    subDepartments: [
      {
        id: 6,
        value: 'Wall Units',
        subDepartments: []
      },
      {
        id: 7,
        value: 'Floor Units',
        subDepartments: []
      },
      {
        id: 8,
        value: 'Tall Units',
        subDepartments: []
      },
      {
        id: 9,
        value: 'Shelves',
        subDepartments: []
      },
      {
        id: 10,
        value: 'Tables',
        subDepartments: []
      },
      {
        id: 11,
        value: 'Accessoires',
        subDepartments: []
      },
      {
        id: 12,
        value: 'Lamps',
        subDepartments: []
      }
    ]
  },
  {
    id: 2,
    value: 'Living Room',
    subDepartments: [
      {
        id: 6,
        value: 'Living Room Cabinets',
        subDepartments: []
      },
      {
        id: 6,
        value: 'Tables',
        subDepartments: []
      }
    ]
  },
  {
    id: 3,
    value: 'Bedroom'
  },
  {
    id: 4,
    value: 'Entrance Hall'
  },
  {
    id: 5,
    value: 'For Kids'
  }
];

export const styles = [
  {
    id: 1,
    value: 'Modern'
  },
  {
    id: 2,
    value: 'Coastal'
  },
  {
    id: 3,
    value: 'Rustic'
  },
  {
    id: 4,
    value: 'Traditional'
  },
  {
    id: 5,
    value: 'Transitional'
  }
];

export const materials = [
  {
    id: 1,
    value: 'Hochlandesche'
  },
  {
    id: 2,
    value: 'Hemlock arktique'
  },
  {
    id: 3,
    value: 'Eiche Toscana'
  },
  {
    id: 4,
    value: 'Kernbuche'
  },
  {
    id: 5,
    value: 'Lärche'
  },
  {
    id: 6,
    value: 'Fichte antik'
  },
  {
    id: 7,
    value: 'Ulme'
  }
];

export const skeletonMaterials = [
  {
    id: 1,
    value: 'Hochlandesche',
    image: pineWoodMaterialImage
  },
  {
    id: 2,
    value: 'Hemlock arktique',
    image: pineWoodMaterialImage
  },
  {
    id: 3,
    value: 'Eiche Toscana',
    image: pineWoodMaterialImage
  },
  {
    id: 4,
    value: 'Kernbuche',
    image: pineWoodMaterialImage
  },
  {
    id: 5,
    value: 'Lärche',
    image: pineWoodMaterialImage
  },
  {
    id: 6,
    value: 'Fichte antik',
    image: stainlessSteelMaterialImage
  },
  {
    id: 7,
    value: 'Ulme',
    image: pineWoodMaterialImage
  }
];

export const handlesMaterials = [
  {
    id: 1,
    value: 'Fichte antik',
    image: stainlessSteelMaterialImage
  },
  {
    id: 2,
    value: 'Plastic'
  },
  {
    id: 3,
    value: 'Metal'
  }
];

export const colors = [
  {
    id: 1,
    value: '#ffffff'
  },
  {
    id: 2,
    value: '#886161'
  },
  {
    id: 3,
    value: '#607891'
  },
  {
    id: 4,
    value: '#333333'
  },
  {
    id: 5,
    value: '#876422'
  },
  {
    id: 6,
    value: '#3f61a2'
  },
  {
    id: 7,
    value: '#c2c6ca'
  },
  {
    id: 8,
    value: '#543185'
  },
  {
    id: 9,
    value: '#316760'
  },
  {
    id: 10,
    value: '#4e6071'
  }
];

export const sizes = [
  {
    id: 1,
    value: '10x10'
  },
  {
    id: 2,
    value: '10x20'
  },
  {
    id: 3,
    value: '10x30'
  },
  {
    id: 4,
    value: '20x20'
  }
];

const reviews = [
  {
    image: userImage1,
    timestamp: 'Dec 12 2018 02:00:00 GMT+0200',
    rate: 5,
    userName: 'Alice White',
    textContent: `Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`
  },
  {
    image: userImage2,
    timestamp: 'Jun 3 2018 01:00:00 GMT+0200',
    rate: 4,
    userName: 'Samantha Smith',
    textContent: `Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`
  }
];

let similarProductsInit = [
  {
    id: 27,
    title: 'Colonial',
    price: 200.5,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews
  },
  {
    id: 28,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 2,
    styleId: 1,
    materialId: 2,
    colorIds: [1, 3, 5, 7, 8],
    image: productImage5,
    details: details,
    reviews: reviews
  },
  {
    id: 29,
    title: 'Mini Bar',
    price: 1500.25,
    departmentIds: [1, 2],
    sizeId: 3,
    styleId: 3,
    materialId: 2,
    colorIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    image: productImage6,
    details: details,
    reviews: reviews
  },
  {
    id: 30,
    title: 'Colonial',
    price: 2000,
    departmentIds: [1, 2],
    sizeId: 4,
    styleId: 4,
    materialId: 3,
    colorIds: [1, 4, 5, 7, 8],
    image: productImage4,
    details: details,
    reviews: reviews
  },
  {
    id: 31,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 2,
    styleId: 5,
    materialId: 4,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews
  },
  {
    id: 32,
    title: 'Mini Bar',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 3,
    styleId: 2,
    materialId: 6,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews
  },
  {
    id: 33,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 4,
    styleId: 3,
    materialId: 7,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews
  },
  {
    id: 34,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 4,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews
  },
  {
    id: 35,
    title: 'Mini Bar',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 5,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews
  },
  {
    id: 36,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews
  },
  {
    id: 37,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews
  },
  {
    id: 38,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews
  },
  {
    id: 39,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews
  }
];

let similarProductsCopy = deepClone(similarProductsInit);

similarProductsInit = similarProductsInit.map(product => {
  product.similarProducts = similarProductsCopy;
  return product;
});

export const similarProducts = similarProductsInit;

export const newProducts = [
  {
    id: 14,
    title: 'Colonial',
    price: 200.5,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 10
  },
  {
    id: 15,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 2,
    styleId: 1,
    materialId: 2,
    colorIds: [1, 3, 5, 7, 8],
    image: productImage5,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 20
  },
  {
    id: 16,
    title: 'Mini Bar',
    price: 1500.25,
    departmentIds: [1, 2],
    sizeId: 3,
    styleId: 3,
    materialId: 2,
    colorIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    image: productImage6,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 17,
    title: 'Colonial',
    price: 2000,
    departmentIds: [1, 2],
    sizeId: 4,
    styleId: 4,
    materialId: 3,
    colorIds: [1, 4, 5, 7, 8],
    image: productImage4,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 18,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 2,
    styleId: 5,
    materialId: 4,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 19,
    title: 'Mini Bar',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 3,
    styleId: 2,
    materialId: 6,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 20,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 4,
    styleId: 3,
    materialId: 7,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 21,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 4,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 22,
    title: 'Mini Bar',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 5,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 23,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 24,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage5,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 25,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage6,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  },
  {
    id: 26,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage4,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts
  }
];

export const featuredProducts = [
  {
    id: 1,
    title: 'Colonial',
    price: 200.5,
    departmentIds: [1, 2, 3],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 10
  },
  {
    id: 2,
    title: 'Blue Cabinet',
    price: 180,
    departmentIds: [1, 2, 4, 5, 6],
    sizeId: 2,
    styleId: 1,
    materialId: 2,
    colorIds: [1, 3, 5, 7, 8],
    image: productImage2,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 100
  },
  {
    id: 3,
    title: 'Mini Bar',
    price: 1500.25,
    departmentIds: [1, 2, 4, 6],
    sizeId: 3,
    styleId: 3,
    materialId: 2,
    colorIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    image: productImage3,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 10
  },
  {
    id: 4,
    title: 'Colonial',
    price: 2000,
    departmentIds: [1, 2, 3, 4, 5, 6],
    sizeId: 4,
    styleId: 4,
    materialId: 3,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 200
  },
  {
    id: 5,
    title: 'Blue Cabinet',
    price: 250,
    departmentIds: [1, 2, 5, 6],
    sizeId: 2,
    styleId: 5,
    materialId: 4,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage2,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 300
  },
  {
    id: 6,
    title: 'Mini Bar',
    price: 300,
    departmentIds: [1, 2, 3, 4],
    sizeId: 3,
    styleId: 2,
    materialId: 6,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage3,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 300
  },
  {
    id: 7,
    title: 'Colonial',
    price: 450,
    departmentIds: [1, 2, 5, 6],
    sizeId: 4,
    styleId: 3,
    materialId: 7,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 500
  },
  {
    id: 8,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [3, 4, 5, 6],
    sizeId: 1,
    styleId: 4,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage2,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 80
  },
  {
    id: 9,
    title: 'Mini Bar',
    price: 200,
    departmentIds: [1, 2, 4, 5],
    sizeId: 1,
    styleId: 5,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage3,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 250
  },
  {
    id: 10,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2, 3, 6],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 800
  },
  {
    id: 11,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2, 5, 6],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage2,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 900
  },
  {
    id: 12,
    title: 'Colonial',
    price: 200,
    departmentIds: [1, 2, 4, 5, 6],
    sizeId: 1,
    styleId: 1,
    materialId: 1,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage3,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 250
  },
  {
    id: 13,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2, 3, 4, 5, 6],
    sizeId: 1,
    styleId: 1,
    materialId: 4,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 100
  },
  {
    id: 13,
    title: 'Hardwood Cabinet',
    price: 200,
    departmentIds: [1, 2, 3, 4, 5, 6],
    sizeId: 1,
    styleId: 1,
    materialId: 5,
    colorIds: [1, 2, 3, 4, 5, 6],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 100
  },
  {
    id: 13,
    title: 'Blue Cabinet',
    price: 200,
    departmentIds: [1, 2, 3, 4, 5],
    sizeId: 1,
    styleId: 1,
    materialId: 7,
    colorIds: [1, 2, 3, 4, 5],
    image: productImage1,
    details: details,
    reviews: reviews,
    similarProducts: similarProducts,
    distance: 100
  }
];

export const customCabinets = [
  {
    id: 103,
    title: 'Custom Cabinet #1',
    colorId: 3,
    materialId: 5,
    skeletonMaterialId: 5,
    doorsMaterialId: 5,
    sizeId: 3,
    price: 10135,
    image: cabinetImage,
    timestampCreated: 'Nov 21 2019 00:00:00 GMT+0200'
  },
  {
    id: 104,
    title: 'Custom Cabinet #2',
    colorId: 5,
    materialId: 1,
    skeletonMaterialId: 1,
    doorsMaterialId: 1,
    sizeId: 1,
    price: 4998,
    image: cabinetImage,
    timestampCreated: 'Nov 22 2019 00:00:00 GMT+0200'
  },
  {
    id: 105,
    title: 'Custom Cabinet #3',
    colorId: 2,
    materialId: 3,
    skeletonMaterialId: 3,
    doorsMaterialId: 3,
    sizeId: 3,
    price: 3342,
    image: cabinetImage,
    timestampCreated: 'Nov 23 2019 00:00:00 GMT+0200'
  },
  {
    id: 106,
    title: 'Custom Cabinet #4',
    colorId: 6,
    materialId: 2,
    sizeId: 1,
    price: 5575,
    image: cabinetImage,
    timestampCreated: 'Nov 24 2019 00:00:00 GMT+0200'
  },
  {
    id: 107,
    title: 'Custom Cabinet #5',
    colorId: 3,
    materialId: 4,
    skeletonMaterialId: 4,
    doorsMaterialId: 4,
    sizeId: 2,
    price: 7765,
    image: cabinetImage,
    timestampCreated: 'Nov 25 2019 00:00:00 GMT+0200'
  },
  {
    id: 108,
    title: 'Custom Cabinet #6',
    colorId: 7,
    materialId: 2,
    skeletonMaterialId: 2,
    doorsMaterialId: 2,
    sizeId: 2,
    price: 4998,
    image: cabinetImage,
    timestampCreated: 'Nov 26 2019 00:00:00 GMT+0200'
  },
  {
    id: 109,
    title: 'Custom Cabinet #7',
    colorId: 3,
    materialId: 5,
    skeletonMaterialId: 5,
    doorsMaterialId: 5,
    sizeId: 1,
    price: 4454,
    image: cabinetImage,
    timestampCreated: 'Nov 27 2019 00:00:00 GMT+0200'
  },
  {
    id: 110,
    title: 'Custom Cabinet #8',
    colorId: 5,
    materialId: 2,
    skeletonMaterialId: 2,
    doorsMaterialId: 2,
    sizeId: 1,
    price: 4998,
    image: cabinetImage,
    timestampCreated: 'Nov 28 2019 00:00:00 GMT+0200'
  },
  {
    id: 111,
    title: 'Custom Cabinet #9',
    colorId: 3,
    materialId: 1,
    skeletonMaterialId: 1,
    doorsMaterialId: 1,
    sizeId: 3,
    price: 10135,
    image: cabinetImage,
    timestampCreated: 'Nov 29 2019 00:00:00 GMT+0200'
  },
  {
    id: 112,
    title: 'Custom Cabinet #10',
    colorId: 5,
    materialId: 4,
    skeletonMaterialId: 4,
    doorsMaterialId: 4,
    sizeId: 1,
    price: 4998,
    image: cabinetImage,
    timestampCreated: 'Nov 30 2019 00:00:00 GMT+0200'
  }
];

export const dummyMakeCustomCabinet = {
  id: 201,
  title: 'Custom Cabinet #1',
  colorId: 3,
  materialId: 5,
  skeletonMaterialId: 5,
  doorsMaterialId: 5,
  sizeId: 3,
  price: 10135,
  image: cabinetImage,
  timestampCreated: 'Nov 21 2019 00:00:00 GMT+0200'
};

export const orders = [
  {
    id: 234235,
    state: orderStates.IN_TRANSIT,
    timestampCreated: 'Tue Nov 21 2019 00:00:00 GMT+0200',
    timestampDelivered: null,
    items: [
      {
        id: 101,
        title: 'For my granny',
        colorId: 1,
        materialId: 3,
        sizeId: 1,
        amount: 1,
        price: 12456,
        image: cabinetImage
      },
      {
        id: 102,
        title: 'To Office',
        colorId: 2,
        materialId: 4,
        sizeId: 2,
        amount: 2,
        price: 2456,
        image: cabinetImage
      }
    ]
  },
  {
    id: 584564,
    state: orderStates.MANUFACTURING,
    timestampCreated: 'Tue Nov 26 2019 00:00:00 GMT+0200',
    timestampDelivered: null,
    items: [
      {
        id: 103,
        title: 'Custom Cabinet #1',
        colorId: 3,
        materialId: 5,
        sizeId: 3,
        amount: 4,
        price: 10135,
        image: cabinetImage
      }
    ]
  },
  {
    id: 177348,
    state: orderStates.DELIVERED,
    timestampCreated: 'Tue Nov 28 2019 00:00:00 GMT+0200',
    timestampDelivered: 'Tue Dec 10 2019 00:00:00 GMT+0200',
    items: [
      {
        id: 104,
        title: 'Custom Cabinet #2',
        colorId: 5,
        materialId: 1,
        sizeId: 1,
        amount: 1,
        price: 4998,
        image: cabinetImage
      }
    ]
  }
];

const msInDay = 86400000;

export const manufacturers = [
  {
    id: 1,
    title: 'Pinewood Hills Factory',
    city: 'Hanover',
    deliveryDate: new Date(Date.now() + msInDay * 8),
    deliveryPrice: 0,
    cabinetPrice: 100,
    image: manufacturerImage1
  },
  {
    id: 2,
    title: 'Old Agnes Woodworks',
    city: 'Mannheim',
    deliveryDate: new Date(Date.now() + msInDay * 5),
    deliveryPrice: 59.99,
    cabinetPrice: 500,
    image: manufacturerImage2
  },
  {
    id: 3,
    title: 'Gerr Gerüstbauer',
    city: 'Aglasterhausen',
    deliveryDate: new Date(Date.now() + msInDay * 10),
    deliveryPrice: 10,
    cabinetPrice: 900,
    image: manufacturerImage1
  }
];

export const dummyCities = [
  {
    city: 'Berlin',
    admin: 'Berlin',
    country: 'Germany',
    population_proper: '3094014',
    iso2: 'DE',
    capital: 'primary',
    lat: '52.516667',
    lng: '13.4',
    population: '3406000'
  },
  {
    city: 'Stuttgart',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '606588',
    iso2: 'DE',
    capital: 'admin',
    lat: '48.782343',
    lng: '9.180819',
    population: '2944700'
  },
  {
    city: 'Frankfurt',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '679664',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.11552',
    lng: '8.684167',
    population: '2895000'
  },
  {
    city: 'Mannheim',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '313174',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.496706',
    lng: '8.479547',
    population: '2362000'
  },
  {
    city: 'Hamburg',
    admin: 'Hamburg',
    country: 'Germany',
    population_proper: '1739117',
    iso2: 'DE',
    capital: 'admin',
    lat: '53.575323',
    lng: '10.01534',
    population: '1757000'
  },
  {
    city: 'Essen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '573468',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.45657',
    lng: '7.012282',
    population: '1742135'
  },
  {
    city: 'Duisburg',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '488005',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.432469',
    lng: '6.765161',
    population: '1276757'
  },
  {
    city: 'Munich',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '1260391',
    iso2: 'DE',
    capital: 'admin',
    lat: '48.15',
    lng: '11.583333',
    population: '1275000'
  },
  {
    city: 'Dsseldorf',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '592393',
    iso2: 'DE',
    capital: 'admin',
    lat: '51.228304',
    lng: '6.793849',
    population: '1220000'
  },
  {
    city: 'Cologne',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '963395',
    iso2: 'DE',
    capital: '',
    lat: '50.933333',
    lng: '6.95',
    population: '1004000'
  },
  {
    city: 'Wuppertal',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '349470',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.270268',
    lng: '7.167553',
    population: '776525'
  },
  {
    city: 'Saarbrcken',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '175741',
    iso2: 'DE',
    capital: 'admin',
    lat: '49.235396',
    lng: '6.981646',
    population: '770001'
  },
  {
    city: 'Marienberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '499237',
    iso2: 'DE',
    capital: '',
    lat: '49.486021',
    lng: '11.086156',
    population: '737304'
  },
  {
    city: 'Bremen',
    admin: 'Bremen',
    country: 'Germany',
    population_proper: '546501',
    iso2: 'DE',
    capital: 'admin',
    lat: '53.073789',
    lng: '8.826754',
    population: '724909'
  },
  {
    city: 'Hannover',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '515140',
    iso2: 'DE',
    capital: 'admin',
    lat: '52.38497',
    lng: '9.737141',
    population: '722490'
  },
  {
    city: 'Bonn',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '313125',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.73438',
    lng: '7.095485',
    population: '680543'
  },
  {
    city: 'Dresden',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '486854',
    iso2: 'DE',
    capital: 'admin',
    lat: '51.048562',
    lng: '13.745794',
    population: '617515'
  },
  {
    city: 'Wiesbaden',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '272432',
    iso2: 'DE',
    capital: 'admin',
    lat: '50.082582',
    lng: '8.249322',
    population: '617126'
  },
  {
    city: 'Dortmund',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '588462',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.514942',
    lng: '7.465997',
    population: '588462'
  },
  {
    city: 'Leipzig',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '504971',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.34419',
    lng: '12.386504',
    population: '542529'
  },
  {
    city: 'Heidelberg',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '143345',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.404123',
    lng: '8.683733',
    population: '426590'
  },
  {
    city: 'Karlsruhe',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '283799',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.009365',
    lng: '8.40444',
    population: '377487'
  },
  {
    city: 'Augsburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '259196',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.371538',
    lng: '10.898514',
    population: '358989'
  },
  {
    city: 'Bielefeld',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '291573',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.028332',
    lng: '8.542002',
    population: '331906'
  },
  {
    city: 'Koblenz',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '107319',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.353573',
    lng: '7.578835',
    population: '312633'
  },
  {
    city: 'Altchemnitz',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '247220',
    iso2: 'DE',
    capital: '',
    lat: '50.811536',
    lng: '12.912827',
    population: '302643'
  },
  {
    city: 'Kassel',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '194501',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.313782',
    lng: '9.484645',
    population: '289924'
  },
  {
    city: 'Mnster',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '237041',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.961114',
    lng: '7.624239',
    population: '270184'
  },
  {
    city: 'Kiel',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '232758',
    iso2: 'DE',
    capital: 'admin',
    lat: '54.312145',
    lng: '10.135526',
    population: '269427'
  },
  {
    city: 'Freiburg',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '215966',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.996861',
    lng: '7.84973',
    population: '254889'
  },
  {
    city: 'Braunschweig',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '235054',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.265939',
    lng: '10.526726',
    population: '244715'
  },
  {
    city: 'Frth',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '112025',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.48121',
    lng: '10.96762',
    population: '237844'
  },
  {
    city: 'Lbeck',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '212207',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.86687',
    lng: '10.688367',
    population: '235390'
  },
  {
    city: 'Osnabrck',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '166462',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.272642',
    lng: '8.049799',
    population: '231268'
  },
  {
    city: 'Magdeburg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '224931',
    iso2: 'DE',
    capital: 'admin',
    lat: '52.127731',
    lng: '11.629163',
    population: '229826'
  },
  {
    city: 'Potsdam',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '145292',
    iso2: 'DE',
    capital: 'admin',
    lat: '52.402247',
    lng: '13.057122',
    population: '218095'
  },
  {
    city: 'Erfurt',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '175476',
    iso2: 'DE',
    capital: 'admin',
    lat: '50.978695',
    lng: '11.032831',
    population: '203254'
  },
  {
    city: 'Rostock',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '198293',
    iso2: 'DE',
    capital: '',
    lat: '54.086813',
    lng: '12.113453',
    population: '203080'
  },
  {
    city: 'Mainz',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '184997',
    iso2: 'DE',
    capital: 'admin',
    lat: '49.984188',
    lng: '8.279096',
    population: '184997'
  },
  {
    city: 'Ulm',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '120451',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.397053',
    lng: '9.968956',
    population: '172955'
  },
  {
    city: 'Wrzburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '133731',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.793912',
    lng: '9.951214',
    population: '168561'
  },
  {
    city: 'Oldenburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '159218',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.141178',
    lng: '8.214674',
    population: '167458'
  },
  {
    city: 'Regensburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '129151',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.034512',
    lng: '12.119234',
    population: '164359'
  },
  {
    city: 'Ingolstadt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '120658',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.765081',
    lng: '11.423725',
    population: '163325'
  },
  {
    city: 'G\u00f6ttingen',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '122149',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.539334',
    lng: '9.934057',
    population: '139419'
  },
  {
    city: 'Bremerhaven',
    admin: 'Bremen',
    country: 'Germany',
    population_proper: '117446',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.550209',
    lng: '8.576735',
    population: '137751'
  },
  {
    city: 'Cottbus',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '84754',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.757691',
    lng: '14.328875',
    population: '105067'
  },
  {
    city: 'Jena',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '95171',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.928782',
    lng: '11.589901',
    population: '104712'
  },
  {
    city: 'Gera',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '103055',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.875507',
    lng: '12.083296',
    population: '104659'
  },
  {
    city: 'Flensburg',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '85838',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.78431',
    lng: '9.439611',
    population: '97930'
  },
  {
    city: 'Schwerin',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '96641',
    iso2: 'DE',
    capital: 'admin',
    lat: '53.628373',
    lng: '11.409462',
    population: '96641'
  },
  {
    city: 'Rosenheim',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '60167',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.857316',
    lng: '12.119255',
    population: '92809'
  },
  {
    city: 'Gie\u00dfen',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '74411',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.58727',
    lng: '8.675536',
    population: '82358'
  },
  {
    city: 'Stralsund',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '58976',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.315823',
    lng: '13.088588',
    population: '61368'
  },
  {
    city: 'Coburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '41901',
    iso2: 'DE',
    capital: '',
    lat: '50.259366',
    lng: '10.963843',
    population: '61054'
  },
  {
    city: 'Hofeck',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '49239',
    iso2: 'DE',
    capital: '',
    lat: '50.330895',
    lng: '11.892585',
    population: '56153'
  },
  {
    city: 'Emden',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '48046',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.367454',
    lng: '7.207776',
    population: '51526'
  },
  {
    city: 'Detmold',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.943277',
    lng: '8.872182',
    population: ''
  },
  {
    city: 'Mei\u00dfen',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.15',
    lng: '13.483333',
    population: ''
  },
  {
    city: 'Kitzingen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.739731',
    lng: '10.150719',
    population: ''
  },
  {
    city: 'Dingolfing',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.62958',
    lng: '12.497606',
    population: ''
  },
  {
    city: 'Heppenheim',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.641447',
    lng: '8.632063',
    population: ''
  },
  {
    city: 'Torgau',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.56016',
    lng: '12.996166',
    population: ''
  },
  {
    city: 'Hanau',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.134229',
    lng: '8.914176',
    population: ''
  },
  {
    city: 'Husum',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.482518',
    lng: '9.059913',
    population: ''
  },
  {
    city: 'Schwandorf',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.32534',
    lng: '12.109795',
    population: ''
  },
  {
    city: 'Bitburg',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.967937',
    lng: '6.527343',
    population: ''
  },
  {
    city: 'Cham',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.225651',
    lng: '12.655013',
    population: ''
  },
  {
    city: 'Traunstein',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.868247',
    lng: '12.643347',
    population: ''
  },
  {
    city: 'Lchow',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.968114',
    lng: '11.153968',
    population: ''
  },
  {
    city: 'Gifhorn',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.477743',
    lng: '10.551095',
    population: ''
  },
  {
    city: 'Biberach',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.099942',
    lng: '9.786893',
    population: ''
  },
  {
    city: 'Bad Reichenhall',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.729467',
    lng: '12.878193',
    population: ''
  },
  {
    city: 'Knzelsau',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.281802',
    lng: '9.683525',
    population: ''
  },
  {
    city: 'Wei\u00dfenburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.030564',
    lng: '10.972771',
    population: ''
  },
  {
    city: 'Regen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.971901',
    lng: '13.128243',
    population: ''
  },
  {
    city: 'Nuremberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.447778',
    lng: '11.068333',
    population: ''
  },
  {
    city: 'Aurich',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.469193',
    lng: '7.482319',
    population: ''
  },
  {
    city: 'Nordhorn',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.432784',
    lng: '7.068896',
    population: ''
  },
  {
    city: 'Aichach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.461421',
    lng: '11.134257',
    population: ''
  },
  {
    city: 'Marburg',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.809044',
    lng: '8.770694',
    population: ''
  },
  {
    city: 'G\u00f6rlitz',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.166667',
    lng: '15.0',
    population: ''
  },
  {
    city: 'Vechta',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.726324',
    lng: '8.285982',
    population: ''
  },
  {
    city: 'Trier',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.75979',
    lng: '6.636628',
    population: ''
  },
  {
    city: 'Pirmasens',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.204106',
    lng: '7.603486',
    population: ''
  },
  {
    city: 'Pirna',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.958054',
    lng: '13.938197',
    population: ''
  },
  {
    city: 'Neustadt',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.350089',
    lng: '8.138863',
    population: ''
  },
  {
    city: 'Beeskow',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.17291',
    lng: '14.245973',
    population: ''
  },
  {
    city: 'Westerstede',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.256822',
    lng: '7.927372',
    population: ''
  },
  {
    city: 'Verden',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.923425',
    lng: '9.234908',
    population: ''
  },
  {
    city: 'Worms',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.632784',
    lng: '8.359164',
    population: ''
  },
  {
    city: 'Dren',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.804337',
    lng: '6.492988',
    population: ''
  },
  {
    city: 'Landsberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.047642',
    lng: '10.869584',
    population: ''
  },
  {
    city: 'Ludwigsburg',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.893886',
    lng: '9.195419',
    population: ''
  },
  {
    city: 'Meiningen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.570435',
    lng: '10.417231',
    population: ''
  },
  {
    city: 'Siegen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.874815',
    lng: '8.02431',
    population: ''
  },
  {
    city: 'Deggendorf',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.840855',
    lng: '12.960676',
    population: ''
  },
  {
    city: 'Peine',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.319277',
    lng: '10.2352',
    population: ''
  },
  {
    city: 'Frankfurt (Oder)',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.344312',
    lng: '14.53498',
    population: ''
  },
  {
    city: 'Nienburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.646102',
    lng: '9.220856',
    population: ''
  },
  {
    city: 'Brake',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.326867',
    lng: '8.480492',
    population: ''
  },
  {
    city: 'Memmingen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.985359',
    lng: '10.182507',
    population: ''
  },
  {
    city: 'Kirchheimbolanden',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.662487',
    lng: '8.015131',
    population: ''
  },
  {
    city: 'Tauberbischofsheim',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.624722',
    lng: '9.662778',
    population: ''
  },
  {
    city: 'Emmendingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.118466',
    lng: '7.848932',
    population: ''
  },
  {
    city: 'Warendorf',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.95109',
    lng: '7.987557',
    population: ''
  },
  {
    city: 'Bad Segeberg',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.943131',
    lng: '10.302154',
    population: ''
  },
  {
    city: 'Rotenburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.111251',
    lng: '9.410819',
    population: ''
  },
  {
    city: 'Kronach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.238777',
    lng: '11.33049',
    population: ''
  },
  {
    city: 'Darmstadt',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.871674',
    lng: '8.650271',
    population: ''
  },
  {
    city: 'Mindelheim',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.045781',
    lng: '10.492223',
    population: ''
  },
  {
    city: 'Bergheim',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.954875',
    lng: '6.64094',
    population: ''
  },
  {
    city: 'Donauw\u00f6rth',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.694951',
    lng: '10.779154',
    population: ''
  },
  {
    city: 'Korbach',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.274307',
    lng: '8.87196',
    population: ''
  },
  {
    city: 'Herzberg',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.691501',
    lng: '13.232048',
    population: ''
  },
  {
    city: 'Hameln',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.109245',
    lng: '9.365097',
    population: ''
  },
  {
    city: 'Suhl',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.609111',
    lng: '10.694009',
    population: ''
  },
  {
    city: 'Friedberg',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.337391',
    lng: '8.755907',
    population: ''
  },
  {
    city: 'Hof',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.31622',
    lng: '11.918332',
    population: ''
  },
  {
    city: 'Neuburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.732181',
    lng: '11.18709',
    population: ''
  },
  {
    city: 'Bad Kissingen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.202278',
    lng: '10.077836',
    population: ''
  },
  {
    city: 'Viersen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.254348',
    lng: '6.394414',
    population: ''
  },
  {
    city: 'Birkenfeld',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.652447',
    lng: '7.166679',
    population: ''
  },
  {
    city: 'Bad Fallingbostel',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.866414',
    lng: '9.695579',
    population: ''
  },
  {
    city: 'Halle',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.481473',
    lng: '11.967112',
    population: ''
  },
  {
    city: 'Bamberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.893174',
    lng: '10.88883',
    population: ''
  },
  {
    city: 'Frstenfeldbruck',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.18662',
    lng: '11.27586',
    population: ''
  },
  {
    city: 'Neuss',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.198071',
    lng: '6.685037',
    population: ''
  },
  {
    city: 'Bad Kreuznach',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.841396',
    lng: '7.867127',
    population: ''
  },
  {
    city: 'Heilbronn',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.13995',
    lng: '9.220541',
    population: ''
  },
  {
    city: 'Bad Ems',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.332737',
    lng: '7.717548',
    population: ''
  },
  {
    city: 'Schw\u00e4bisch Hall',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.105833',
    lng: '9.734167',
    population: ''
  },
  {
    city: 'Offenburg',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.473768',
    lng: '7.944946',
    population: ''
  },
  {
    city: 'Saalfeld',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.648259',
    lng: '11.365363',
    population: ''
  },
  {
    city: 'Wolfenbttel',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.164421',
    lng: '10.540946',
    population: ''
  },
  {
    city: 'Altenkirchen',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.685945',
    lng: '7.64176',
    population: ''
  },
  {
    city: 'Pforzheim',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.884361',
    lng: '8.698923',
    population: ''
  },
  {
    city: 'Gnzburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.455989',
    lng: '10.276951',
    population: ''
  },
  {
    city: 'Euskirchen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.660569',
    lng: '6.787224',
    population: ''
  },
  {
    city: 'Chemnitz',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.835701',
    lng: '12.929217',
    population: ''
  },
  {
    city: 'Rendsburg',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.306634',
    lng: '9.663126',
    population: ''
  },
  {
    city: 'Tirschenreuth',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.884428',
    lng: '12.337277',
    population: ''
  },
  {
    city: 'Offenbach',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.100605',
    lng: '8.766466',
    population: ''
  },
  {
    city: 'Uelzen',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.96572',
    lng: '10.561106',
    population: ''
  },
  {
    city: 'Zwickau',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.72724',
    lng: '12.488394',
    population: ''
  },
  {
    city: 'Schwabach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.330468',
    lng: '11.023461',
    population: ''
  },
  {
    city: 'Gelsenkirchen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.507502',
    lng: '7.122826',
    population: ''
  },
  {
    city: 'Mettmann',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.250404',
    lng: '6.975361',
    population: ''
  },
  {
    city: 'Ravensburg',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.781982',
    lng: '9.61062',
    population: ''
  },
  {
    city: 'Leer',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.233724',
    lng: '7.456431',
    population: ''
  },
  {
    city: 'Wittmund',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.576739',
    lng: '7.77839',
    population: ''
  },
  {
    city: 'Ingelheim',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.970782',
    lng: '8.058826',
    population: ''
  },
  {
    city: 'H\u00f6xter',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.775814',
    lng: '9.378885',
    population: ''
  },
  {
    city: 'Oranienburg',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.755772',
    lng: '13.241967',
    population: ''
  },
  {
    city: 'Erbach',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.661484',
    lng: '8.994017',
    population: ''
  },
  {
    city: 'Freising',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.402654',
    lng: '11.747976',
    population: ''
  },
  {
    city: 'Landau',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.198444',
    lng: '8.116921',
    population: ''
  },
  {
    city: 'Stendal',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.606895',
    lng: '11.858671',
    population: ''
  },
  {
    city: 'Balingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.277142',
    lng: '8.852118',
    population: ''
  },
  {
    city: 'Reutlingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.488779',
    lng: '9.213698',
    population: ''
  },
  {
    city: 'Eisenach',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.980701',
    lng: '10.315221',
    population: ''
  },
  {
    city: 'Tuttlingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.984644',
    lng: '8.817702',
    population: ''
  },
  {
    city: 'Neumnster',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.074773',
    lng: '9.981954',
    population: ''
  },
  {
    city: 'Neu-Ulm',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.400724',
    lng: '9.99028',
    population: ''
  },
  {
    city: 'K\u00f6then',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.751855',
    lng: '11.970928',
    population: ''
  },
  {
    city: 'Schleiz',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.578663',
    lng: '11.810237',
    population: ''
  },
  {
    city: 'Garmisch-Partenkirchen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.498475',
    lng: '11.104356',
    population: ''
  },
  {
    city: 'Baden-Baden',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.757631',
    lng: '8.242458',
    population: ''
  },
  {
    city: 'Bayreuth',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.947823',
    lng: '11.578935',
    population: ''
  },
  {
    city: 'Wunsiedel',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.043134',
    lng: '12.004459',
    population: ''
  },
  {
    city: 'Osterode',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.726856',
    lng: '10.250894',
    population: ''
  },
  {
    city: 'Sankt Wendel',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.466326',
    lng: '7.168142',
    population: ''
  },
  {
    city: 'Ldenscheid',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.219774',
    lng: '7.6273',
    population: ''
  },
  {
    city: 'Plauen',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.497305',
    lng: '12.137823',
    population: ''
  },
  {
    city: 'Forst',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.735443',
    lng: '14.63971',
    population: ''
  },
  {
    city: 'Pfaffenhofen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.53159',
    lng: '11.506415',
    population: ''
  },
  {
    city: 'Bochum',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.481651',
    lng: '7.216482',
    population: ''
  },
  {
    city: 'Weimar',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.980301',
    lng: '11.32903',
    population: ''
  },
  {
    city: 'Wilhelmshaven',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.529977',
    lng: '8.112526',
    population: ''
  },
  {
    city: 'Limburg',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.383597',
    lng: '8.050302',
    population: ''
  },
  {
    city: 'Freyung',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.80952',
    lng: '13.547679',
    population: ''
  },
  {
    city: 'Merseburg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.35478',
    lng: '11.989233',
    population: ''
  },
  {
    city: 'Halberstadt',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.895617',
    lng: '11.056224',
    population: ''
  },
  {
    city: 'Dessau-Ro\u00dflau',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.848277',
    lng: '12.229065',
    population: ''
  },
  {
    city: 'Weiden',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.676824',
    lng: '12.156126',
    population: ''
  },
  {
    city: 'Altenburg',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.987632',
    lng: '12.436838',
    population: ''
  },
  {
    city: 'Heide',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.195794',
    lng: '9.098797',
    population: ''
  },
  {
    city: 'B\u00f6blingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.684953',
    lng: '9.029552',
    population: ''
  },
  {
    city: 'Kulmbach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.100684',
    lng: '11.450319',
    population: ''
  },
  {
    city: 'Homberg',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.029943',
    lng: '9.402612',
    population: ''
  },
  {
    city: 'Perleberg',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.07583',
    lng: '11.857394',
    population: ''
  },
  {
    city: 'Mlheim',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.431391',
    lng: '6.883666',
    population: ''
  },
  {
    city: 'Northeim',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.706616',
    lng: '9.999975',
    population: ''
  },
  {
    city: 'Salzwedel',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.854354',
    lng: '11.152503',
    population: ''
  },
  {
    city: 'Cuxhaven',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.866887',
    lng: '8.70168',
    population: ''
  },
  {
    city: 'Pl\u00f6n',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.162408',
    lng: '10.423328',
    population: ''
  },
  {
    city: 'Mhlhausen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.208959',
    lng: '10.452753',
    population: ''
  },
  {
    city: 'Kempten',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.732209',
    lng: '10.315858',
    population: ''
  },
  {
    city: 'Gstrow',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.798852',
    lng: '12.178073',
    population: ''
  },
  {
    city: 'Lichtenfels',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.148152',
    lng: '11.069607',
    population: ''
  },
  {
    city: 'Bad Salzungen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.813418',
    lng: '10.236103',
    population: ''
  },
  {
    city: 'Weilheim',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.841468',
    lng: '11.15484',
    population: ''
  },
  {
    city: 'Jever',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.573676',
    lng: '7.898061',
    population: ''
  },
  {
    city: 'Arnstadt',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.840478',
    lng: '10.95198',
    population: ''
  },
  {
    city: 'Lneburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.250897',
    lng: '10.414087',
    population: ''
  },
  {
    city: 'Delmenhorst',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.051103',
    lng: '8.630908',
    population: ''
  },
  {
    city: 'Neubrandenburg',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.564144',
    lng: '13.275319',
    population: ''
  },
  {
    city: 'Bad Drkheim',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.461803',
    lng: '8.172358',
    population: ''
  },
  {
    city: 'Greiz',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.657785',
    lng: '12.199182',
    population: ''
  },
  {
    city: 'Alt\u00f6tting',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.225329',
    lng: '12.676646',
    population: ''
  },
  {
    city: 'Erding',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.30662',
    lng: '11.907496',
    population: ''
  },
  {
    city: 'Lbben',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.938144',
    lng: '13.888258',
    population: ''
  },
  {
    city: 'Holzminden',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.82901',
    lng: '9.45902',
    population: ''
  },
  {
    city: 'Wetzlar',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.561091',
    lng: '8.50495',
    population: ''
  },
  {
    city: 'Soest',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.571657',
    lng: '8.108861',
    population: ''
  },
  {
    city: 'Mosbach',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.353573',
    lng: '9.151062',
    population: ''
  },
  {
    city: 'Heilbad Heiligenstadt',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.378187',
    lng: '10.137445',
    population: ''
  },
  {
    city: 'Neustadt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.732873',
    lng: '12.17773',
    population: ''
  },
  {
    city: 'Calw',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.716828',
    lng: '8.737612',
    population: ''
  },
  {
    city: 'Kleve',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.788258',
    lng: '6.138646',
    population: ''
  },
  {
    city: 'Annaberg-Buchholz',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.579985',
    lng: '13.003333',
    population: ''
  },
  {
    city: 'Wismar',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.893145',
    lng: '11.452859',
    population: ''
  },
  {
    city: 'Aachen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.774122',
    lng: '6.084726',
    population: ''
  },
  {
    city: 'Tbingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.533333',
    lng: '9.05',
    population: ''
  },
  {
    city: 'Freiberg',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.910894',
    lng: '13.338808',
    population: ''
  },
  {
    city: 'M\u00f6nchengladbach',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.185386',
    lng: '6.441723',
    population: ''
  },
  {
    city: 'Nordhausen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.501799',
    lng: '10.795695',
    population: ''
  },
  {
    city: 'Krefeld',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.339208',
    lng: '6.586152',
    population: ''
  },
  {
    city: 'Stadthagen',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.324242',
    lng: '9.205315',
    population: ''
  },
  {
    city: 'Hildesheim',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.154122',
    lng: '9.952068',
    population: ''
  },
  {
    city: 'Celle',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.61909',
    lng: '10.092232',
    population: ''
  },
  {
    city: 'Eberswalde',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.835127',
    lng: '13.82307',
    population: ''
  },
  {
    city: 'Recklinghausen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.61379',
    lng: '7.197381',
    population: ''
  },
  {
    city: 'Eisenberg',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.968602',
    lng: '11.902075',
    population: ''
  },
  {
    city: 'Kaufbeuren',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.882382',
    lng: '10.621923',
    population: ''
  },
  {
    city: 'S\u00f6mmerda',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.159143',
    lng: '11.115239',
    population: ''
  },
  {
    city: 'Remscheid',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.179829',
    lng: '7.192496',
    population: ''
  },
  {
    city: 'Greifswald',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.093107',
    lng: '13.387857',
    population: ''
  },
  {
    city: 'Rastatt',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.858512',
    lng: '8.209645',
    population: ''
  },
  {
    city: 'Naumburg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.149871',
    lng: '11.809792',
    population: ''
  },
  {
    city: 'Lauf',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.51386',
    lng: '11.282474',
    population: ''
  },
  {
    city: 'Amberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.442872',
    lng: '11.862672',
    population: ''
  },
  {
    city: 'Ratzeburg',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.694698',
    lng: '10.790845',
    population: ''
  },
  {
    city: 'Bad Homburg',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.229964',
    lng: '8.613056',
    population: ''
  },
  {
    city: 'Neustadt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.579531',
    lng: '10.611257',
    population: ''
  },
  {
    city: 'Herne',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.538797',
    lng: '7.225716',
    population: ''
  },
  {
    city: 'Sangerhausen',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.472209',
    lng: '11.295333',
    population: ''
  },
  {
    city: 'Forchheim',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.72376',
    lng: '11.068238',
    population: ''
  },
  {
    city: 'Eutin',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.140541',
    lng: '10.607507',
    population: ''
  },
  {
    city: 'Bad Oldesloe',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.809544',
    lng: '10.37997',
    population: ''
  },
  {
    city: 'Kelheim',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.921761',
    lng: '11.845459',
    population: ''
  },
  {
    city: 'Bad Neustadt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.324586',
    lng: '10.198731',
    population: ''
  },
  {
    city: 'Helmstedt',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.230039',
    lng: '11.008984',
    population: ''
  },
  {
    city: 'Heinsberg',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.063583',
    lng: '6.0998',
    population: ''
  },
  {
    city: 'Zweibrcken',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.246864',
    lng: '7.369766',
    population: ''
  },
  {
    city: 'Hagen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.360811',
    lng: '7.471679',
    population: ''
  },
  {
    city: 'Montabaur',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.435882',
    lng: '7.823203',
    population: ''
  },
  {
    city: 'Ha\u00dffurt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.03521',
    lng: '10.515604',
    population: ''
  },
  {
    city: 'Pinneberg',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.667324',
    lng: '9.789362',
    population: ''
  },
  {
    city: 'Apolda',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.026239',
    lng: '11.51638',
    population: ''
  },
  {
    city: 'Bad Schwalbach',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.141956',
    lng: '8.069639',
    population: ''
  },
  {
    city: 'Marktoberdorf',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.779637',
    lng: '10.617132',
    population: ''
  },
  {
    city: 'Winsen',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.357528',
    lng: '10.21282',
    population: ''
  },
  {
    city: 'Wesel',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.666897',
    lng: '6.620367',
    population: ''
  },
  {
    city: 'Landshut',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.529612',
    lng: '12.161791',
    population: ''
  },
  {
    city: 'Alzey',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.746574',
    lng: '8.115128',
    population: ''
  },
  {
    city: 'Homburg',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.326366',
    lng: '7.33867',
    population: ''
  },
  {
    city: 'Passau',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.574308',
    lng: '13.464016',
    population: ''
  },
  {
    city: 'Cloppenburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.847538',
    lng: '8.045',
    population: ''
  },
  {
    city: 'Miesbach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.791218',
    lng: '11.833358',
    population: ''
  },
  {
    city: 'Gotha',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.950945',
    lng: '10.705764',
    population: ''
  },
  {
    city: 'Schwelm',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.286348',
    lng: '7.293884',
    population: ''
  },
  {
    city: 'Kusel',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.537724',
    lng: '7.404717',
    population: ''
  },
  {
    city: 'Meschede',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.350202',
    lng: '8.283325',
    population: ''
  },
  {
    city: 'Steinfurt',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.135198',
    lng: '7.353441',
    population: ''
  },
  {
    city: 'Aschaffenburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.977035',
    lng: '9.15214',
    population: ''
  },
  {
    city: 'Paderborn',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.718127',
    lng: '8.757493',
    population: ''
  },
  {
    city: 'Karlstadt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.960341',
    lng: '9.772389',
    population: ''
  },
  {
    city: 'Waiblingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.831538',
    lng: '9.315456',
    population: ''
  },
  {
    city: 'Villingen-Schwenningen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.062262',
    lng: '8.49358',
    population: ''
  },
  {
    city: 'Rottweil',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.168228',
    lng: '8.618248',
    population: ''
  },
  {
    city: 'G\u00f6ppingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.703582',
    lng: '9.656463',
    population: ''
  },
  {
    city: 'Eichst\u00e4tt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.888543',
    lng: '11.196753',
    population: ''
  },
  {
    city: 'Freudenstadt',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.466951',
    lng: '8.41371',
    population: ''
  },
  {
    city: 'Schleswig',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '54.521561',
    lng: '9.558598',
    population: ''
  },
  {
    city: 'Erlangen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.595606',
    lng: '10.994974',
    population: ''
  },
  {
    city: 'Olpe',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.02899',
    lng: '7.851393',
    population: ''
  },
  {
    city: 'L\u00f6rrach',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.616288',
    lng: '7.664842',
    population: ''
  },
  {
    city: 'Ansbach',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.304808',
    lng: '10.593098',
    population: ''
  },
  {
    city: 'Wittlich',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.985958',
    lng: '6.89308',
    population: ''
  },
  {
    city: 'Neuruppin',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.925588',
    lng: '12.804559',
    population: ''
  },
  {
    city: 'Sonneberg',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.3592',
    lng: '11.174631',
    population: ''
  },
  {
    city: 'Bottrop',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.523918',
    lng: '6.928499',
    population: ''
  },
  {
    city: 'Ludwigshafen',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.481206',
    lng: '8.446414',
    population: ''
  },
  {
    city: 'Borken',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.843823',
    lng: '6.857743',
    population: ''
  },
  {
    city: 'Starnberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.0',
    lng: '11.35',
    population: ''
  },
  {
    city: 'Gummersbach',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.026079',
    lng: '7.564731',
    population: ''
  },
  {
    city: 'Lauterbach',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.635582',
    lng: '9.397773',
    population: ''
  },
  {
    city: 'Herford',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.114569',
    lng: '8.67343',
    population: ''
  },
  {
    city: 'Rathenow',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.609013',
    lng: '12.346825',
    population: ''
  },
  {
    city: 'Solingen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.173433',
    lng: '7.084502',
    population: ''
  },
  {
    city: 'Speyer',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.320833',
    lng: '8.431111',
    population: ''
  },
  {
    city: 'Siegburg',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.800194',
    lng: '7.207693',
    population: ''
  },
  {
    city: 'Burg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.271784',
    lng: '11.851402',
    population: ''
  },
  {
    city: 'Leverkusen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.030299',
    lng: '6.984316',
    population: ''
  },
  {
    city: 'Unna',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.537945',
    lng: '7.689687',
    population: ''
  },
  {
    city: 'Coesfeld',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.943489',
    lng: '7.168094',
    population: ''
  },
  {
    city: 'Cochem',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.145108',
    lng: '7.163793',
    population: ''
  },
  {
    city: 'Eschwege',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.183859',
    lng: '10.053294',
    population: ''
  },
  {
    city: 'Bad Hersfeld',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.87197',
    lng: '9.708908',
    population: ''
  },
  {
    city: 'Bad Neuenahr-Ahrweiler',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.543224',
    lng: '7.111305',
    population: ''
  },
  {
    city: 'Sondershausen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.369733',
    lng: '10.87011',
    population: ''
  },
  {
    city: 'Dachau',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.25838',
    lng: '11.449149',
    population: ''
  },
  {
    city: 'Meppen',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.69085',
    lng: '7.292763',
    population: ''
  },
  {
    city: 'Wolfsburg',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.424516',
    lng: '10.781499',
    population: ''
  },
  {
    city: 'Brandenburg',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.410928',
    lng: '12.551172',
    population: ''
  },
  {
    city: 'Sigmaringen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.088291',
    lng: '9.230327',
    population: ''
  },
  {
    city: 'Sonthofen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.518213',
    lng: '10.282619',
    population: ''
  },
  {
    city: 'Itzehoe',
    admin: 'Schleswig-Holstein',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.92516',
    lng: '9.517306',
    population: ''
  },
  {
    city: 'Bergisch Gladbach',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.985596',
    lng: '7.132985',
    population: ''
  },
  {
    city: 'Dillingen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.581529',
    lng: '10.495275',
    population: ''
  },
  {
    city: 'Saarlouis',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.313663',
    lng: '6.751539',
    population: ''
  },
  {
    city: 'Gro\u00df-Gerau',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.921394',
    lng: '8.48255',
    population: ''
  },
  {
    city: 'Oberhausen',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.473111',
    lng: '6.880739',
    population: ''
  },
  {
    city: 'Goslar',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.917204',
    lng: '10.434446',
    population: ''
  },
  {
    city: 'Neustadt',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.565583',
    lng: '8.843695',
    population: ''
  },
  {
    city: 'Germersheim',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.223056',
    lng: '8.363889',
    population: ''
  },
  {
    city: 'Hofheim',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.090194',
    lng: '8.449297',
    population: ''
  },
  {
    city: 'Ebersberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.077878',
    lng: '11.964944',
    population: ''
  },
  {
    city: 'Prenzlau',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.316246',
    lng: '13.862611',
    population: ''
  },
  {
    city: 'Bad T\u00f6lz',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.760761',
    lng: '11.56301',
    population: ''
  },
  {
    city: 'Parchim',
    admin: 'Mecklenburg-Western Pomerania',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.42718',
    lng: '11.849164',
    population: ''
  },
  {
    city: 'Luckenwalde',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.090293',
    lng: '13.167717',
    population: ''
  },
  {
    city: 'Bernburg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.794638',
    lng: '11.740096',
    population: ''
  },
  {
    city: 'Osterholz-Scharmbeck',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.226979',
    lng: '8.79528',
    population: ''
  },
  {
    city: 'Stade',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '53.593369',
    lng: '9.476292',
    population: ''
  },
  {
    city: 'Neumarkt',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.281558',
    lng: '11.457205',
    population: ''
  },
  {
    city: 'Salzgitter',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.083333',
    lng: '10.333333',
    population: ''
  },
  {
    city: 'Bautzen',
    admin: 'Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.18035',
    lng: '14.434941',
    population: ''
  },
  {
    city: 'Hildburghausen',
    admin: 'Thuringia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.426678',
    lng: '10.72889',
    population: ''
  },
  {
    city: 'Heidenheim',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.680587',
    lng: '10.152483',
    population: ''
  },
  {
    city: 'Wittenberg',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.86747',
    lng: '12.6377',
    population: ''
  },
  {
    city: 'Kaiserslautern',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.442996',
    lng: '7.771614',
    population: ''
  },
  {
    city: 'Miltenberg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.704515',
    lng: '9.267253',
    population: ''
  },
  {
    city: 'Coburg',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.261359',
    lng: '10.963256',
    population: ''
  },
  {
    city: 'Fulda',
    admin: 'Hesse',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.554729',
    lng: '9.683439',
    population: ''
  },
  {
    city: 'Senftenberg',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.525173',
    lng: '14.001635',
    population: ''
  },
  {
    city: 'Mhldorf',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.246695',
    lng: '12.521552',
    population: ''
  },
  {
    city: 'Merzig',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.443313',
    lng: '6.638738',
    population: ''
  },
  {
    city: 'Seelow',
    admin: 'Brandenburg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.533373',
    lng: '14.380005',
    population: ''
  },
  {
    city: 'Minden',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.289532',
    lng: '8.914552',
    population: ''
  },
  {
    city: 'Waldshut-Tiengen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '47.622491',
    lng: '8.199311',
    population: ''
  },
  {
    city: 'Neunkirchen',
    admin: 'Saarland',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.344493',
    lng: '7.180446',
    population: ''
  },
  {
    city: 'Neuwied',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.433601',
    lng: '7.470566',
    population: ''
  },
  {
    city: 'Daun',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '50.197155',
    lng: '6.829418',
    population: ''
  },
  {
    city: 'Esslingen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.7374',
    lng: '9.316551',
    population: ''
  },
  {
    city: 'Simmern',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.98198',
    lng: '7.523507',
    population: ''
  },
  {
    city: 'Gtersloh',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.906931',
    lng: '8.378535',
    population: ''
  },
  {
    city: 'Diepholz',
    admin: 'Lower Saxony',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.607826',
    lng: '8.370051',
    population: ''
  },
  {
    city: 'Frankenthal',
    admin: 'Rhineland-Palatinate',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '49.534143',
    lng: '8.353574',
    population: ''
  },
  {
    city: 'Straubing',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.881259',
    lng: '12.573853',
    population: ''
  },
  {
    city: 'Pfarrkirchen',
    admin: 'Bavaria',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.43233',
    lng: '12.938694',
    population: ''
  },
  {
    city: 'Hamm',
    admin: 'North Rhine-Westphalia',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '51.673991',
    lng: '7.804448',
    population: ''
  },
  {
    city: 'Haldensleben',
    admin: 'Saxony-Anhalt',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '52.286851',
    lng: '11.413255',
    population: ''
  },
  {
    city: 'Aalen',
    admin: 'Baden-Wrttemberg',
    country: 'Germany',
    population_proper: '',
    iso2: 'DE',
    capital: 'minor',
    lat: '48.834439',
    lng: '10.091336',
    population: ''
  }
];
