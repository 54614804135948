import DeuFlag from '../assets/images/flags/flag-deu.svg';
import EngFlag from '../assets/images/flags/flag-eng.svg';

export const orderStates = {
  IN_TRANSIT: 0,
  MANUFACTURING: 1,
  DELIVERED: 2
};

export const orderStateValues = ['IN TRANSIT', 'MANUFACTURING', 'DELIVERED'];

export const productSections = [
  {
    id: 1,
    value: 'Featured'
  },
  {
    id: 2,
    value: 'New'
  }
];

export const myOrdersPageFilterOptions = [
  {
    id: 1,
    value: 'Featured'
  },
  {
    id: 2,
    value: 'New'
  }
];

export const orderFilterOptionIds = {
  RECENT_AT_TOP: 0,
  LATEST_AT_TOP: 1,
  CHEAPEST_AT_TOP: 2,
  HIGHEST_PRICE_AT_TOP: 3
};

export const orderFilterOptions = [
  {
    id: orderFilterOptionIds.RECENT_AT_TOP,
    value: 'Recent at Top'
  },
  {
    id: orderFilterOptionIds.LATEST_AT_TOP,
    value: 'Latest at Top'
  },
  {
    id: orderFilterOptionIds.CHEAPEST_AT_TOP,
    value: 'Cheapest at Top'
  },
  {
    id: orderFilterOptionIds.HIGHEST_PRICE_AT_TOP,
    value: 'Highest Price at Top'
  }
];

export const manufacturerTableColumnNames = {
  MANUFACTURER: 0,
  DELIVERY_DATE: 1,
  DELIVERY_PRICE: 2,
  CABINET_PRICE: 3,
  TOTAL_PRICE: 4
};

export const manufacturerTableColumnTypes = {
  STRING: 0,
  NUMBER: 1,
  DATE: 2
};

export const manufacturerTableOrderTypes = {
  ASCENDING: 0,
  DESCENDING: 1
};

export const manufacturerTableColumns = {
  [manufacturerTableColumnNames.MANUFACTURER]:
    manufacturerTableColumnTypes.STRING,
  [manufacturerTableColumnNames.DELIVERY_DATE]:
    manufacturerTableColumnTypes.DATE,
  [manufacturerTableColumnNames.DELIVERY_PRICE]:
    manufacturerTableColumnTypes.NUMBER,
  [manufacturerTableColumnNames.CABINET_PRICE]:
    manufacturerTableColumnTypes.NUMBER,
  [manufacturerTableColumnNames.TOTAL_PRICE]:
    manufacturerTableColumnTypes.NUMBER
};

export const mapLocation = {
  location: {
    address: 'Wittenauer Str. 88-100, 13435 Berlin, Germany',
    lat: 37.42216, // to be updated
    lng: -122.08427, // to be updated
  },
  zoom: 11
};

export const languages = [
  { id: "de", name: "Deutsch", icon: DeuFlag },
  { id: "en", name: "English", icon: EngFlag },
];
