import * as SizesActionTypes from '../actionTypes/sizesActionTypes';

export const getSizes = () => {
  return { type: SizesActionTypes.GET_SIZES };
};

export const getSizesSuccessful = sizes => {
  return { type: SizesActionTypes.GET_SIZES_SUCCESS, payload: sizes };
};

export const getSizesFailed = error => {
  return { type: SizesActionTypes.GET_SIZES_ERROR, payload: error };
};
