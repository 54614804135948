import React from 'react';

const CheckmarkManufacturerIcon = (props) => {
const { color = '#1899A5', className = '' } = props;

return (
        <svg className={className}
             width="21px" height="17px" viewBox="0 0 21 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>UI/Buttons/Teal/check</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1690.000000, -424.000000)" fill={color} fillRule="nonzero">
                    <path d="M1693.32771,431.643979 C1692.50165,430.910705 1691.23755,430.985925
                    1690.50428,431.811989 C1689.771,432.638053 1689.84622,433.902147
                    1690.67229,434.635422 L1697.27415,440.495721 C1698.13035,441.255744
                    1699.4486,441.143302 1700.16371,440.249253 L1710.56185,427.249253 C1711.25179,426.386669
                    1711.11184,425.128097 1710.24925,424.438153 C1709.38667,423.748209
                    1708.1281,423.888162 1707.43815,424.750747 L1698.35507,436.106634 L1693.32771,431.643979 Z"
                          id="UI/Buttons/Teal/check"></path>
                </g>
            </g>
        </svg>
    );
}

export default CheckmarkManufacturerIcon;
