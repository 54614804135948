import React from 'react';

import CheckmarkManufacturerIcon from '../../../../../../assets/images/components/CheckmarkManufacturerIcon';
import './checkboxManufacturer.scss';

class CheckboxManufacturer extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      isChecked: nextProps.isActive
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.isChecked || false,
      isHover: false
    };
  }

  toggleChecked = () => {
    const { onClick } = this.props;
    const isChecked = !this.state.isChecked;
    this.setState({
      isChecked
    });
    onClick(isChecked);
  };

  toggleIconHover = () => {
    this.setState({
      isHover: !this.state.isHover
    });
  };

  render() {
    const { isChecked, isHover } = this.state;
    const { className } = this.props;

    let icon;
    if (isChecked) {
      icon = <CheckmarkManufacturerIcon color="#fff" />;
    } else if (isHover) {
      icon = <CheckmarkManufacturerIcon color="#fff" />;
    } else {
      icon = <CheckmarkManufacturerIcon color="#1899a5" />;
    }

    return (
      <div
        className={`ikt-ws_checkbox-manufacturer${isChecked ? ` checked` : ``}${
          className ? ` ${className}` : ``
        }`}
        onClick={this.toggleChecked}
        onMouseEnter={this.toggleIconHover}
        onMouseLeave={this.toggleIconHover}
      >
        {icon}
      </div>
    );
  }
}

export default CheckboxManufacturer;
