import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class FooterMenu extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="columns is-gapless footer-menu">
        <div className="column">
          <div className="footer-group-name"> { t("information") }</div>
          <div>
            <Link to="/faq" className="footer-navbar-item">
              FAQ
            </Link>
          </div>
          <div>
            <Link to="/terms" className="footer-navbar-item">
              { t("delivery_terms") }
            </Link>
          </div>
          <div> { t("conditions") }</div>
        </div>
        <div className="column">
          <div>&nbsp;</div>
          <div>  { t("measurement_instructions") }</div>
          <div>{ t("assembly_instructions") }</div>
          <div>Impressum</div>
        </div>
        <div className="column"></div>
        <div className="column">
          <div className="footer-group-name">{ t("company") }</div>
          <div>{ t("about_us") }</div>
          <div>
            <Link to="/contacts" className="footer-navbar-item">
            { t("contacts") }
            </Link>
          </div>
        </div>
        <div className="column">
          <div className="footer-group-name"> { t("legal_information") }</div>
          <div> { t("privacy_policy") }</div>
          <div> { t("terms_of_service") }</div>
          <div> { t("GDPR_notice") }</div>
        </div>
      </div>
    );
  }
}

export default (withTranslation()(FooterMenu));
