import * as MakeCustomCabinetActionTypes from '../actionTypes/makeCustomCabinetActionTypes';

export const addCustomCabinet = cabinet => {
  return { type: MakeCustomCabinetActionTypes.ADD_CABINET, payload: cabinet };
};

export const setCabinetSkeletonMateial = materialId => {
  return {
    type: MakeCustomCabinetActionTypes.SET_SKELETON_MATERIAL,
    payload: materialId
  };
};

export const setCabinetDoorsMaterial = materialId => {
  return {
    type: MakeCustomCabinetActionTypes.SET_DOORS_MATERIAL,
    payload: materialId
  };
};

export const setCabinetHandlesMaterial = materialId => {
  return {
    type: MakeCustomCabinetActionTypes.SET_HANDLES_MATERIAL,
    payload: materialId
  };
};

export const selectCabinetManufacturer = manufacturerId => {
  return {
    type: MakeCustomCabinetActionTypes.SELECT_MANUFACTURER,
    payload: manufacturerId
  };
};
