import AppConfig from '../../config/index';

export async function loginAPI({ email = '', password = '' }) {
  let body = new FormData();
  body.append('email', email);
  body.append('password', password);

  const fetchResult = await fetch(`${AppConfig.baseURL}api/auth/login`, {
    method: 'post',
    body,
    mode: 'cors'
  });

  const authResponse = await fetchResult.json();

  return {
    ok: fetchResult.ok,
    response: authResponse
  };
}

export async function getJwtAPI({ token = '' }) {
  let headers = new Headers({
    Authorization: `Bearer ${token}`
  });

  const fetchResult = await fetch(`${AppConfig.baseURL}api/auth/get-jwt`, {
    method: 'post',
    headers,
    mode: 'cors'
  });

  const authResponse = await fetchResult.json();

  return {
    ok: fetchResult.ok,
    response: authResponse
  };
}

export async function registerAPI({ name = '', email = '', password = '' }) {
    let body = new FormData();
    body.append('name', name);
    body.append('email', email);
    body.append('password', password);

    const fetchResult = await fetch(`${AppConfig.baseURL}api/auth/register`, {
        method: 'post',
        body,
        mode: 'cors'
    });

    const authResponse = await fetchResult.json();

    return {
        ok: fetchResult.ok,
        response: authResponse
    };
}

export async function logoutAPI() {
  const token = localStorage.getItem('token');

  let headers = new Headers({
    Authorization: `Bearer ${token}`
  });

  const fetchResult = await fetch(`${AppConfig.baseURL}api/auth/logout`, {
    method: 'post',
    headers,
    mode: 'cors'
  });

  if (!fetchResult.ok) {
    console.error(
      `Bad response from server.  ${fetchResult.statusText} (${fetchResult.status})`
    );
  }
  return true;
}

export async function resetPasswordAPI(email = '') {
    let body = new FormData();
    body.append('email', email);

    const fetchResult = await fetch(`${AppConfig.baseURL}api/auth/reset-password`, {
        method: 'post',
        body,
        mode: 'cors'
    });

    const authResponse = await fetchResult.json();

    return {
        ok: fetchResult.ok,
        response: authResponse
    };
}
