import { combineEpics } from 'redux-observable';
import { getCities } from './cities';
import { getDepartments } from './departments';
import { getFeaturedProducts } from './featuredProducts';
import { getNewProducts } from './newProducts';
import { getFilters } from './filters';
import { getSingleProduct } from './singleProduct';
import { getMaterials } from './materials';
import { getStyles } from './styles';
import { getColors } from './colors';
import { getSizes } from './sizes';
import { getUserAccountData } from './userAccount';
import { getManufacturers } from './manufacturers';

export const rootEpic = combineEpics(
  getCities,
  getDepartments,
  getFeaturedProducts,
  getNewProducts,
  getFilters,
  getSingleProduct,
  getMaterials,
  getStyles,
  getColors,
  getSizes,
  getUserAccountData,
  getManufacturers
);
