import { toast } from 'react-toastify';
import * as AuthActionTypes from '../actionTypes/authActionTypes';
import { getUserAccountData } from './userAccountActions';
import {
  loginAPI,
  registerAPI,
  logoutAPI,
  getJwtAPI,
  resetPasswordAPI
} from '../../services/api/authAPI';

export const checkCurrentAuthStatus = () => {
  return (dispatch, getState) => {
    const tokenExpires = localStorage.getItem('token_expires');
    const token = localStorage.getItem('token');
    let isAuthorized = token && new Date(tokenExpires).getTime() > Date.now();
    if (isAuthorized) {
      dispatch(getUserAccountData());
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expires');
    }
    dispatch(authorize(isAuthorized));
    dispatch({
      type: AuthActionTypes.CHECK_CURRENT_AUTH_STATUS,
      payload: isAuthorized
    });
  };
};

export const checkAuthorization = ({ credentials, auth0Jwt }) => {
  return async (dispatch, getState) => {
    let response;
    if (credentials) {
      response = await loginAPI(credentials);
    } else if (auth0Jwt) {
      response = await getJwtAPI({ token: auth0Jwt });
    }
    let errors = {};
    if (response.ok) {
      const tokenExpires = new Date(
        Date.now() + response.response.expires_in * 1000
      );
      localStorage.setItem('token', response.response.access_token);
      localStorage.setItem('token_expires', tokenExpires.toString());

      dispatch(hideAuthPages());
      dispatch(authorize(true));
      dispatch(getUserAccountData());
    } else {
      if (response.response.email) {
        for (const error of response.response.email) {
          toast.error(error);
        }
      }
      if (response.response.password) {
        for (const error of response.response.password) {
          toast.error(error);
        }
      }

      if (response.response.error === 'Unauthorized') {
        toast.error(`Authorisation failed. Wrong credentials.`);
      }

      errors = response.response;
    }

    dispatch(setLoginErrors(errors));

    dispatch({
      type: AuthActionTypes.CHECK_AUTHORIZATION
    });
  };
};

export const register = credentials => {
  return async (dispatch, getState) => {
    const response = await registerAPI(credentials);

    let errors = {};
    if (response.ok) {
      const tokenExpires = new Date(
        Date.now() + response.response.expires_in * 1000
      );
      localStorage.setItem('token', response.response.access_token);
      localStorage.setItem('token_expires', tokenExpires.toString());
      toast.success(`You were successfully registered`);

      dispatch(hideAuthPages());
      dispatch(authorize(true));
      dispatch(getUserAccountData());
    } else {
      if (response.response.error) {
        // Handling error related to password inconsistency with server app rules
        let errorMessage = 'Password should comply with following rules: ';
        response.response.error.rules.forEach((rule, ruleIndex) => {
          let ruleMessage = rule.message;
          rule.format.forEach(formatVal => {
            ruleMessage = ruleMessage.replace(/%d/, formatVal);
          });
          if (rule.items) {
            ruleMessage += ' ';
            rule.items.forEach((ruleItem, ruleItemIndex) => {
              ruleMessage += `${ruleItem.message}${
                rule.items.length - 1 === ruleItemIndex ? `` : `; `
              }`;
            });
          }

          errorMessage += `${ruleMessage}${
            response.response.error.rules.length - 1 === ruleIndex ? `.` : `; `
          }`;
        });
        toast.error(errorMessage);
        errors.password = errorMessage;
      } else if (Object.keys(response.response).length > 0) {
        for (let errorType in response.response) {
          for (const error of response.response[errorType]) {
            toast.error(error);
          }
        }
        errors = response.response;
      }
    }

    dispatch(setRegistrationErrors(errors));

    dispatch({
      type: AuthActionTypes.REGISTER_USER
    });
  };
};

export const resetPassword = email => {
  return async (dispatch, getState) => {
    let response = await resetPasswordAPI(email);

    if (response.ok) {
      toast.success(
        `Letter with instructions for resetting password has been sent to your e-mail.`
      );
    } else {
      if (response.response.email) {
        for (const error of response.response.email) {
          toast.error(error);
        }
      }
      if (response.response.error) {
        toast.error(response.response.error);
      }
    }
    dispatch({ type: AuthActionTypes.RESET_PASSWORD, payload: null });
  };
};

export const setLoginErrors = errors => {
  return { type: AuthActionTypes.SET_LOGIN_ERRORS, payload: errors };
};

export const resetLoginErrors = fields => {
  return { type: AuthActionTypes.RESET_LOGIN_ERRORS, payload: fields };
};

export const setRegistrationErrors = errors => {
  return { type: AuthActionTypes.SET_REGISTRATION_ERRORS, payload: errors };
};

export const resetRegistrationErrors = fields => {
  return { type: AuthActionTypes.RESET_REGISTRATION_ERRORS, payload: fields };
};

export const authorize = (isAuthorized = false) => {
  return { type: AuthActionTypes.AUTHORIZE, payload: isAuthorized };
};

export const showLoginPage = () => {
  return async (dispatch, getState) => {
    dispatch(resetLoginErrors(['email', 'password']));
    dispatch({ type: AuthActionTypes.SHOW_LOGIN_PAGE, payload: null });
  };
};

export const showRegistrationPage = () => {
  return async (dispatch, getState) => {
    dispatch(
      resetRegistrationErrors([
        'email',
        'name',
        'surname',
        'password',
        'repeatPassword'
      ])
    );
    dispatch({ type: AuthActionTypes.SHOW_REGISTRATION_PAGE, payload: null });
  };
};

export const showResetPasswordPage = () => {
  return { type: AuthActionTypes.SHOW_RESET_PASSWORD_PAGE, payload: null };
};

export const hideAuthPages = () => {
  return {
    type: AuthActionTypes.HIDE_AUTH_PAGES,
    payload: null
  };
};

export const logOut = () => {
  logoutAPI();
  localStorage.removeItem('token');
  localStorage.removeItem('token_expires');
  return { type: AuthActionTypes.LOG_OUT, payload: null };
};
