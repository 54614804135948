import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonGold from '../../common/ButtonGold/ButtonGold';
import ButtonShaded from '../../common/ButtonShaded/ButtonShaded';
import CustomCabinet from '../CustomCabinet/CustomCabinet';
import Loader from '../../common/Loader/Loader';
import FooterSignUp from '../../common/FooterSignUp';

import './thankYouPage.scss';

class ThankYouPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderCabinet() {
    const { cabinet, sizes, materials, handlesMaterials } = this.props;

    return (
      <CustomCabinet
        cabinet={cabinet}
        sizes={sizes}
        materials={materials}
        handlesMaterials={handlesMaterials}
        isShowMaterials={true}
      />
    );
  }

  isAllDataReady() {
    const { cabinet, sizes, handlesMaterials, materials } = this.props;
    return (
      Object.keys(cabinet).length > 0 &&
      sizes.length > 0 &&
      handlesMaterials.length > 0 &&
      materials.length > 0
    );
  }

  render() {
    const { isAuthorized } = this.props;

    return (
      <div
        className={`ikt-ws_thank-you-page-container${
          isAuthorized ? ` authorized` : ``
        }`}
      >
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_thank-you-page-top">
              {this.renderCabinet()}
            </div>
            <div className="ikt-ws_thank-you-page-bottom">
              <div className="ikt-ws_thank-you-page-heading">Thank you!</div>
              <div className="ikt-ws_thank-you-page-text">
                Your custom cabinet has been added to cart.
                {isAuthorized ? ` Also we saved it in your account.` : null}
              </div>
              <div className="ikt-ws_thank-you-page-review-cart-container">
                <ButtonTeal
                  to="/cart"
                  className="ikt-ws_thank-you-page-review-cart-btn"
                >
                  REVIEW CART
                </ButtonTeal>
              </div>
              <div className="ikt-ws_thank-you-page-make-another-cab-container">
                <ButtonTeal
                  to="/make-cabinet/get-started"
                  className="ikt-ws_thank-you-page-make-another-cab-btn"
                >
                  MAKE ANOTHER CABINET
                </ButtonTeal>
              </div>
              {isAuthorized ? (
                <>
                  <div className="ikt-ws_thank-you-page-browse-my-cab-container">
                    <ButtonShaded
                      to="/cabinets"
                      className="ikt-ws_thank-you-page-browse-my-cab-btn"
                    >
                      BROWSE MY CABINETS
                    </ButtonShaded>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
        <FooterSignUp />
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    cabinet: state.makeCustomCabinet.cabinet,
    materials: state.materials.materials,
    handlesMaterials: state.materials.handlesMaterials,
    sizes: state.sizes.sizes,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(ThankYouPage));
