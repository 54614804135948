import React from 'react';
import Slider from 'react-slick';
import GalleryArrowL from '../../../assets/images/components/GalleryArrowL';
import GalleryArrowR from '../../../assets/images/components/GalleryArrowR';
import ProductCard from '../../common/ProductCard/ProductCard';
import './similarProductsSlider.scss';

class NextArrowBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNextBtnHover: false
    };
  }

  toggleNextBtnHover = () => {
    this.setState({
      isNextBtnHover: !this.state.isNextBtnHover
    });
  };

  render() {
    const { style, onClick } = this.props;
    const { isNextBtnHover } = this.state;

    return (
      <button
        className="ikt-ws_similar-products-slider-next-arrow"
        onClick={onClick}
        style={{ ...style }}
        onMouseEnter={this.toggleNextBtnHover}
        onMouseLeave={this.toggleNextBtnHover}
      >
        <GalleryArrowR color={isNextBtnHover ? '#b6b5b5' : '#979797'} />
      </button>
    );
  }
}

class PrevArrowBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrevBtnHover: false
    };
  }

  togglePrevBtnHover = () => {
    this.setState({
      isPrevBtnHover: !this.state.isPrevBtnHover
    });
  };

  render() {
    const { style, onClick } = this.props;
    const { isPrevBtnHover } = this.state;

    return (
      <button
        className="ikt-ws_similar-products-slider-prev-arrow"
        onClick={onClick}
        style={{ ...style }}
        onMouseEnter={this.togglePrevBtnHover}
        onMouseLeave={this.togglePrevBtnHover}
      >
        <GalleryArrowL color={isPrevBtnHover ? '#b6b5b5' : '#979797'} />
      </button>
    );
  }
}

class SimilarProductsSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrevBtnHover: false,
      isNextBtnHover: false
    };
  }

  togglePrevBtnHover = () => {
    this.setState({
      isPrevBtnHover: !this.state.isPrevBtnHover
    });
  };

  toggleNextBtnHover = () => {
    this.setState({
      isNextBtnHover: !this.state.isNextBtnHover
    });
  };

  onImageClick = index => {
    return () => {
      const { onImageClick } = this.props;

      this.setState({
        activeImageIndex: index
      });

      onImageClick(index);
    };
  };

  isImageDataReady() {
    const { images } = this.props;
    return images && images.length > 0;
  }

  render() {
    const { products } = this.props;

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 3,
      nextArrow: <NextArrowBtn />,
      prevArrow: <PrevArrowBtn />
    };
    return (
      <div className="ikt-ws_similar-products-slider">
        <Slider style={{ display: 'flex' }} {...sliderSettings}>
          {products &&
            products.map((elem, index) => {
              return (
                <ProductCard
                  key={index}
                  className="ikt-ws_shop-page-product-card"
                  data={elem}
                />
              );
            })}
        </Slider>
      </div>
    );
  }
}

export default SimilarProductsSlider;
