import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFeaturedProducts } from '../../store/actions/featuredProductsActions';
import { getNewProducts } from '../../store/actions/newProductsActions';
import {
  getFilters,
  toggleSelectedFilterOptionsAndFilterProducts,
  selectAllFilterOptionAndFilterProducts,
  activatePriceFilterAndFilterProducts,
  deactivatePriceFilterAndFilterProducts,
  activateDistanceFilterAndFilterProducts,
  deactivateDistanceFilterAndFilterProducts
} from '../../store/actions/filtersActions';
import ShopFilter from './ShopFilter/ShopFilter';
import ProductCard from '../common/ProductCard/ProductCard';
import Dropdown from '../common/Dropdown/Dropdown';
import Loader from '../common/Loader/Loader';
import { productSections } from '../../constants/constants';

import './shopPage.scss';

const FEATURED_PRODUCTS_SECTION_ID = 1;
const NEW_PRODUCTS_SECTION_ID = 2;

class ShopPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProductSectionId: FEATURED_PRODUCTS_SECTION_ID
    };
  }

  renderProducts = () => {
    const { activeProductSectionId } = this.state;
    const { featuredProductsFiltered, newProductsFiltered } = this.props;

    let products;
    switch (activeProductSectionId) {
      case FEATURED_PRODUCTS_SECTION_ID:
        products = featuredProductsFiltered;
        break;
      case NEW_PRODUCTS_SECTION_ID:
        products = newProductsFiltered;
        break;
    }

    const renderedProdcuts =
      products &&
      products.map((product, index) => {
        return (
          <ProductCard
            key={index}
            data={product}
            className="ikt-ws_shop-page-product-card"
          ></ProductCard>
        );
      });

    if (renderedProdcuts.length === 0) {
      return (
        <div className="ikt-ws_shop-page-no-products">
          There are no products found satisfying selected filters.
        </div>
      );
    } else {
      return (
        <div className="ikt-ws_products-container">{renderedProdcuts}</div>
      );
    }
  };

  renderFilters = () => {
    const {
      filters,
      selectedFilters,
      toggleSelectedFilterOptionsAndFilterProducts,
      selectAllFilterOptionAndFilterProducts,
      activatePriceFilterAndFilterProducts,
      deactivatePriceFilterAndFilterProducts,
      activateDistanceFilterAndFilterProducts,
      deactivateDistanceFilterAndFilterProducts
    } = this.props;
    return (
      filters &&
      filters.map((filter, index) => {
        let selectedFilterData = selectedFilters.filter(currSelectedFilter => {
          return currSelectedFilter.filterId === filter.id;
        })[0];

        return (
          <ShopFilter
            key={index}
            filterData={filter}
            toggleSelectedFilterOptionsAndFilterProducts={
              toggleSelectedFilterOptionsAndFilterProducts
            }
            selectAllFilterOptionAndFilterProducts={
              selectAllFilterOptionAndFilterProducts
            }
            selectedFilterData={selectedFilterData}
            activatePriceFilterAndFilterProducts={
              activatePriceFilterAndFilterProducts
            }
            deactivatePriceFilterAndFilterProducts={
              deactivatePriceFilterAndFilterProducts
            }
            activateDistanceFilterAndFilterProducts={
              activateDistanceFilterAndFilterProducts
            }
            deactivateDistanceFilterAndFilterProducts={
              deactivateDistanceFilterAndFilterProducts
            }
          ></ShopFilter>
        );
      })
    );
  };

  selectProductsSection = id => {
    const { featuredProducts, newProducts } = this.props;
    switch (id) {
      case FEATURED_PRODUCTS_SECTION_ID:
        if (!featuredProducts || featuredProducts.length === 0) {
          this.props.getFeaturedProducts();
        }
        break;
      case NEW_PRODUCTS_SECTION_ID:
        if (!newProducts || newProducts.length === 0) {
          this.props.getNewProducts();
        }
        break;
    }

    this.setState({
      activeProductSectionId: id
    });
  };

  updateProductsData(products, updateFunc) {
    if (!products || products.length === 0) {
      updateFunc();
    }
  }

  isAllDataReady() {
    const { featuredProducts, newProducts, filters } = this.props;

    const isAllDataReady =
      featuredProducts &&
      featuredProducts.length > 0 &&
      newProducts &&
      newProducts.length > 0 &&
      filters &&
      filters.length > 0;

    return isAllDataReady;
  }

  componentDidMount() {
    const {
      featuredProducts,
      newProducts,
      getFeaturedProducts,
      getNewProducts
    } = this.props;
    this.updateProductsData(featuredProducts, getFeaturedProducts);
    this.updateProductsData(newProducts, getNewProducts);
    this.props.getFilters();
  }

  render() {
    return (
      <div className="ikt-ws_shop-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_shop-page-top">
              <div className="ikt-ws_shop-page-title">Shop</div>
              <Dropdown
                className="ikt-ws_shop-page-section-dropdown"
                items={productSections}
                onClick={this.selectProductsSection}
              />
            </div>
            <div className="ikt-ws_filters-n-products-container">
              <div className="ikt-ws_filters-container">
                {this.renderFilters()}
              </div>
              {this.renderProducts()}
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    featuredProducts: state.featuredProducts.featuredProducts,
    newProducts: state.newProducts.newProducts,
    featuredProductsFiltered: state.featuredProducts.featuredProductsFiltered,
    newProductsFiltered: state.newProducts.newProductsFiltered,
    filters: state.filters.filters,
    selectedFilters: state.filters.selectedFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFeaturedProducts: searchParams =>
      dispatch(getFeaturedProducts(searchParams)),
    getNewProducts: searchParams => dispatch(getNewProducts(searchParams)),
    getFilters: searchParams => dispatch(getFilters(searchParams)),
    selectAllFilterOptionAndFilterProducts: filterId =>
      dispatch(selectAllFilterOptionAndFilterProducts(filterId)),
    toggleSelectedFilterOptionsAndFilterProducts: selectedFilterData =>
      dispatch(
        toggleSelectedFilterOptionsAndFilterProducts(selectedFilterData)
      ),
    activatePriceFilterAndFilterProducts: filterData =>
      dispatch(activatePriceFilterAndFilterProducts(filterData)),
    deactivatePriceFilterAndFilterProducts: filterId =>
      dispatch(deactivatePriceFilterAndFilterProducts(filterId)),
    activateDistanceFilterAndFilterProducts: filterData =>
      dispatch(activateDistanceFilterAndFilterProducts(filterData)),
    deactivateDistanceFilterAndFilterProducts: filterId =>
      dispatch(deactivateDistanceFilterAndFilterProducts(filterId))
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(ShopPage);
