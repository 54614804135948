const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const renderDate = timestamp => {
  const dateObj = new Date(timestamp);
  let month = dateObj.getUTCMonth() + 1;
  month = month.toString().length === 1 ? `0${month}` : month;
  let day = dateObj.getUTCDate();
  day = day.toString().length === 1 ? `0${day}` : day;
  const year = dateObj.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

export const renderManufacturerDate = timestamp => {
  const dateObj = new Date(timestamp);
  let month = monthNames[dateObj.getUTCMonth()];
  let day = dateObj.getUTCDate();
  day = day.toString().length === 1 ? `0${day}` : day;
  const year = dateObj.getUTCFullYear();

  return `${day} ${month}, ${year}`;
};
