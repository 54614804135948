import React from 'react';

import Button from '../Button/Button';
import './buttonBlue.scss';

/**
 * Usage:
 * <ButtonTeal className="ikt-ws_make-own-cabinet">
 *     MAKE YOUR OWN CABINET
 * </ButtonTeal>
 */

const ButtonBlue = (props) => {
  return (
    <Button {...props} className={`blue${props.className ? ` ${props.className}` : ``}`}>
      {props.children}
    </Button>
  )
};

export default ButtonBlue;

