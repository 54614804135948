import React from 'react';

const RegistrationArrowIcon = (props) => {
    const { color = '#1899A5', className = '' } = props;

    return (
        <svg className={className}
             width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>ui-arrow</title>
            <desc>Created with Sketch.</desc>
            <g id="Index" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ui-arrow" transform="translate(2.000000, 1.000000)" stroke={color} strokeWidth="2">
                    <polyline id="Path-6" points="6.8395723 13.6791446 0 6.8395723 6.8395723 0"></polyline>
                    <line x1="17.9529109" y1="6.8395723" x2="1.71868588" y2="6.8395723" id="Path-7"></line>
                </g>
            </g>
        </svg>
    );
}

export default RegistrationArrowIcon;
