import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getMaterialsSuccessful,
  getMaterialsFailed
} from '../store/actions/materialsActions';
import * as MaterialsActionTypes from '../store/actionTypes/materialsActionTypes';
import { skeletonMaterials, handlesMaterials } from './epicsData';

const dummyMaterials = {
  skeletonMaterials,
  handlesMaterials
};

export const getMaterials = actions$ => {
  return actions$.pipe(
    ofType(MaterialsActionTypes.GET_MATERIALS),
    delay(2000),
    mapTo(getMaterialsSuccessful(dummyMaterials)),
    catchError((error, caught) => merge(of(getMaterialsFailed(error)), caught))
  );
};
