import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getColorsSuccessful,
  getColorsFailed
} from '../store/actions/colorsActions';
import * as StylesActionTypes from '../store/actionTypes/stylesActionTypes';
import { colors as dummyColors } from './epicsData';

export const getColors = actions$ => {
  return actions$.pipe(
    ofType(StylesActionTypes.GET_STYLES),
    delay(2000),
    mapTo(getColorsSuccessful(dummyColors)),
    catchError((error, caught) => merge(of(getColorsFailed(error)), caught))
  );
};
