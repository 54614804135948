import React from 'react';

import './shopColorFilterContent.scss';

class ShopColorFilterContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderFilterOptions(filterData, selectedFilterData) {
    return (
      filterData.options &&
      filterData.options.map((option, index) => {
        let optionJSX;

        const isActive =
          selectedFilterData.selectedOptions &&
          selectedFilterData.selectedOptions.includes(option.id);

        const colorValueLowerCase = option.value.toLowerCase();
        let colorBtnContentBorderColor;
        if (
          colorValueLowerCase === '#fff' ||
          colorValueLowerCase === '#ffffff'
        ) {
          colorBtnContentBorderColor = '#d7d7d7';
        } else {
          colorBtnContentBorderColor = colorValueLowerCase;
        }

        const activeColorBorder = '#000';

        colorBtnContentBorderColor = isActive
          ? activeColorBorder
          : colorBtnContentBorderColor;
        const colorBtnContentBgColor = isActive ? '' : option.value;

        optionJSX = (
          <div
            key={index}
            className={`ikt-ws_shop-color-filter-item-content${
              isActive ? ` ikt-ws_is-active` : ``
            }`}
            onClick={this.onOptionsClick(option.id)}
          >
            <div
              className="ikt-ws_shop-color-filter-color-btn-outer"
              style={{
                backgroundColor: colorBtnContentBgColor,
                borderColor: colorBtnContentBorderColor
              }}
            >
              <div
                className="ikt-ws_shop-color-filter-color-btn-inner"
                style={{ backgroundColor: option.value }}
              ></div>
            </div>
          </div>
        );

        return optionJSX;
      })
    );
  }

  onAllOptionClick = () => {
    return () => {
      const { selectAllFilterOptionAndFilterProducts, filterData } = this.props;
      selectAllFilterOptionAndFilterProducts(filterData.id);
    };
  };

  onOptionsClick = optionId => {
    return () => {
      const {
        toggleSelectedFilterOptionsAndFilterProducts,
        filterData
      } = this.props;
      toggleSelectedFilterOptionsAndFilterProducts({
        filterId: filterData.id,
        optionId
      });
    };
  };

  render() {
    const {
      filterData,
      selectedFilterData,
      toggleSelectedFilterOptions
    } = this.props;

    const isAllOptionActive =
      selectedFilterData.selectedOptions &&
      selectedFilterData.selectedOptions.length === 0;

    return (
      <div className="ikt-ws_shop-filter-color-content">
        <div
          className={`ikt-ws_shop-filter-all-item${
            isAllOptionActive ? ` ikt-ws_is-active` : ``
          }`}
        >
          <div className="ikt-ws_shop-filter-all-item-checkmark" />
          <div
            className="ikt-ws_shop-filter-all-item-text"
            onClick={this.onAllOptionClick()}
          >
            All
          </div>
        </div>
        {
          <div className="ikt-ws_shop-color-filter-colors-wrapper">
            {this.renderFilterOptions(
              filterData,
              selectedFilterData,
              toggleSelectedFilterOptions
            )}
          </div>
        }
      </div>
    );
  }
}

export default ShopColorFilterContent;
