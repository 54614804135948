import React from 'react';

import './inputImage.scss';

/**
 * Usage in components:
 *
 * <InputImage className="some-class" />
 */

const InputImage = React.forwardRef((props, ref) => {
  const { className, error, errorMessage, image, ...rest } = props;
  let containerClassName = `ikt-ws_input-image-container${
    className ? ` ${className}` : ``
  }`;
  containerClassName = `${containerClassName}${
    errorMessage || error ? ` error` : ``
  }`;

  return (
    <div className={containerClassName}>
      <div
        className="ikt-ws_input-image-image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <input {...rest} className="ikt-ws_input-image" ref={ref} />
    </div>
  );
});

export default InputImage;
