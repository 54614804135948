import React, { Component } from 'react';
import CheckboxManufacturer from './CheckboxManufacturer/CheckboxManufacturer';
import { renderManufacturerDate } from '../../../../../helpers/renderDate';
import { renderPrice } from '../../../../../helpers/priceHelpers';
import {
  manufacturerTableColumnNames,
  manufacturerTableColumnTypes,
  manufacturerTableOrderTypes,
  manufacturerTableColumns
} from '../../../../../constants/constants';
import './manufacturerItem.scss';

class ManufacturerItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeManufacturerIndex: 0
    };
  }

  onCheckboxClick = () => {
    const { onClick, manufacturer } = this.props;

    if (onClick) {
      onClick(manufacturer.id);
    }
  };

  calcDeliveryDate() {
    const { manufacturer } = this.props;
    return renderManufacturerDate(manufacturer.deliveryDate);
  }

  render() {
    const { manufacturer, isActive, selectedColumn } = this.props;

    return (
      manufacturer && (
        <div
          className={`ikt-ws_manufacturer-list-item-container${
            isActive ? ` active` : ``
          }`}
        >
          <div
            className={`ikt-ws_manufacturer-list-item-left-part
                    ${
                      selectedColumn.columnName ===
                      manufacturerTableColumnNames.MANUFACTURER
                        ? ` active`
                        : ``
                    }`}
          >
            <div className="ikt-ws_manufacturer-list-item-image-container">
              <div
                className="ikt-ws_manufacturer-list-item-image"
                style={{ backgroundImage: `url(${manufacturer.image})` }}
              ></div>
            </div>
            <div className="ikt-ws_manufacturer-list-item-title-n-city">
              <div className="ikt-ws_manufacturer-list-item-title">
                {manufacturer.title}
              </div>
              <div className="ikt-ws_manufacturer-list-item-city">
                {manufacturer.city}
              </div>
            </div>
          </div>
          <div
            className={`ikt-ws_manufacturer-list-item-delivery-date
                    ${
                      selectedColumn.columnName ===
                      manufacturerTableColumnNames.DELIVERY_DATE
                        ? ` active`
                        : ``
                    }`}
          >
            {this.calcDeliveryDate()}
          </div>
          <div
            className={`ikt-ws_manufacturer-list-item-delivery-price
                    ${
                      selectedColumn.columnName ===
                      manufacturerTableColumnNames.DELIVERY_PRICE
                        ? ` active`
                        : ``
                    }`}
          >
            {renderPrice(manufacturer.deliveryPrice)}
          </div>
          <div
            className={`ikt-ws_manufacturer-list-item-cabinet-price
                    ${
                      selectedColumn.columnName ===
                      manufacturerTableColumnNames.CABINET_PRICE
                        ? ` active`
                        : ``
                    }`}
          >
            {renderPrice(manufacturer.cabinetPrice)}
          </div>
          <div
            className={`ikt-ws_manufacturer-list-item-total-price
                    ${
                      selectedColumn.columnName ===
                      manufacturerTableColumnNames.TOTAL_PRICE
                        ? ` active`
                        : ``
                    }`}
          >
            {renderPrice(
              manufacturer.deliveryPrice + manufacturer.cabinetPrice
            )}
          </div>
          <CheckboxManufacturer
            isActive={isActive}
            onClick={this.onCheckboxClick}
          />
        </div>
      )
    );
  }
}

export default ManufacturerItem;
