import React from 'react';
import {connect} from 'react-redux';

import AddressCard from '../common/AddressCard';
import CartItem from '../cart/CartItem';
import DropdownCustom from '../common/DropdownCustom/DropdownCustom';
import PaymentMethodCard from '../common/PaymentMethodCard';

const items = [
    {
        itemsTitle: 'Prebuilt items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            },
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            }
        ]
    },
    {
        itemsTitle: 'Custom items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    }
                ]
            }
        ]
    }
];

class ConfirmationPage extends React.Component {
    render () {
        const {cartItems, totalPrice} = this.props;
        return (
            <div className="rows cart-content">
                <div className="row">
                    <CartItem
                        isOrderPage={true}
                        item={items[0]}
                        products={cartItems}
                        totalPrice={totalPrice}
                    />
                </div>
                <div className="row">
                    <AddressCard/>
                </div>
                <div className="row">
                    <PaymentMethodCard />
                </div>
            </div>
        )
    }
}

export default ConfirmationPage;
