import React from 'react';

const LinkedinIcon = (props) => {
    const { color = '#007AB9', ...rest} = props;

    return (
        <svg {...rest}
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
            width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Other/Login/likedin</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1877.000000, -1143.000000)" fillRule="nonzero">
                    <g id="Other/Login/likedin" transform="translate(1877.000000, 1143.000000)">
                        <circle id="Oval" fill={color} cx="20" cy="20" r="20"></circle>
                        <path d="M32,21.4871006 L32,30 L27.2836542,30 L27.2836542,22.0576209 C27.2836542,20.0633763
                        26.6027051,18.701489 24.8950566,18.701489 C23.5918974,18.701489
                        22.81774,19.6183704 22.4758585,20.5061737 C22.3516979,20.8234566
                        22.3196905,21.2640455 22.3196905,21.7090514 L22.3196905,29.9996319 L17.6029929,29.9996319 C17.6029929,29.9996319
                        17.6663043,16.5478668 17.6029929,15.1554291 L22.3200422,15.1554291 L22.3200422,17.2589928 C22.3105455,17.2755563
                        22.2971798,17.2917517 22.2887382,17.3075791 L22.3200422,17.3075791 L22.3200422,17.2589928 C22.9468248,16.2497239
                        24.0646224,14.8068596 26.5706977,14.8068596 C29.6736586,14.8068596
                        32,16.9284591 32,21.4871006 L32,21.4871006 Z M12.6689263,8 C11.0555413,8 10,9.10828175
                        10,10.5643969 C10,11.9895934 11.0249408,13.1298979 12.6070218,13.1298979 L12.6376223,13.1298979 C14.2826629,13.1298979
                        15.3054934,11.9895934 15.3054934,10.5643969 C15.2741894,9.10828175 14.2826629,8
                        12.6689263,8 L12.6689263,8 Z M10.2803287,30 L14.9952676,30 L14.9952676,15.1554291 L10.2803287,15.1554291 L10.2803287,30 Z"
                              id="Shape" fill="#F1F2F2"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default LinkedinIcon;
