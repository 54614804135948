import * as CartActionTypes from '../actionTypes/cartActionTypes';
import {loadState} from '../../helpers/cartDataPersistance';

const storedProducts = loadState('cart') && loadState('cart').cart ? loadState('cart').cart.products : []
const initState = {
  products: storedProducts
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case CartActionTypes.ADD_PRODUCT:
      const newProduct = action.payload;
      let prevProducts = state.products;
      let prevProductIndex;
      const existingProduct = prevProducts.find((currProduct, index) => {
        if (
          currProduct.id === newProduct.id &&
          currProduct.sizeId === newProduct.sizeId &&
          currProduct.colorId === newProduct.colorId
        ) {
          prevProductIndex = index;
          return true;
        }

        return false;
      });

      if (existingProduct) {
        prevProducts[prevProductIndex].amount = newProduct.amount;
      } else {
        prevProducts.push(newProduct);
      }

      return {
        ...state,
        products: [...prevProducts]
      };
    case CartActionTypes.REMOVE_PRODUCT:
      const removedProductId = action.payload;
      let products = state.products;
      const filteredProducts = products.filter(
        item => item.id !== removedProductId
      );
      return {
        ...state,
        products: filteredProducts
      };
    case CartActionTypes.REMOVE_PRODUCTS:
      return {
        ...state,
        products: []
      };

    default:
      return state;
  }
};

export default cartReducer;
