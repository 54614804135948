import React from 'react';

const FacebookIcon = (props) => {
    const { color = '#3B5998', ...rest } = props;

    return (
        <svg {...rest}
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
            width="30px" height="29px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Other/Footer/facebook</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1713.000000, -1091.000000)" fillRule="nonzero">
                    <g id="Other/Footer/facebook" transform="translate(1713.000000, 1091.000000)">
                        <ellipse id="Oval" fill={color} cx="15" cy="14.5" rx="15" ry="14.5"></ellipse>
                        <path d="M18.6375785,14.4817016 L15.968087,14.4817016 L15.968087,24 L11.9235807,24
                        L11.9235807,14.4817016 L10,14.4817016 L10,11.1365928 L11.9235807,11.1365928
                        L11.9235807,8.97192738 C11.9235807,7.42395803 12.6790921,5 16.0040891,5 L19,5.01219895
                        L19,8.25919703 L16.8262712,8.25919703 C16.4697167,8.25919703 15.9683537,8.4325779
                        15.9683537,9.17100392 L15.9683537,11.1397074 L18.9909328,11.1397074 L18.6375785,14.4817016 Z"
                              id="Path" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default FacebookIcon;
