import * as MaterialsActionTypes from '../actionTypes/materialsActionTypes';
import { handlesMaterials } from '../../epics/epicsData';

const initState = {
  materials: [],
  handlesMaterials: [],
  materialsError: null,
  materialsBusy: false
};

const materialsReducer = (state = initState, action) => {
  switch (action.type) {
    case MaterialsActionTypes.GET_MATERIALS:
      return {
        ...state,
        materialsBusy: true
      };
    case MaterialsActionTypes.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: action.payload.skeletonMaterials,
        handlesMaterials: action.payload.handlesMaterials,
        materialsError: null,
        materialsBusy: false
      };
    case MaterialsActionTypes.GET_MATERIALS_ERROR:
      return {
        ...state,
        materialsError: action.payload.message,
        materialsBusy: false
      };
    default:
      return state;
  }
};

export default materialsReducer;
