import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import FooterSignUp from "../common/FooterSignUp";

import "./deliveryTermsPage.scss";

class DeliveryTermsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <div className="ikt-ws_legal-page-container">
        <>
          <div className="ikt-ws_legal-page-content">
            <div className="ikt-ws_legal-page-title">{ t('delivery_terms_page_title') }</div>
            <div className="ikt-ws_legal-page-description">
              <p className="date">July 2020</p>

              <div className="legal-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>

              <p className="legal-title">1.Lorem ipsum</p>

              <div className="legal-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>

              <ul>
                <li>
                  <span className="highlighted">Etiam non</span> quam lacus
                  suspendisse faucibus. Mattis enim ut tellus elementum.
                  Pharetra convallis posuere morbi urna. Sed enim ut sem viverra
                  aliquet eget. Duis ultricies lacus sed turpis tincidunt. Lacus
                  vestibulum sed arcu non. Sit amet cursus sit amet dictum sit
                  amet justo donec.
                </li>
                <li>
                  <span className="highlighted">Malesuada nunc</span> vel risus
                  commodo. Hac habitasse platea dictumst quisque sagittis purus.
                  Magnis dis parturient montes nascetur ridiculus mus mauris
                  vitae. At urna condimentum mattis pellentesque. Nisl vel
                  pretium lectus quam. Purus ut faucibus pulvinar elementum. A
                  arcu cursus vitae congue mauris rhoncus aenean vel. Viverra
                  tellus in hac habitasse platea.
                </li>
                <li>
                  <span className="highlighted">Consectetur lorem </span>
                  Consectetur lorem donec massa sapien faucibus et. Vitae tortor
                  condimentum lacinia quis vel eros donec. Dictum fusce ut
                  placerat orci nulla pellentesque dignissim enim.
                </li>
              </ul>
              <a className="terms-link">www.regiom.com</a>
              <br />
              <a className="terms-link">www.regiom.com</a>
            </div>
          </div>
          <FooterSignUp />
        </>
      </div>
    );
  }
}

export default (withTranslation()(DeliveryTermsPage));
