import React from 'react';

import DropdownIcon from '../../../assets/images/components/DropdownIcon';
import './dropdown.scss';

/**
 * Usage in components:
 *
 * <Dropdown className="some-class"
 *     items={['test1', 'test2', 'test3']}
 *     initialItemIndex={1}
 *     onClick={(index) => { console.log("Item with index selected: ", index); }}>
 * </Dropdown>
 */

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      isShowPlaceholder: true,
      currentItemIndex: props.initialItemIndex || 0
    };

    this.dropdownTriggerRef = React.createRef();
  }

  dropdownToggle = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({
        isActive: !this.state.isActive
      });
    }
  };

  hideDropdown = event => {
    const currTriggerBtn = event.target.closest('.ikt-ws_dropdown-trigger');

    if (!currTriggerBtn || currTriggerBtn !== this.dropdownTriggerRef.current) {
      this.setState({
        isActive: false
      });
    }
  };

  onItemClick = item => {
    return () => {
      const { disabled, onClick, items } = this.props;

      if (!disabled) {
        this.setState({
          currentItemIndex: items.indexOf(item),
          isShowPlaceholder: false
        });
        onClick(item.id);
      }
    };
  };

  componentDidMount() {
    document.body.addEventListener('click', this.hideDropdown, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.hideDropdown);
  }

  renderDropdownButtonText = () => {
    const { currentItemIndex, isShowPlaceholder } = this.state;
    const { items, placeholder } = this.props;
    let buttonText = '';
    if (placeholder && isShowPlaceholder) {
      buttonText = placeholder;
    } else if (items && items[currentItemIndex].value) {
      buttonText = items[currentItemIndex].value;
    }

    return buttonText;
  };

  render() {
    const { isActive, currentItemIndex } = this.state;
    const { items, disabled, placeholder, error } = this.props;

    let color = '#000';
    if (disabled) {
      color = 'rgba(0, 0, 0, 0.5)';
    }

    let className = `ikt-ws_dropdown${isActive ? ` ikt-ws_is-active ` : ``}${
      disabled ? ` disabled` : ``
    }`;
    className = `${className}${
      this.props.className ? ` ${this.props.className}` : ``
    }`;
    className = `${className}${error ? ` error` : ``}`;

    return (
      <div className={className}>
        <div className="ikt-ws_dropdown-trigger" ref={this.dropdownTriggerRef}>
          <button
            className="ikt-ws_dropdown-button"
            onClick={this.dropdownToggle}
          >
            <span className="ikt-ws_dropdown-button-text">
              {this.renderDropdownButtonText()}
            </span>
            <DropdownIcon className="ikt-ws_dropdown-chevron" color={color} />
          </button>
        </div>
        <div className="ikt-ws_dropdown-menu">
          <div className="ikt-ws_dropdown-content">
            {items &&
              items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ikt-ws_dropdown-item"
                    onClick={this.onItemClick(item)}
                  >
                    {item.value}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
