import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import FooterSignUp from "../common/FooterSignUp";
import { mapLocation } from "../../constants/constants";
import phone from "../../assets/images/contacts/phone.svg";
import location from "../../assets/images/contacts/location.svg";
import web from "../../assets/images/contacts/web.svg";
import workinghours from "../../assets/images/contacts/workinghours.svg";
import facebook from "../../assets/images/contacts/facebook.svg";
import instagram from "../../assets/images/contacts/instagram.svg";
import pinterest from "../../assets/images/contacts/pinterest.svg";
import twitter from "../../assets/images/contacts/twitter.svg";
import Map from "./Map";
import "./contactsPage.scss";

class ContactsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="ikt-ws_contacts-page-container">
        <>
          <div className="ikt-ws_contacts-page-top">
            <div className="ikt-ws_contacts-page-title">{t("contacts_page_title")}</div>
            <div className="ikt-ws_contacts-page-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
          <div className="ikt-ws_contacts-page-location">
            <div className="contacts-details">
              <div className="details">
                <img src={phone} alt="phone-img" />
                <div className="details-content">
                  <span>{t("call_us_today")}</span>
                  <span>+49 (0) 8025/995969</span>
                </div>
              </div>

              <div className="details">
                <img src={workinghours} alt="phone-img" />
                <div className="details-content">
                  <span>Mon - Thurs: 8am - 7pm ET</span>
                  <span>Fri: 8am - 5pm ET</span>
                </div>
              </div>

              <div className="details">
                <img src={location} alt="location-pin-img" />
                <div className="details-content">
                  <span>Wittenauer Str. 88-100, 13435 Berlin,</span>
                  <span>Germany</span>
                </div>
              </div>

              <div className="details web">
                <img src={web} alt="web-img" />
                <div className="details-content">
                  <a className="social-media">
                    <img src={facebook} alt="facebook-img" /> Facebook
                  </a>

                  <a className="social-media">
                    <img src={twitter} alt="twitter-img" /> Twitter
                  </a>

                  <a className="social-media">
                    <img src={pinterest} alt="pinterest-img" /> Pinterest
                  </a>

                  <a className="social-media">
                    <img src={instagram} alt="phone-img" /> Instagram
                  </a>
                </div>
              </div>
            </div>
            <div className="contacts-map">
              <Map
                location={mapLocation.location}
                zoomLevel={mapLocation.zoom}
              />
            </div>
          </div>
          <FooterSignUp />
        </>
      </div>
    );
  }
}

export default (withTranslation()(ContactsPage));
