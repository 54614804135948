import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './dropdownUserAccount.scss';

const userAccountDropdownOptions = [
  {
    text: 'My Cabinets',
    to: '/cabinets'
  },
  {
    text: 'Orders',
    to: '/orders'
  },
  {
    text: 'Account'
  }
];

class DropdownUserAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };
  }

  dropdownToggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  hideDropdown = event => {
    const isFromTriggerBtn = event.target.closest(
      '.ikt-ws_user-account-dropdown-trigger'
    );

    if (!isFromTriggerBtn) {
      this.setState({
        isActive: false
      });
    }
  };

  onLogoutClick = () => {
    const { logOut, history } = this.props;
    logOut();
    history.push('/');
  };

  componentDidMount() {
    document.body.addEventListener('click', this.hideDropdown, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.hideDropdown);
  }

  render() {
    const { isActive } = this.state;
    const { image } = this.props;

    let className = `ikt-ws_user-account-dropdown${
      isActive ? ` ikt-ws_is-active ` : ``
    }`;
    className = `${className}${
      this.props.className ? ` ${this.props.className}` : ``
    }`;

    return (
      <div className={className}>
        <div className="ikt-ws_user-account-dropdown-trigger">
          <button
            className="ikt-ws_user-account-dropdown-button"
            onClick={this.dropdownToggle}
            style={{ backgroundImage: `url(${image})` }}
          ></button>
        </div>
        <div className="ikt-ws_user-account-dropdown-menu">
          <div className="ikt-ws_user-account-dropdown-content">
            {userAccountDropdownOptions.map((item, index) => {
              return !!item.to ? (
                <Link key={index} to={item.to}>
                  <div className="ikt-ws_user-account-dropdown-item">
                    {item.text}
                  </div>
                </Link>
              ) : (
                <div key={index} className="ikt-ws_user-account-dropdown-item">
                  {item.text}
                </div>
              );
            })}
            <div
              className="ikt-ws_user-account-dropdown-item"
              onClick={this.onLogoutClick}
            >
              Log Out
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DropdownUserAccount);
