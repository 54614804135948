import React, { Component } from 'react';
import { toast } from 'react-toastify';
import RegistrationArrow from '../../../assets/images/components/RegistrationArrow';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import CheckboxTeal from '../../common/CheckboxTeal/CheckboxTeal';
import Input from '../../common/Input/Input';
import Dropdown from '../../common/Dropdown/Dropdown';
import { Link, withRouter } from 'react-router-dom';

import './registration.scss';

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.registrationWrapperRef = React.createRef();

    this.state = {
      isMembershipCheckboxChecked: false,
      backToLoginBtnHover: false
    };

    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
    this.surnameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.repeatPasswordRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    const { isMembershipCheckboxChecked } = this.state;
    if (!prevProps.shown && this.props.shown) {
      this.registrationWrapperRef.current &&
        this.registrationWrapperRef.current.scrollTo(0, 0);
    }

    if (
      !auth.isLoginPageShown &&
      !auth.isRegistrationPageShown &&
      !auth.isResetPasswordPageShown
    ) {
      this.emailRef.current.value = '';
      this.nameRef.current.value = '';
      this.surnameRef.current.value = '';
      this.passwordRef.current.value = '';
      this.repeatPasswordRef.current.value = '';

      if (isMembershipCheckboxChecked) {
        this.setState({
          isMembershipCheckboxChecked: false
        });
      }
    }
  }

  toggleBackToLoginBtnHover = () => {
    this.setState({
      backToLoginBtnHover: !this.state.backToLoginBtnHover
    });
  };

  onMembershipCheckboxClick = isChecked => {
    this.setState({
      isMembershipCheckboxChecked: isChecked
    });
  };

  goToLoginPage = () => {
    this.props.showLoginPage();
  };

  onRegisterBtnClick = () => {
    const { register, setRegistrationErrors } = this.props;
    const { isMembershipCheckboxChecked } = this.state;

    if (isMembershipCheckboxChecked) {
      const email = this.emailRef.current.value;
      const name = this.nameRef.current.value;
      const surname = this.surnameRef.current.value;
      const password = this.passwordRef.current.value;
      const repeatPassword = this.repeatPasswordRef.current.value;
      const fullName = `${name} ${surname}`;

      let errors = {};

      if (email.length === 0) {
        const errorMessage = `Please fill in email field`;
        toast.error(errorMessage);
        errors.email = errorMessage;
      }

      if (name.length === 0) {
        const errorMessage = `Please fill in name field`;
        toast.error(errorMessage);
        errors.name = errorMessage;
      }

      if (surname.length === 0) {
        const errorMessage = `Please fill in surname field`;
        toast.error(errorMessage);
        errors.surname = errorMessage;
      }

      if (password.length === 0) {
        const errorMessage = `Please fill in password field`;
        toast.error(errorMessage);
        errors.password = errorMessage;
      }

      if (repeatPassword.length === 0) {
        const errorMessage = `Please fill in repeat password field`;
        toast.error(errorMessage);
        errors.repeatPassword = errorMessage;
      }

      if (
        password.length !== 0 &&
        repeatPassword.length !== 0 &&
        password !== repeatPassword
      ) {
        const errorMessage = `Password and repeat password fields content must be equal`;
        toast.error(errorMessage);
        errors.password = errorMessage;
        errors.repeatPassword = errorMessage;
      }

      setRegistrationErrors(errors);

      if (Object.keys(errors).length === 0) {
        register({ name: fullName, email, password });
      }
    }
  };

  onInputEmailChange = event => {
    const { resetRegistrationErrors } = this.props;
    resetRegistrationErrors(['email']);
  };

  onInputNameChange = event => {
    const { resetRegistrationErrors } = this.props;
    resetRegistrationErrors(['name']);
  };

  onInputSurnameChange = event => {
    const { resetRegistrationErrors } = this.props;
    resetRegistrationErrors(['surname']);
  };

  onInputPasswordChange = event => {
    const { resetRegistrationErrors, auth } = this.props;
    const password = this.passwordRef.current.value;
    const repeatPassword = this.repeatPasswordRef.current.value;

    if (
      password.length !== 0 &&
      repeatPassword.length !== 0 &&
      password === repeatPassword &&
      auth.registrationErrors.repeatPassword ===
        `Password and repeat password fields content must be equal`
    ) {
      resetRegistrationErrors(['repeatPassword']);
    }

    resetRegistrationErrors(['password']);
  };

  onInputRepeatPasswordChange = event => {
    const { resetRegistrationErrors, auth } = this.props;
    const password = this.passwordRef.current.value;
    const repeatPassword = this.repeatPasswordRef.current.value;

    if (
      password.length !== 0 &&
      repeatPassword.length !== 0 &&
      password === repeatPassword &&
      auth.registrationErrors.password ===
        `Password and repeat password fields content must be equal`
    ) {
      resetRegistrationErrors(['password']);
    }

    resetRegistrationErrors(['repeatPassword']);
  };

  onInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.onRegisterBtnClick();
    }
  };

  render() {
    const { isMembershipCheckboxChecked, backToLoginBtnHover } = this.state;
    const { className, auth } = this.props;

    const isAddLink = this.props.location.pathname === '/register';

    return (
      <div
        className={`ikt-ws_registration-container${
          className ? ` ${className}` : ``
        } `}
      >
        <div
          className="ikt-ws_registration-wrapper"
          ref={this.registrationWrapperRef}
        >
          <div className="ikt-ws_registration-text">Registration</div>
          <Input
            placeholder="Email..."
            className="ikt-ws_registration-email"
            error={!!auth.registrationErrors.email}
            onChange={this.onInputEmailChange}
            onKeyDown={this.onInputKeyDown}
            ref={this.emailRef}
          />
          <Dropdown placeholder="Title" className="ikt-ws_registration-title" />
          <Input
            placeholder="Name"
            className="ikt-ws_registration-name"
            error={!!auth.registrationErrors.name}
            onChange={this.onInputNameChange}
            onKeyDown={this.onInputKeyDown}
            ref={this.nameRef}
          />
          <Input
            placeholder="Surname"
            className="ikt-ws_registration-surname"
            error={!!auth.registrationErrors.surname}
            onChange={this.onInputSurnameChange}
            onKeyDown={this.onInputKeyDown}
            ref={this.surnameRef}
          />
          <Input
            placeholder="Password..."
            type="password"
            className="ikt-ws_registration-password"
            error={!!auth.registrationErrors.password}
            onChange={this.onInputPasswordChange}
            onKeyDown={this.onInputKeyDown}
            ref={this.passwordRef}
          />
          <Input
            placeholder="Repeat Password..."
            type="password"
            className="ikt-ws_registration-repeat-password"
            error={!!auth.registrationErrors.repeatPassword}
            onChange={this.onInputRepeatPasswordChange}
            onKeyDown={this.onInputKeyDown}
            ref={this.repeatPasswordRef}
          />
          <div className="ikt-ws_registration-agree-membership">
            <CheckboxTeal
              className="ikt-ws_registration-agree-membership-checkbox"
              isChecked={isMembershipCheckboxChecked}
              onClick={this.onMembershipCheckboxClick}
            />
            <div className="ikt-ws_registration-agree-membership-text">
              I agree to the Regiomebel Membership
              <span className="ikt-ws_registration-agree-membership-link">
                Terms and conditions
              </span>
              .
            </div>
          </div>
          <ButtonTeal
            className="ikt-ws_registration-register-btn"
            onClick={this.onRegisterBtnClick}
            disabled={!isMembershipCheckboxChecked}
          >
            REGISTER
          </ButtonTeal>
          <ButtonLinkEmpty
            className="ikt-ws_registration-back-to-login"
            onMouseEnter={this.toggleBackToLoginBtnHover}
            onMouseLeave={this.toggleBackToLoginBtnHover}
            onClick={this.goToLoginPage}
            to={isAddLink ? `/login` : null}
          >
            <RegistrationArrow
              color={backToLoginBtnHover ? `#1da6b2` : `#1899A5`}
            />
            <div className="ikt-ws_registration-back-to-login-text">
              Back to Login
            </div>
          </ButtonLinkEmpty>
        </div>
      </div>
    );
  }
}

export default withRouter(Registration);
