import React from 'react';
import {connect} from 'react-redux';

import CheckBoxTeal from '../common/CheckboxTeal/CheckboxTeal';
import Dropdown from '../common/Dropdown/Dropdown';
import Input from '../common/Input/Input';

const items = [
    {
        itemsTitle: 'Prebuilt items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            },
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            }
        ]
    },
    {
        itemsTitle: 'Custom items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    }
                ]
            }
        ]
    }
];

class Address extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            address1: '',
            address2: '',
            postalCode: '',
            city: '',
            country: 'Germany',
            name: '',
            mobileNumber: ''
        };
    }

    getCitiesNames = () => {
        const {cities} = this.props;
        const citiesNames = [];
        cities.map((item, index) => citiesNames.push({id: index, value: item.city}));
        return citiesNames;
    };

    handleInputChange = (name, e) => {
        const {onChange} = this.props;
        const value = e.target.value;
        onChange(name, value);
    };

    handleCityChange = (id) => {
        const {onChange} = this.props;
        const cities = this.getCitiesNames();
        const city = cities.filter(item => item.id === id);
        onChange('city', city[0].value);
        this.setState({
            city: city[0].value
        })
    };

    render () {
        const {errors} = this.props;
        const {address1, address2, postalCode, name, mobileNumber} = this.state;
        const cities = this.getCitiesNames();
        return (
            <>
                <div className="row order-card" />
                <div className="row address-card">
                    <div className="column is-3">
                        <div className="address-card-title">New Address</div>
                        <Input
                            className="address-card-input"
                            onChange={e => this.handleInputChange('address1', e)}
                            placeholder={"Address"}
                            defaultValue={address1}
                            error={errors.address1}
                        />
                        <div className="address-card-title">New Address</div>
                        <Input
                            className="address-card-input"
                            onChange={e => this.handleInputChange('address2', e)}
                            placeholder={"Address"}
                            defaultValue={address2}
                            error={errors.address2}
                        />
                        <Input
                            className="address-card-input"
                            onChange={e => this.handleInputChange('postalCode', e)}
                            placeholder={"Postal Code"}
                            defaultValue={postalCode}
                            error={errors.postalCode}
                        />
                        <Dropdown
                            className="address-card-dropdown"
                            error={errors.city}
                            onClick={this.handleCityChange}
                            placeholder={'City'}
                            items={cities}
                        />
                        <Dropdown
                            className="address-card-dropdown"
                            error={errors.country}
                            placeholder={'Country'}
                            items={[{id: 1, value:'Germany'}]}
                            initialItemIndex={0}
                        />
                        <Input
                            className="address-card-input"
                            onChange={e => this.handleInputChange('name', e)}
                            placeholder={"Full Name"}
                            defaultValue={name}
                            error={errors.name}
                        />
                        <Input
                            className="address-card-input"
                            onChange={e => this.handleInputChange('mobileNumber', e)}
                            placeholder={"Mobile"}
                            defaultValue={mobileNumber}
                            error={errors.mobileNumber}
                        />
                    </div>
                    <div className="column billing-address">
                        <div className="address-card-title">Billing Address</div>
                        <div className="billing-checkbox">
                            <CheckBoxTeal />
                            <span>Billing Address same as Delivery Address</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        cities: state.cities.cities,
    };
};

export default connect(mapStateToProp, null)(Address);
