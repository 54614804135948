import React from 'react';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import ButtonBlue from '../../common/ButtonBlue/ButtonBlue';

class TopSlider extends React.Component {
    render () {
        const { t } = this.props;
        const sliderSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        let buttonClassName = '';
        if (i18n.language == 'de') {
            buttonClassName = 'button-blue-bigger-width';
        }
        return (
            <div className="column is-6 is-offset-3 top-slider">
                <Slider {...sliderSettings}>
                    <div className="top-slider-item">
                        <div className="row slider-item-title">{ t('plan_individual_furniture_title') }</div>
                        <div className="row slider-item-text">{ t('plan_individual_furniture_body') }</div>
                        <div className="row slider-item-btn"><ButtonBlue className={buttonClassName}>{ t('make_your_own_cabient').toUpperCase() }</ButtonBlue></div>
                    </div>
                    <div className="top-slider-item">
                        <div className="row slider-item-title">{ t('plan_individual_furniture_title') }</div>
                        <div className="row slider-item-text">{ t('plan_individual_furniture_body') }</div>
                        <div className="row slider-item-btn"><ButtonBlue className={buttonClassName}>{ t('make_your_own_cabient').toUpperCase() }</ButtonBlue></div>
                    </div>
                    <div className="top-slider-item">
                        <div className="row slider-item-title">{ t('plan_individual_furniture_title') }</div>
                        <div className="row slider-item-text">{ t('plan_individual_furniture_body') }</div>
                        <div className="row slider-item-btn"><ButtonBlue className={buttonClassName}>{ t('make_your_own_cabient').toUpperCase() }</ButtonBlue></div>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default withTranslation()(TopSlider);

