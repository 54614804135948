import * as StylesActionTypes from '../actionTypes/stylesActionTypes';

export const getStyles = () => {
  return { type: StylesActionTypes.GET_STYLES };
};

export const getStylesSuccessful = materials => {
  return { type: StylesActionTypes.GET_STYLES_SUCCESS, payload: materials };
};

export const getStylesFailed = error => {
  return { type: StylesActionTypes.GET_STYLES_ERROR, payload: error };
};
