import * as CartActionTypes from '../actionTypes/cartActionTypes';

export const addProduct = product => {
  return { type: CartActionTypes.ADD_PRODUCT, payload: product };
};

export const removeProduct = product => {
  return { type: CartActionTypes.REMOVE_PRODUCT, payload: product };
};

export const removeProducts = () => {
  return { type: CartActionTypes.REMOVE_PRODUCTS };
};
