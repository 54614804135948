import * as DepartmentsActionTypes from '../actionTypes/departmentsActionTypes';

export const getDepartments = () => {
  return { type: DepartmentsActionTypes.GET_DEPARTMENTS };
};

export const getDepartmentsSuccessful = departments => {
  return {
    type: DepartmentsActionTypes.GET_DEPARTMENTS_SUCCESS,
    payload: departments
  };
};

export const getDepartmentsFailed = error => {
  return { type: DepartmentsActionTypes.GET_DEPARTMENTS_ERROR, payload: error };
};
