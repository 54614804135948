import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getStylesSuccessful,
  getStylesFailed
} from '../store/actions/stylesActions';
import * as StylesActionTypes from '../store/actionTypes/stylesActionTypes';
import { styles as dummyStyles } from './epicsData';

export const getStyles = actions$ => {
  return actions$.pipe(
    ofType(StylesActionTypes.GET_STYLES),
    delay(2000),
    mapTo(getStylesSuccessful(dummyStyles)),
    catchError((error, caught) => merge(of(getStylesFailed(error)), caught))
  );
};
