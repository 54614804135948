import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  orderFilterOptionIds,
  orderFilterOptions
} from '../../constants/constants';
import CabinetItem from './CabinetItem/CabinetItem';
import InputImage from '../common/InputImage/InputImage';
import SearchImage from '../../assets/images/inputIcons/search.svg';
import Dropdown from '../common/Dropdown/Dropdown';
import { deepClone } from '../../helpers/deepCloneHelper';
import { addProduct } from '../../store/actions/cartActions';
import Loader from '../common/Loader/Loader';
import './myCabinetsPage.scss';

class MyCabinetsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOrderFilterId: orderFilterOptionIds.RECENT_AT_TOP,
      searchInputVal: ''
    };

    if (!props.isAuthorized) {
      props.history.push('/');
    }
  }

  onFilterOrderDropdownClick = id => {
    this.setState({
      selectedOrderFilterId: id
    });
  };

  onSearchInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.setState({
        searchInputVal: event.target.value
      });
    }
  };

  onSearchInputChange = event => {
    const value = event.target.value;
    if (value === '') {
      this.setState({
        searchInputVal: event.target.value
      });
    }
  };

  renderCainets() {
    const { customCabinets, materials, sizes, colors } = this.props;
    const { selectedOrderFilterId, searchInputVal } = this.state;

    let customCabinetsCopy = deepClone(customCabinets);

    switch (selectedOrderFilterId) {
      case orderFilterOptionIds.RECENT_AT_TOP:
        customCabinetsCopy.sort(function(cabinetA, cabinetB) {
          const timeCabinetA = new Date(cabinetA.timestampCreated).getTime();
          const timeCabinetB = new Date(cabinetB.timestampCreated).getTime();
          return timeCabinetA - timeCabinetB;
        });
        break;
      case orderFilterOptionIds.LATEST_AT_TOP:
        customCabinetsCopy.sort(function(cabinetA, cabinetB) {
          const timeCabinetA = new Date(cabinetA.timestampCreated).getTime();
          const timeCabinetB = new Date(cabinetB.timestampCreated).getTime();
          return timeCabinetB - timeCabinetA;
        });
        break;
      case orderFilterOptionIds.HIGHEST_PRICE_AT_TOP:
        customCabinetsCopy.sort(function(cabinetA, cabinetB) {
          return cabinetB.price - cabinetA.price;
        });
        break;
      case orderFilterOptionIds.CHEAPEST_AT_TOP:
        customCabinetsCopy.sort(function(cabinetA, cabinetB) {
          return cabinetA.price - cabinetB.price;
        });
        break;
    }

    customCabinetsCopy = customCabinetsCopy.filter(cabinetItem => {
      return cabinetItem.title.match(new RegExp(searchInputVal, 'gi'));
    });

    return (
      customCabinetsCopy &&
      customCabinetsCopy.map((cabinetItem, index) => {
        return (
          <div key={index} className="ikt-ws_my-cabinets-page-cabinet-item">
            <CabinetItem
              cabinetItem={cabinetItem}
              materials={materials}
              sizes={sizes}
              colors={colors}
            />
          </div>
        );
      })
    );
  }

  isAllDataReady() {
    const { customCabinets, materials, sizes, colors } = this.props;
    return (
      customCabinets &&
      Object.keys(customCabinets).length > 0 &&
      materials &&
      materials.length > 0 &&
      sizes &&
      sizes.length > 0 &&
      colors &&
      colors.length > 0
    );
  }

  render() {
    return (
      <div className="ikt-ws_my-cabinets-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_my-cabinets-page-top">
              <div className="ikt-ws_my-cabinets-page-heading">My Cabinets</div>
              <div className="ikt-ws_my-cabinets-page-filters">
                <InputImage
                  className="ikt-ws_my-cabinets-page-name-search"
                  placeholder="Search by name"
                  image={SearchImage}
                  onChange={this.onSearchInputChange}
                  onKeyDown={this.onSearchInputKeyDown}
                ></InputImage>
                <Dropdown
                  className="ikt-ws_my-cabinets-page-items-order-filter"
                  items={orderFilterOptions}
                  currentItemIndex={orderFilterOptionIds.RECENT_AT_TOP}
                  onClick={this.onFilterOrderDropdownClick}
                />
              </div>
            </div>
            <div className="ikt-ws_my-cabinets-page-content">
              {this.renderCainets()}
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    customCabinets: state.userAccount.customCabinets,
    sizes: state.sizes.sizes,
    materials: state.materials.materials,
    colors: state.colors.colors,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProduct: product => {
      dispatch(addProduct(product));
    }
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(MyCabinetsPage));
