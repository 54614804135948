import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, catchError, delay } from 'rxjs/operators';
import {
  getManufacturersSuccessful,
  getManufacturersFailed
} from '../store/actions/manufacturersActions';
import * as ManufacturersActionTypes from '../store/actionTypes/manufacturersActionTypes';
import { manufacturers as dummyManufacturers } from './epicsData';

export const getManufacturers = actions$ => {
  return actions$.pipe(
    ofType(ManufacturersActionTypes.GET_MANUFACTURERS),
    delay(2000),
    mapTo(getManufacturersSuccessful(dummyManufacturers)),
    catchError((error, caught) =>
      merge(of(getManufacturersFailed(error)), caught)
    )
  );
};
