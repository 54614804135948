import React from 'react';

import Menu from './Menu';
import TopBar from './Topbar';

const Header = props => {
  return (
    <header
      className={props.isDarkTheme ? 'header-dark-theme' : 'header-light-theme'}
    >
      <TopBar />
      <Menu
        isDarkTheme={props.isDarkTheme}
        showLoginPage={props.showLoginPage}
        isAuthorized={props.isAuthorized}
        userProfile={props.userProfile}
        logOut={props.logOut}
      />
    </header>
  );
};

export default Header;
