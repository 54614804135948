import React from 'react';

import DropDownImage from '../common/DropdownImage/DropdownImage';
import img from '../../assets/images/background/background1.jpg';

class SettingsCard extends React.Component {
    render () {
        const {title, name} = this.props;
        return (
            <div className="rows settings-card">
                <div className="row setting-card-title">{title}</div>
                <div className="row setting-card-name">{name}</div>
                <div className="row">
                    <DropDownImage
                        items={[{ value: 'Wood', image: img }, { value: 'Wood', image: img }]}
                        showPlaceholder={true}
                    />
                </div>
            </div>
        )
    }
}

export default SettingsCard;
