import * as MakeCustomCabinetActionTypes from '../actionTypes/makeCustomCabinetActionTypes';
import { dummyMakeCustomCabinet } from '../../epics/epicsData';

const initState = {
  cabinet: dummyMakeCustomCabinet || {}
};

const cartReducer = (state = initState, action) => {
  let materialId, cabinet;
  switch (action.type) {
    case MakeCustomCabinetActionTypes.ADD_CABINET:
      cabinet = action.payload;
      return {
        ...state,
        cabinet
      };
    case MakeCustomCabinetActionTypes.SET_SKELETON_MATERIAL:
      materialId = action.payload;
      cabinet = { ...state.cabinet };
      cabinet.skeletonMaterialId = materialId;
      return {
        ...state,
        cabinet
      };
    case MakeCustomCabinetActionTypes.SET_DOORS_MATERIAL:
      materialId = action.payload;
      cabinet = { ...state.cabinet };
      cabinet.doorsMaterialId = materialId;
      return {
        ...state,
        cabinet
      };
    case MakeCustomCabinetActionTypes.SET_HANDLES_MATERIAL:
      materialId = action.payload;
      cabinet = { ...state.cabinet };
      cabinet.handlesMaterialId = materialId;
      return {
        ...state,
        cabinet
      };
    case MakeCustomCabinetActionTypes.SELECT_MANUFACTURER:
      const manufacturerId = action.payload;
      cabinet = { ...state.cabinet };
      cabinet.manufacturerId = manufacturerId;
      return {
        ...state,
        cabinet
      };
    default:
      return state;
  }
};

export default cartReducer;
