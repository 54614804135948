import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setEmail} from '../../../store/actions/signUpActions';
import { withTranslation } from 'react-i18next';
import ButtonBlue from '../ButtonBlue/ButtonBlue';

class FooterSignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
    }

    handleEmailInputChange = (val) => {
        this.setState({
            email: val
        })
    };

    handleSignUpBtnClick = () => {
        const {setEmail} = this.props;
        const {email} = this.state;
        setEmail(email);
        this.props.history.push('/register');
    }

    render () {
        const { t } = this.props;
        return (
            <div className="row is-full sign-up-container">
                <div className="columns is-marginless sales-promotions has-text-centered is-vcentered">
                    <div className="column is-offset-3 is-4">
                        <div className="sales-promotions-text">
                            { t("footer_sign_up")}
                        </div>
                    </div>
                    <div className="column is-2">
                        <div className="sales-promotions-input">
                            <input
                                onChange={e => this.handleEmailInputChange(e.target.value)}
                                placeholder={ t("your_email")}
                                type="text"
                            />
                            <ButtonBlue
                                className="sign-up-btn"
                                onClick={this.handleSignUpBtnClick}
                            >
                                SIGN ME UP!
                            </ButtonBlue>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEmail: (email) => dispatch(setEmail(email)),
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(FooterSignUp)));
