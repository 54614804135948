import React from 'react';

const GalleryArrowRIcon = (props) => {
    const { color = '#979797', className = '' } = props;

    return (
        <svg className={className} width="23px" height="40px" viewBox="0 0 23 40" version="1.1">
            <title>gallery-arrow-r</title>
            <desc>Created with Sketch.</desc>
            <g id="Index" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1371.000000, -2031.000000)" id="gallery-arrow-r" stroke={color} strokeWidth="2">
                    <polyline transform="translate(1382.000000, 2051.000000) scale(-1, 1) translate(-1382.000000, -2051.000000) "
                              points="1392 2032 1372 2051 1392 2070"></polyline>
                </g>
            </g>
        </svg>
    );
}

export default GalleryArrowRIcon;
