import * as SettingsActionTypes from '../actionTypes/settingsActionTypes';

const initState = {
  
};

const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default settingsReducer;
