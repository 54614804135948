import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  orderFilterOptionIds,
  orderFilterOptions
} from '../../constants/constants';
import { Link } from 'react-router';
import DropdownOrder from './DropdownOrder/DropdownOrder';
import InputImage from '../common/InputImage/InputImage';
import SearchImage from '../../assets/images/inputIcons/search.svg';
import Dropdown from '../common/Dropdown/Dropdown';
import { deepClone } from '../../helpers/deepCloneHelper';
import { addProduct } from '../../store/actions/cartActions';
import Loader from '../common/Loader/Loader';
import './myOrdersPage.scss';

class MyOrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOrderFilterId: orderFilterOptionIds.RECENT_AT_TOP,
      searchInputVal: ''
    };

    if (!props.isAuthorized) {
      props.history.push('/');
    }
  }

  onFilterOrderDropdownClick = id => {
    this.setState({
      selectedOrderFilterId: id
    });
  };

  onSearchInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.setState({
        searchInputVal: event.target.value
      });
    }
  };

  onSearchInputChange = event => {
    const value = event.target.value;
    if (value === '') {
      this.setState({
        searchInputVal: event.target.value
      });
    }
  };

  renderOrders() {
    const { selectedOrderFilterId, searchInputVal } = this.state;
    const { orders, materials, sizes, colors, addProduct } = this.props;
    let ordersCopy = deepClone(orders);

    switch (selectedOrderFilterId) {
      case orderFilterOptionIds.RECENT_AT_TOP:
        ordersCopy.sort(function(orderA, orderB) {
          const timeOrderA = new Date(orderA.timestampCreated).getTime();
          const timeOrderB = new Date(orderB.timestampCreated).getTime();
          return timeOrderA - timeOrderB;
        });
        break;
      case orderFilterOptionIds.LATEST_AT_TOP:
        ordersCopy.sort(function(orderA, orderB) {
          const timeOrderA = new Date(orderA.timestampCreated).getTime();
          const timeOrderB = new Date(orderB.timestampCreated).getTime();
          return timeOrderB - timeOrderA;
        });
        break;
      case orderFilterOptionIds.HIGHEST_PRICE_AT_TOP:
        ordersCopy.sort(function(orderA, orderB) {
          let totalOrderAPrice = 0;
          orderA.items.forEach(orderItem => {
            totalOrderAPrice += orderItem.price * orderItem.amount;
          });

          let totalOrderBPrice = 0;
          orderB.items.forEach(orderItem => {
            totalOrderBPrice += orderItem.price * orderItem.amount;
          });

          return totalOrderBPrice - totalOrderAPrice;
        });
        break;
      case orderFilterOptionIds.CHEAPEST_AT_TOP:
        ordersCopy.sort(function(orderA, orderB) {
          let totalOrderAPrice = 0;
          orderA.items.forEach(orderItem => {
            totalOrderAPrice += orderItem.price * orderItem.amount;
          });

          let totalOrderBPrice = 0;
          orderB.items.forEach(orderItem => {
            totalOrderBPrice += orderItem.price * orderItem.amount;
          });

          return totalOrderAPrice - totalOrderBPrice;
        });
        break;
    }

    let filteredOrders = [];
    ordersCopy.forEach(order => {
      const filteredOrderItems = order.items.filter(orderItem => {
        return orderItem.title.match(new RegExp(searchInputVal, 'gi'));
      });

      if (filteredOrderItems && filteredOrderItems.length > 0) {
        order.items = filteredOrderItems;
        filteredOrders.push(order);
      }
    });

    return (
      filteredOrders &&
      filteredOrders.map((order, index) => {
        return (
          <div key={index} className="ikt-ws_my-orders-page-order-item">
            <DropdownOrder
              order={order}
              materials={materials}
              sizes={sizes}
              colors={colors}
              addProduct={addProduct}
            />
          </div>
        );
      })
    );
  }

  isAllDataReady() {
    const { orders, materials, sizes, colors } = this.props;
    return (
      orders &&
      orders.length > 0 &&
      materials &&
      materials.length > 0 &&
      sizes &&
      sizes.length > 0 &&
      colors &&
      colors.length > 0
    );
  }

  render() {
    return (
      <div className="ikt-ws_my-orders-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_my-orders-page-top">
              <div className="ikt-ws_my-orders-page-heading">My Orders</div>
              <div className="ikt-ws_my-orders-page-filters">
                <InputImage
                  className="ikt-ws_my-orders-page-name-search"
                  placeholder="Search by name"
                  image={SearchImage}
                  onChange={this.onSearchInputChange}
                  onKeyDown={this.onSearchInputKeyDown}
                ></InputImage>
                <Dropdown
                  className="ikt-ws_my-orders-page-items-order-filter"
                  items={orderFilterOptions}
                  currentItemIndex={orderFilterOptionIds.RECENT_AT_TOP}
                  onClick={this.onFilterOrderDropdownClick}
                />
              </div>
            </div>
            <div className="ikt-ws_my-orders-page-orders-info">
              {this.renderOrders()}
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    orders: state.userAccount.orders,
    sizes: state.sizes.sizes,
    materials: state.materials.materials,
    colors: state.colors.colors,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProduct: product => {
      dispatch(addProduct(product));
    }
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(MyOrdersPage));
