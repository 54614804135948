import React from 'react';
import './button.scss';
import { Link } from 'react-router-dom';

const Button = props => {
  const onClick = props.disabled ? null : props.onClick;
  let className = props.className
    ? `ikt-ws_button ${props.className}`
    : `ikt-ws_button`;
  className = props.disabled ? `${className} disabled` : `${className}`;

  let button = (
    <button
      disabled={props.disabled}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={className}
      onClick={onClick}
    >
      {props.children}
    </button>
  );

  let resultContent;
  if (props.to && !props.disabled) {
    resultContent = <Link to={props.to}>{button}</Link>;
  } else {
    resultContent = button;
  }

  return resultContent;
};

export default Button;
