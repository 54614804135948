import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import CustomCabinet from '../CustomCabinet/CustomCabinet';
import SelectMaterial from './SelectMaterial/SelectMaterial';
import Loader from '../../common/Loader/Loader';
import {
  setCabinetSkeletonMateial,
  setCabinetDoorsMaterial,
  setCabinetHandlesMaterial
} from '../../../store/actions/makeCustomCabinetActions';
import './selectCabinetMaterialsPage.scss';
import * as MakeCustomCabinetActionTypes from '../../../store/actionTypes/makeCustomCabinetActionTypes';

class SelectCabinetMaterialsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skeletonMaterialId: -1,
      doorsMaterialId: -1,
      handlesMaterialId: -1
    };
  }

  selectSkeletonMaterial = materialId => {
    const { setCabinetSkeletonMateial } = this.props;
    this.setState({
      skeletonMaterialId: materialId
    });
    setCabinetSkeletonMateial(materialId);
  };

  selectDoorsMaterial = materialId => {
    const { setCabinetDoorsMaterial } = this.props;
    this.setState({
      doorsMaterialId: materialId
    });
    setCabinetDoorsMaterial(materialId);
  };

  selectHandlesMaterial = materialId => {
    const { setCabinetHandlesMaterial } = this.props;
    this.setState({
      handlesMaterialId: materialId
    });
    setCabinetHandlesMaterial(materialId);
  };

  renderCabinet() {
    const { cabinet, sizes } = this.props;

    return <CustomCabinet cabinet={cabinet} sizes={sizes} />;
  }

  isAllDataReady() {
    const { cabinet, sizes, handlesMaterials, materials } = this.props;
    return (
      Object.keys(cabinet).length > 0 &&
      sizes.length > 0 &&
      handlesMaterials.length > 0 &&
      materials.length > 0
    );
  }

  isNextBtnEnabled() {
    const {
      skeletonMaterialId,
      doorsMaterialId,
      handlesMaterialId
    } = this.state;

    return (
      skeletonMaterialId !== -1 &&
      doorsMaterialId !== -1 &&
      handlesMaterialId !== -1
    );
  }

  render() {
    const { materials, handlesMaterials } = this.props;

    return (
      <div className="ikt-ws_select-cabinet-materials-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_select-cabinet-materials-page-top">
              <div className="ikt-ws_select-cabinet-materials-left-col">
                {this.renderCabinet()}
              </div>
              <div className="ikt-ws_select-cabinet-materials-right-col">
                <div className="ikt-ws_select-cabinet-materials-right-col-heading">
                  Select Material
                </div>
                <SelectMaterial
                  heading="Skeleton"
                  hint="Select Material"
                  items={materials}
                  onClick={this.selectSkeletonMaterial}
                />
                <SelectMaterial
                  heading="Doors"
                  hint="Select Material"
                  items={materials}
                  onClick={this.selectDoorsMaterial}
                />
                <SelectMaterial
                  heading="Handles"
                  hint="Select handles"
                  items={handlesMaterials}
                  onClick={this.selectHandlesMaterial}
                />
              </div>
            </div>
            <div className="ikt-ws_select-cabinet-materials-page-bottom">
              <ButtonLinkEmpty
                to="/make-cabinet/cabinet-builder"
                className="ikt-ws_select-cabinet-materials-back-btn"
              >
                BACK
              </ButtonLinkEmpty>
              <ButtonTeal
                to="/make-cabinet/select-cabinet-manufacturer"
                className="ikt-ws_select-cabinet-materials-next-btn"
                disabled={!this.isNextBtnEnabled()}
              >
                NEXT
              </ButtonTeal>
            </div>
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    cabinet: state.makeCustomCabinet.cabinet,
    materials: state.materials.materials,
    handlesMaterials: state.materials.handlesMaterials,
    sizes: state.sizes.sizes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCabinetSkeletonMateial: materialId => {
      dispatch(setCabinetSkeletonMateial(materialId));
    },
    setCabinetDoorsMaterial: materialId => {
      dispatch(setCabinetDoorsMaterial(materialId));
    },
    setCabinetHandlesMaterial: materialId => {
      dispatch(setCabinetHandlesMaterial(materialId));
    }
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(SelectCabinetMaterialsPage);
