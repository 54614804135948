import * as ManufacturersActionTypes from '../actionTypes/manufacturersActionTypes';

export const getManufacturers = () => {
  return { type: ManufacturersActionTypes.GET_MANUFACTURERS };
};

export const getManufacturersSuccessful = manufacturers => {
  return {
    type: ManufacturersActionTypes.GET_MANUFACTURERS_SUCCESS,
    payload: manufacturers
  };
};

export const getManufacturersFailed = error => {
  return {
    type: ManufacturersActionTypes.GET_MANUFACTURERS_ERROR,
    payload: error
  };
};
