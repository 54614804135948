import React from 'react';

import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import DepartmentCard from '../DepartmentCard';

class ShopDepartments extends React.Component {
    render () {
        const {departments} = this.props;
        return (
            <div className="row shop-departments">
                <div className="row is-2 is-offset-4 dep-title">Shop by Department</div>
                <div className="row dep-content">
                    {departments && departments.map((item, index) => {
                        return (
                            <DepartmentCard key={index} data={item}/>
                        )
                    })}
                </div>
                <div className="row">
                    <ButtonTeal className="shop-dep-btn" to="/shop">GO TO SHOP</ButtonTeal>
                </div>
            </div>
        )
    }
}

export default ShopDepartments;
