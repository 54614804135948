import React from 'react';

const GalleryArrowLIcon = (props) => {
    const { color = '#979797', className = '' } = props;

    return (
        <svg className={className} width="23px" height="40px" viewBox="0 0 23 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>gallery-arrow-l</title>
            <desc>Created with Sketch.</desc>
            <g id="Index" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-47.000000, -2031.000000)" id="gallery-arrow-l" stroke={color} strokeWidth="2">
                    <polyline points="69 2032 49 2051 69 2070"></polyline>
                </g>
            </g>
        </svg>
    );
}

export default GalleryArrowLIcon;
