import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';

import App from './App';
import * as serviceWorker from './assets/scripts/serviceWorker.js';
import reportWebVitals from './assets/scripts/reportWebVitals';
import rootReducer from './store/reducers/rootReducer';
import { rootEpic } from './epics';
import { Auth0Provider } from './components/auth/auth0';
import {saveState} from './helpers/cartDataPersistance';
import throttle from 'lodash/throttle';
import AppConfig from "./config/index";
import history from "./utils/history";
import './i18n';

import './scss/app.scss';

require('./assets/scripts/bootstrap.js');

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk, epicMiddleware))
);

store.subscribe( throttle(() => {
  saveState('cart', {
    cart: {
      products: store.getState().cart.products
    }
  });
}, 1000));

epicMiddleware.run(rootEpic);

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={AppConfig.authDomain}
    client_id={AppConfig.authClientId}
    redirect_uri={`${window.location.origin}/auth0-callback`}
    response_type="token"
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();

reportWebVitals();
