import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {removeProducts} from '../../store/actions/cartActions';

import Address from './Address';
import Payment from './Payment';
import Confirmation from './Confirmation';
import ButtonGold from '../common/ButtonGold/ButtonGold';
import OrderSummary from '../common/OrderSummary';

const items = [
    {
        itemsTitle: 'Prebuilt items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            },
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    },

                ]
            }
        ]
    },
    {
        itemsTitle: 'Custom items',
        total: '$121211',
        products: [
            {
                estDelivery: '2 Days (1 September, 2019: My Home)',
                deliveryAddress: '',
                shipping: 'Free',
                subTotal: '€24 912.00',
                selectedProducts: [
                    {
                        title: 'For my granny',
                        description: 'Pseydo Marble (10x10)',
                        imageUrl: '',
                        color: '#545157',
                        count: 1,
                        price: 12456,
                    }
                ]
            }
        ]
    }
];

class OrderPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            isAddressValid: false,
            isPaymentValid: false,
            address: {
                address1: '',
                address2: '',
                postalCode: '',
                city: '',
                country: 'Germany',
                name: '',
                mobileNumber: ''
            },
            addressErrors: {},
            payment: {
                cardNumber: [],
                cardName: '',
                cardValidDate: [],
                cvv: ''
            },
            paymentErrors: {}
        };
    }

    addressValidation = () => {
        const {address} = this.state;
        let isValid = true;
        let errors = {};
        Object.keys(address).map((item) => {
            if(address[item].length === 0) {
                errors[item] = true;
                isValid = false
            }
        });
        this.setState({
            addressErrors: errors,
            isAddressValid: isValid,
            step: isValid ? 2 : 1
        })
    };

    paymentValidation = () => {
        const {payment} = this.state;
        let isValid = true;
        let errors = {};
        Object.keys(payment).map((item) => {
            if(payment[item].length === 0) {
                errors[item] = true;
                isValid = false
            }
        });
        this.setState({
            paymentErrors: errors,
            isPaymentValid: isValid,
            step: isValid ? 3 : 2
        })
    };

    onStepChange = (step) => {
        this.setState({step})
    };

    onAddressChange = (name, value) => {
        const {address} = this.state;
        address[name] = value;
        this.setState({
            address
        })
    };

    onPaymentChange = (name, value) => {
        const {payment} = this.state;
        payment[name] = value;
        this.setState({
            payment
        })
    };

    getTotalPrice = () => {
        const {cartItems} = this.props;
        let totalPrice = 0;
        cartItems.map(item => totalPrice += item.amount*item.price);
        return totalPrice;
    };

    onButtonClick = () => {
        const {step} = this.state;
        if (step === 1) {
            this.addressValidation();
        } else if (step === 2) {
            this.paymentValidation();
        } else {
            this.props.clearCartProducts();
            this.props.history.push('./success')
        }
    };

    render () {
        const {cartItems} = this.props;
        const {step, isAddressValid, isPaymentValid, address, addressErrors, payment, paymentErrors} = this.state;
        const totalPrice = this.getTotalPrice();
        const btnText = step === 1 ? 'CHECKOUT >' : step === 2 ? 'CONFIRMATION >' : 'PLACE ORDER';
        let selectedComponent;
        switch(step) {
            case 1:
                selectedComponent = <Address data={address} errors={addressErrors} onChange={this.onAddressChange} />;
                break;
            case 2:
                selectedComponent = <Payment data={payment} errors={paymentErrors} onChange={this.onPaymentChange} />;
                break;
            case 3:
                selectedComponent = <Confirmation cartItems={cartItems} totalPrice={totalPrice} />;
                break;
            default:
                return null;
        };

        return (
            <div className="columns is-marginless cart-page">
                <div className="column is-9">
                    <div className="rows cart-content">
                        <div className="row cart-page-title">
                            <div className="title">
                                <span>Make Order</span>
                            </div>
                            <div className="order-nmb">
                                <span>Order #554466</span>
                            </div>
                        </div>
                        <div className="row order-titles">
                            <div
                                className={step === 1 ? 'selected' : isAddressValid ? 'valid' : ''}
                                onClick={() => this.onStepChange(1)}
                            >
                                Delivery Address
                            </div>
                            <div
                                className={step === 2 ? 'selected' : isPaymentValid ? 'valid' : ''}
                                onClick={() => this.onStepChange(2)}
                            >
                                Payment
                            </div>
                            <div
                                className={step === 3 ? 'selected' : ''}
                                onClick={() => this.onStepChange(3)}
                            >
                                Confirmation
                            </div>
                        </div>
                        {selectedComponent}
                        <div className="row">
                            <ButtonGold
                                className="checkout-btn"
                                onClick={this.onButtonClick}
                            >
                                {btnText}
                            </ButtonGold>
                        </div>
                    </div>
                </div>
                <div className="column is-3 order-sum">
                    <OrderSummary
                        btnText={btnText}
                        onBtnClick={this.onButtonClick}
                        totalPrice={totalPrice}
                        isShowBtn={true}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        cartItems: state.cart.products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCartProducts: () => {dispatch(removeProducts())}
    }
};

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(OrderPage));
