import React, { useState } from "react";
import arrow from "../../assets/images/arrows/accordion-arrow.svg";
import "./Accordion.scss";
export default function Accordion({ question, answer }) {
  const [isOpened, setIsOpened] = useState(false);

  const toggleAccordion = () => {
    setIsOpened((prevState) => !prevState);
  };
  return (
    <div className="accordion">
      <div
        onClick={toggleAccordion}
        className={`accordion-header ${isOpened ? "header-opened" : ""}`}
      >
        <h2>{question}</h2>
        <div className={`accordion-indicator ${isOpened ? "opened" : ""}`}>
          <img src={arrow} alt="arrow-img" />
        </div>
      </div>
      {isOpened && <div className="accordion-body" dangerouslySetInnerHTML={{ __html: answer }}></div>}
    </div>
  );
}
