import * as CityActionTypes from '../actionTypes/cityActionTypes';

const initState = {
  cities: [],
  citiesError: null,
  citiesBusy: false
};

const citiesReducer = (state = initState, action) => {
  switch (action.type) {
    case CityActionTypes.GET_CITIES:
      return {
        ...state,
        citiesBusy: true
      };
    case CityActionTypes.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        citiesError: null,
        citiesBusy: false
      };
    case CityActionTypes.GET_CITIES_ERROR:
      return {
        ...state,
        citiesError: action.payload.message,
        citiesBusy: false
      };
    default:
      return state;
  }
};

export default citiesReducer;
