import React from 'react';

const TwitterIcon = (props) => {
    const { color = '#55ACEE', ...rest } = props;

    return (
        <svg {...rest}
            width="30px" height="29px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Other/Footer/twitter</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1802.000000, -1091.000000)" fillRule="nonzero">
                    <g id="Other/Footer/twitter" transform="translate(1802.000000, 1091.000000)">
                        <ellipse id="Oval" fill={color} cx="15" cy="14.5" rx="15" ry="14.5"></ellipse>
                        <path d="M24,9.65738492 C23.3747055,9.93836016 22.7020717,10.1284006
                        21.9966645,10.2132731 C22.7168977,9.77599548 23.2696227,9.08410054
                        23.5305089,8.25830745 C22.8565746,8.66342841 22.1098106,8.95731902
                        21.3157073,9.11573002 C20.6794884,8.42910666 19.7730194,8 18.7692708,8
                        C16.8434469,8 15.2815111,9.58279205 15.2815111,11.5343312 C15.2815111,11.8113527
                        15.3124637,12.0809941 15.372288,12.3398287 C12.4736681,12.192488 9.90356192,10.7855032
                        8.18322164,8.64655935 C7.88305946,9.16844583 7.71086937,9.77599548
                        7.71086937,10.4236091 C7.71086937,11.6495152 8.32706019,12.7317707
                        9.26240093,13.3654147 C8.69094832,13.3472277 8.15278925,13.1882896
                        7.68277793,12.9228655 C7.68251782,12.9378895 7.68251782,12.9529135
                        7.68251782,12.9676739 C7.68251782,14.6801469 8.88524741,16.1084816
                        10.4804768,16.4329474 C10.1881178,16.5141297 9.87937207,16.5570931
                        9.5617828,16.5570931 C9.33653111,16.5570931 9.11830227,16.535216
                        8.90553567,16.4940977 C9.34927629,17.8979196 10.6370605,18.9198155
                        12.1636219,18.9485456 C10.9697359,19.8966394 9.46606383,20.4614892
                        7.83155849,20.4614892 C7.55064414,20.4614892 7.27233085,20.4448837
                        7,20.4119364 C8.54268796,21.4151181 10.3764344,22
                        12.3459561,22 C18.7612075,22 22.2695156,16.6145533
                        22.2695156,11.9436694 C22.2695156,11.79053 22.2661342,11.6379177
                        22.2593715,11.4866234 C22.9413691,10.988459 23.5325897,10.3661489
                        24,9.65738492 L24,9.65738492 Z" id="Path" fill="#F1F2F2"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default TwitterIcon;
