import React from 'react';

import DropdownCustom from '../DropdownCustom/DropdownCustom';

class AddressCard extends React.Component {
    render () {
        return (
           <>
               <DropdownCustom className="address-card-dropdown" title={"Delivery / Billing Address"}>
                   <div className="column is-3">
                       <div className="address-title">Delivery Address</div>
                       <div className="address-name">My Home</div>
                       <div className="address-title">Billing Address</div>
                       <div className="address-name">Same as Shipping Address</div>
                   </div>
                   <div className="column is-3 is-offset-2">
                       <div className="address-title">Billing Address</div>
                       <div className="address-name">Same as Shipping Address</div>
                   </div>
               </DropdownCustom>
           </>
        )
    }
}

export default AddressCard;
