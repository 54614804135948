import * as NewProductsActionTypes from '../actionTypes/newProductsActionTypes';
import { filterProducts } from '../../helpers/filterHelper';

const initState = {
  newProducts: [],
  newProductsFiltered: [],
  newProductsError: null,
  newProductsBusy: false
};

const newProductsReducer = (state = initState, action) => {
  let selectedFilters;
  let filteredProducts;
  let products;

  switch (action.type) {
    case NewProductsActionTypes.GET_NEW_PRODUCTS:
      return {
        ...state,
        newProductsBusy: true
      };
    case NewProductsActionTypes.GET_NEW_PRODUCTS_SUCCESS:
      return {
        ...state,
        newProducts: action.payload,
        newProductsFiltered: action.payload,
        newProductsError: null,
        newProductsBusy: false
      };
    case NewProductsActionTypes.GET_NEW_PRODUCTS_ERROR:
      return {
        ...state,
        newProductsError: action.payload.message,
        newProductsBusy: false
      };
    case NewProductsActionTypes.APPLY_PRODUCTS_FILTERS:
      selectedFilters = action.payload;
      filteredProducts = filterProducts(selectedFilters, state.newProducts);
      return {
        ...state,
        newProductsFiltered: filteredProducts
      };
    default:
      return state;
  }
};

export default newProductsReducer;
