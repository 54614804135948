import * as DepartmentsActionTypes from '../actionTypes/departmentsActionTypes';

const initState = {
  departments: [],
  departmentsError: null,
  departmentsBusy: false
};

const departmentsReducer = (state = initState, action) => {
  switch (action.type) {
    case DepartmentsActionTypes.GET_DEPARTMENTS:
      return {
        ...state,
        departmentsBusy: true
      };
    case DepartmentsActionTypes.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload,
        departmentsError: null,
        departmentsBusy: false
      };
    case DepartmentsActionTypes.GET_DEPARTMENTS_ERROR:
      return {
        ...state,
        departmentsError: action.payload.message,
        departmentsBusy: false
      };
    default:
      return state;
  }
};

export default departmentsReducer;
