import React from 'react';
import OrderItem from '../../common/Product';
import DropdownIcon from '../../../assets/images/components/DropdownIcon';
import './dropdownOrder.scss';
import { orderStateValues, orderStates } from '../../../constants/constants';
import { renderDate } from '../../../helpers/renderDate';
import { renderPrice } from '../../../helpers/priceHelpers';
import { deepClone } from '../../../helpers/deepCloneHelper';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import ButtonGold from '../../common/ButtonGold/ButtonGold';
import { toast } from 'react-toastify';

class DropdownOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true
    };
  }

  dropdownToggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  renderOrderState() {
    const { order } = this.props;
    let orderStateVal = orderStateValues[order.state];

    if (order.state === orderStates.DELIVERED && order.timestampDelivered) {
      const date = renderDate(order.timestampDelivered);
      orderStateVal = `${orderStateVal} ${date}`;
    }

    let orderStateClass;
    switch (order.state) {
      case orderStates.IN_TRANSIT:
        orderStateClass = 'ikt-ws_in-transit';
        break;
      case orderStates.MANUFACTURING:
        orderStateClass = 'ikt-ws_manufacturing';
        break;
      case orderStates.DELIVERED:
        orderStateClass = 'ikt-ws_delivered';
        break;
    }

    return (
      <div
        className={`ikt-ws_dropdown-order-trigger-order-state ${orderStateClass}`}
      >
        {orderStateVal}
      </div>
    );
  }

  renderOrderLeftCaption() {
    const { order } = this.props;
    let totalOrderPrice = order.items.reduce((accumulator, orderItem) => {
      return accumulator + orderItem.price * orderItem.amount;
    }, 0);

    totalOrderPrice = renderPrice(totalOrderPrice);
    const orderCaption = `Order #${order.id} (${totalOrderPrice})`;

    return (
      <div className="ikt-ws_dropdown-order-trigger-left-caption">
        {orderCaption}
      </div>
    );
  }

  renderOrderItems() {
    const { order, sizes, materials, colors } = this.props;
    return (
      <div className="ikt-ws_dropdown-order-order-items">
        {order &&
          order.items.length > 0 &&
          order.items.map((orderItem, index) => {
            return (
              <OrderItem
                key={index}
                data={orderItem}
                sizes={sizes}
                materials={materials}
                colors={colors}
                isOrdersPage={true}
              />
            );
          })}
      </div>
    );
  }

  addOrderToCart = () => {
    const { addProduct, order } = this.props;
    order &&
      order.items.forEach(orderItem => {
        const orderItemCopy = deepClone(orderItem);
        addProduct(orderItemCopy);
      });
    toast.success(`Order #${order.id} was added to cart`);
  };

  render() {
    const { isActive } = this.state;
    const {
      className = '',
      leftCaption,
      children,
      color = '#000',
      order
    } = this.props;

    return (
      <div
        className={`ikt-ws_dropdown-order${
          isActive ? ` ikt-ws_is-active ` : ``
        }${className ? ` ${className}` : ``}`}
      >
        <div
          className="ikt-ws_dropdown-order-trigger"
          onClick={this.dropdownToggle}
        >
          {this.renderOrderLeftCaption()}
          {this.renderOrderState()}
          <DropdownIcon
            className="ikt-ws_dropdown-order-chevron"
            color={color}
          />
        </div>
        <div className="ikt-ws_dropdown-order-content">
          {this.renderOrderItems()}
          <div className="ikt-ws_dropdown-order-order-buttons">
            <ButtonGold
              className="ikt-ws_dropdown-order-order-button"
              onClick={this.addOrderToCart}
            >
              REPEAT ORDER
            </ButtonGold>
            {order.state === orderStates.IN_TRANSIT && (
              <ButtonTeal className="ikt-ws_dropdown-order-order-button">
                TRACK ORDER
              </ButtonTeal>
            )}
            <ButtonTeal
              to={`/orders/${order.id}`}
              className="ikt-ws_dropdown-order-order-button"
            >
              DETAILS
            </ButtonTeal>
          </div>
        </div>
      </div>
    );
  }
}

export default DropdownOrder;
