import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getSizesSuccessful,
  getSizesFailed
} from '../store/actions/sizesActions';
import * as SizesActionTypes from '../store/actionTypes/sizesActionTypes';
import { sizes as dummySizes } from './epicsData';

export const getSizes = actions$ => {
  return actions$.pipe(
    ofType(SizesActionTypes.GET_SIZES),
    delay(2000),
    mapTo(getSizesSuccessful(dummySizes)),
    catchError((error, caught) => merge(of(getSizesFailed(error)), caught))
  );
};
