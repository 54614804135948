import React from 'react';
import { Link } from 'react-router-dom';
import { renderPrice } from '../../../helpers/priceHelpers';
import './cabinetItem.scss';

class CabinetItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { cabinetItem, materials, sizes, colors } = this.props;

    const price = renderPrice(cabinetItem.price);
    const material = materials.find(material => {
      return material.id === cabinetItem.materialId;
    });
    const size = sizes.find(size => {
      return size.id === cabinetItem.sizeId;
    });

    const color = colors.find(color => {
      return color.id === cabinetItem.colorId;
    });

    return (
      <div className="ikt-ws_cabinet-item-container">
        <Link
          className="ikt-ws_cabinet-item-image"
          to={`/cabinet/${cabinetItem.id}`}
          style={{ backgroundImage: `url(${cabinetItem.image})` }}
        ></Link>
        <div className="ikt-ws_cabinet-item-bottom">
          <div className="ikt-ws_cabinet-item-title">{cabinetItem.title}</div>
          <div className="ikt-ws_cabinet-item-material-n-size">
            {`${material.value} (${size.value})`}
          </div>
          <div className="ikt-ws_cabinet-item-colors">
            <div className={`ikt-ws_cabinet-item-color-btn-content`}>
              <div className="ikt-ws_cabinet-item-color-btn-outer">
                <div
                  className="ikt-ws_cabinet-item-color-btn-inner"
                  style={{ backgroundColor: color.value }}
                ></div>
              </div>
            </div>
          </div>
          <div className="ikt-ws_cabinet-item-price">{price}</div>
        </div>
      </div>
    );
  }
}

export default CabinetItem;
