import * as SingleProductActionTypes from '../actionTypes/singleProductActionTypes';

const initState = {
  singleProduct: {},
  singleProductError: null,
  singleProductBusy: false
};

const singleProductReducer = (state = initState, action) => {
  switch (action.type) {
    case SingleProductActionTypes.GET_SINGLE_PRODUCT:
      return {
        ...state,
        singleProductBusy: true
      };
    case SingleProductActionTypes.GET_SINGLE_PRODUCT_SUCCESS:
      const product = action.payload;

      return {
        ...state,
        singleProduct: product,
        singleProductError: null,
        singleProductBusy: false
      };
    case SingleProductActionTypes.GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        singleProductError: action.payload.message,
        singleProductBusy: false
      };
    case SingleProductActionTypes.RESET_SINGLE_PRODUCT:
      return {
        ...state,
        singleProduct: {}
      };
    default:
      return state;
  }
};

export default singleProductReducer;
