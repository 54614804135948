import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getFiltersSuccessful,
  getFiltersFailed
} from '../store/actions/filtersActions';
import * as filtersActionTypes from '../store/actionTypes/filtersActionTypes';
import { colors, departments, materials, sizes, styles } from './epicsData';

//for testing

const filters = [
  {
    id: 1,
    type: 'text',
    title: 'Department',
    isActive: true,
    options: departments.slice(0)
  },
  {
    id: 2,
    type: 'price',
    title: 'Price',
    isActive: true,
    options: []
  },
  {
    id: 3,
    type: 'distance',
    title: 'Distance (km)',
    isActive: true,
    options: []
  },
  /*{
        id: 4,
        type: 'chips',
        title: 'Size',
        isActive: true,
        options: sizes.slice(0)
    },*/
  /* {
        id: 5,
        type: 'text',
        title: 'Style',
        isActive: true,
        options: styles.slice(0)
    },*/
  {
    id: 6,
    type: 'text',
    title: 'Material',
    isActive: true,
    options: materials.slice(0)
  },
  {
    id: 7,
    type: 'color',
    title: 'Color',
    isActive: true,
    options: colors.slice(0)
  }
];

export const getFilters = actions$ => {
  return actions$.pipe(
    ofType(filtersActionTypes.GET_FILTERS),
    delay(2000),
    mapTo(getFiltersSuccessful(filters)),
    catchError((error, caught) => merge(of(getFiltersFailed(error)), caught))
  );
};
