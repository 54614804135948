import React from 'react';

import Input from '../common/Input/Input';

class PaymentCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cvv: '',
        };
    }

    handleCvvChange = (val) => {
        const {onCvvChange, id} = this.props;
        this.setState({
            cvv: val
        });
        onCvvChange(val);
    };

    handleSelect = () => {
        const {onCardSelect, id} = this.props;
        onCardSelect(id)
    }

    render () {
        const {data, isCardSelected} = this.props;
        return (
            <div className="row payment-card">
                <div className="column is-3">
                    <div className="payment-card-title">{`${data.cardType} Ending ${data.cardNumber}`}</div>
                    <div className="payment-card-name">{data.cardName}</div>
                    <Input
                        className="payment-input"
                        disabled={!isCardSelected}
                        onChange={e => this.handleCvvChange(e.target.value)}
                        placeholder={"CVV"}
                    />
                </div>
                <div className="column is-offset-8"></div>
            </div>
        )
    }
}

export default PaymentCard;
