import * as ColorsActionTypes from '../actionTypes/colorsActionTypes';

const initState = {
  colors: [],
  colorsError: null,
  colorsBusy: false
};

const colorsReducer = (state = initState, action) => {
  switch (action.type) {
    case ColorsActionTypes.GET_COLORS:
      return {
        ...state,
        colorsBusy: true
      };
    case ColorsActionTypes.GET_COLORS_SUCCESS:
      return {
        ...state,
        colors: action.payload,
        colorsError: null,
        colorsBusy: false
      };
    case ColorsActionTypes.GET_COLORS_ERROR:
      return {
        ...state,
        colorsError: action.payload.message,
        colorsBusy: false
      };
    default:
      return state;
  }
};

export default colorsReducer;
