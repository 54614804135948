import React from 'react';
import DropDown from '../../common/DropdownCustom/DropdownCustom';
import CartProducts from '../CartProducts';

class CartItem extends React.Component {
    render () {
        const {item, products, totalPrice, isOrderPage} = this.props;
        return (
            <div className="cart-item">
                <DropDown title={item.itemsTitle}>
                    <div className="cart-dropdown-content">
                        <CartProducts isOrderPage={isOrderPage} products={products} totalPrice={totalPrice} />
                    </div>
                </DropDown>
                <div className="cart-item-total">{`Total ${item.itemsTitle} € ${totalPrice}`}</div>
            </div>
        )
    }
}

export default CartItem;
