import * as AuthActionTypes from '../actionTypes/authActionTypes';

const initState = {
  isAuthorized: false,
  isLoginPageShown: false,
  isRegistrationPageShown: false,
  isResetPasswordPageShown: false,
  loginErrors: {},
  registrationErrors: {}
};

const authReducer = (state = initState, action) => {
  let fields;
  switch (action.type) {
    case AuthActionTypes.AUTHORIZE:
      const isAuthorized = action.payload;

      return {
        ...state,
        isAuthorized
      };
    case AuthActionTypes.SHOW_LOGIN_PAGE:
      return {
        ...state,
        isLoginPageShown: true,
        isRegistrationPageShown: false,
        isResetPasswordPageShown: false
      };
    case AuthActionTypes.SHOW_REGISTRATION_PAGE:
      return {
        ...state,
        isLoginPageShown: false,
        isRegistrationPageShown: true,
        isResetPasswordPageShown: false
      };
    case AuthActionTypes.SHOW_RESET_PASSWORD_PAGE:
      return {
        ...state,
        isLoginPageShown: false,
        isRegistrationPageShown: false,
        isResetPasswordPageShown: true
      };
    case AuthActionTypes.HIDE_AUTH_PAGES:
      return {
        ...state,
        isLoginPageShown: false,
        isRegistrationPageShown: false,
        isResetPasswordPageShown: false
      };

    case AuthActionTypes.LOG_OUT:
      return {
        ...state,
        isAuthorized: false
      };
    case AuthActionTypes.SET_LOGIN_ERRORS:
      return {
        ...state,
        loginErrors: { ...state.loginErrors, ...action.payload }
      };
    case AuthActionTypes.RESET_LOGIN_ERRORS:
      let loginErrors = { ...state.loginErrors };
      fields = action.payload;
      fields &&
        fields.forEach(field => {
          delete loginErrors[field];
        });
      return {
        ...state,
        loginErrors
      };
    case AuthActionTypes.SET_REGISTRATION_ERRORS:
      return {
        ...state,
        registrationErrors: { ...state.registrationErrors, ...action.payload }
      };
    case AuthActionTypes.RESET_REGISTRATION_ERRORS:
      let registrationErrors = { ...state.registrationErrors };
      fields = action.payload;
      fields &&
        fields.forEach(field => {
          delete registrationErrors[field];
        });
      return {
        ...state,
        registrationErrors
      };
    default:
      return state;
  }
};

export default authReducer;
