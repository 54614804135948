import * as FeaturedProductsActionTypes from '../actionTypes/featuredProductsActionTypes';
import { filterProducts } from '../../helpers/filterHelper';

const initState = {
  featuredProducts: [],
  featuredProductsFiltered: [],
  featuredProductsError: null,
  featuredProductsBusy: false
};

const featuredProductsReducer = (state = initState, action) => {
  let selectedFilters;
  let filteredProducts;
  let products;

  switch (action.type) {
    case FeaturedProductsActionTypes.GET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProductsBusy: true
      };
    case FeaturedProductsActionTypes.GET_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        featuredProducts: action.payload,
        featuredProductsFiltered: action.payload,
        featuredProductsError: null,
        featuredProductsBusy: false
      };
    case FeaturedProductsActionTypes.GET_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        featuredProductsError: action.payload.message,
        featuredProductsBusy: false
      };
    case FeaturedProductsActionTypes.APPLY_PRODUCTS_FILTERS:
      selectedFilters = action.payload;
      filteredProducts = filterProducts(
        selectedFilters,
        state.featuredProducts
      );
      return {
        ...state,
        featuredProductsFiltered: filteredProducts
      };
    default:
      return state;
  }
};

export default featuredProductsReducer;
