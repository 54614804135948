import * as NewProductsActionTypes from '../actionTypes/newProductsActionTypes';

export const getNewProducts = searchParams => {
  return {
    type: NewProductsActionTypes.GET_NEW_PRODUCTS,
    payload: searchParams
  };
};

export const getNewProductsSuccessful = products => {
  return {
    type: NewProductsActionTypes.GET_NEW_PRODUCTS_SUCCESS,
    payload: products
  };
};

export const getNewProductsFailed = error => {
  return {
    type: NewProductsActionTypes.GET_NEW_PRODUCTS_ERROR,
    payload: error
  };
};

export const applyNewProductFilters = filters => {
  return {
    type: NewProductsActionTypes.APPLY_PRODUCTS_FILTERS,
    payload: filters
  };
};
