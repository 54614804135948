import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getSingleProductSuccessful,
  getSingleProductFailed
} from '../store/actions/singleProductActions';
import * as SingleProductActionTypes from '../store/actionTypes/singleProductActionTypes';
import { featuredProducts, newProducts, similarProducts } from './epicsData';

const allProducts = featuredProducts
  .concat(newProducts)
  .concat(similarProducts);

export const getSingleProduct = actions$ => {
  return actions$.pipe(
    ofType(SingleProductActionTypes.GET_SINGLE_PRODUCT),
    delay(2000),
    mergeMap(({ payload }) => {
      const productId = payload;
      const product = allProducts.find(currProduct => {
        return currProduct.id === productId;
      });

      return [getSingleProductSuccessful(product)];
    }),
    catchError((error, caught) =>
      merge(of(getSingleProductFailed(error)), caught)
    )
  );
};
