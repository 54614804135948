import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import DropdownUserAccount from '../../common/DropdownUserAccount/DropdownUserAccount';

import CartIcon from '../../../assets/images/menuIcons/cart.svg';
import CartIconDark from '../../../assets/images/menuIcons/cart-dark.svg';
import Logo from '../../../assets/images/logo/regiom-logo-black.svg';
import LogoDark from '../../../assets/images/logo/regiom-logo.svg';
import SearchIcon from '../../../assets/images/menuIcons/search.svg';
import SearchIconDark from '../../../assets/images/menuIcons/search-dark.svg';
import UserIcon from '../../../assets/images/menuIcons/user.svg';
import UserIconDark from '../../../assets/images/menuIcons/user-dark.svg';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  };

  onLoginIconClick = () => {
    const { showLoginPage, isAuthorized } = this.props;
    if (!isAuthorized) {
      showLoginPage();
    }
  };

  isShowDropdownUserAccount() {
    const { isAuthorized, userProfile } = this.props;

    return isAuthorized && userProfile && userProfile.image;
  }

  renderMyCabinetsLink() {
    const { isAuthorized } = this.props;
    return isAuthorized ? (
      <Link to="/cabinets" className="navbar-item">
        My Cabinets
      </Link>
    ) : null;
  }

  render() {
    const {
      isDarkTheme,
      cartItems,
      userProfile,
      logOut,
      isAuthorized,
      t
    } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <nav className="navbar menu-bar" role="navigation" aria-label="menu">
        <div className="navbar-brand">
          <Link to="/" className="logo">
            <img src={isDarkTheme ? LogoDark : Logo} />
          </Link>
          <a
            role="button"
            aria-label="menu"
            aria-expanded="false"
            className={`navbar-burger burger ${isMenuOpen ? 'is-active' : ''}`}
            data-target="navbarBasicExample"
            onClick={this.toggle}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          { /* <div
            aria-label="menu"
            aria-expanded="false"
            className={`navbar-burger burger ${isMenuOpen ? 'is-active' : ''}`}
            data-target="navbarBasicExample"
            onClick={this.toggle}
            role="button"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div> */ }
        </div>
        <div
          className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}
          id="navbarBasicExample"
        >
          <div className={`navbar-start ${isDarkTheme ? 'dark-items' : ''}`}>
          <Link to="/" className="navbar-item">
              Home
            </Link>
            <Link to="/gallery" className="navbar-item">
              Gallery
            </Link>
            <Link to="/shop" className="navbar-item">
              Shop
            </Link>
            <Link to="/" className="navbar-item">
              Information
            </Link>
            {/* <a className="navbar-item">Style</a>
            <a className="navbar-item">Department</a> */}
            {/* <a className="navbar-item">Gallery</a>
            <a className="navbar-item">Design</a>
            <a className="navbar-item">About</a>
            <a className="navbar-item">Shipping</a>
            <a className="navbar-item">Track Cabinet</a> */}
            {this.renderMyCabinetsLink()}
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="icon search-icon">
                <img src={isDarkTheme ? SearchIconDark : SearchIcon} />
              </div>
            </div>
            <div className="navbar-item">
              <ButtonTeal
                className="ikt-ws_make-own-cabinet"
                to="/make-cabinet/cabinet-builder"
              >
                { t('make_your_own_cabient').toUpperCase() }
              </ButtonTeal>
            </div>
            <div className="navbar-item">
              <Link to="/cart" className="icon cart-icon">
                <img src={isDarkTheme ? CartIconDark : CartIcon} />
                {cartItems.length !== 0 && (
                  <div className="cart-badge">{cartItems.length}</div>
                )}
              </Link>
            </div>
            <div className="navbar-item">
              {this.isShowDropdownUserAccount() ? (
                <DropdownUserAccount
                  image={userProfile.image}
                  logOut={logOut}
                />
              ) : (
                <div
                  className="icon user-image-icon"
                  style={{ cursor: isAuthorized ? '' : 'pointer' }}
                  onClick={this.onLoginIconClick}
                >
                  <img src={isDarkTheme ? UserIconDark : UserIcon} />
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProp = state => {
  return {
    cartItems: state.cart.products
  };
};

export default connect(mapStateToProp, null)(withTranslation()(Menu));
