import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getUserAccountDataSuccessful,
  getUserAccountDataFailed
} from '../store/actions/userAccountActions';
import * as uaserAccountActionTypes from '../store/actionTypes/userAccountActionTypes';
import userImage3 from '../assets/images/users/photo3.jpg';
import { orders, customCabinets } from './epicsData';
//for testing

const userAccountData = {
  userProfile: {
    image: userImage3
  },
  orders,
  customCabinets
};

export const getUserAccountData = actions$ => {
  return actions$.pipe(
    ofType(uaserAccountActionTypes.GET_USER_ACCOUNT_DATA),
    delay(2000),
    mapTo(getUserAccountDataSuccessful(userAccountData)),
    catchError((error, caught) =>
      merge(of(getUserAccountDataFailed(error)), caught)
    )
  );
};
