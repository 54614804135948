import React from 'react';

import DropdownCustom from '../DropdownCustom/DropdownCustom';

class PaymentMethodCard extends React.Component {
    render () {
        return (
            <>
                <DropdownCustom className="payment-method-dropdown" title={"Payment Method"}>
                    <div className="column is-3">
                        <div className="method-title">MasterCard Ending 5989</div>
                        <div className="method-name">Johannes Ellmeier</div>
                    </div>
                </DropdownCustom>
            </>
        )
    }
}

export default PaymentMethodCard;
