export const CHECK_AUTHORIZATION = 'auth/CHECK_AUTHORIZATION';
export const CHECK_CURRENT_AUTH_STATUS = 'auth/CHECK_CURRENT_AUTH_STATUS';
export const AUTHORIZE = 'auth/AUTHORIZE';
export const SHOW_LOGIN_PAGE = 'auth/SHOW_LOGIN_PAGE';
export const SHOW_REGISTRATION_PAGE = 'auth/SHOW_REGISTRATION_PAGE';
export const SHOW_RESET_PASSWORD_PAGE = 'auth/SHOW_RESET_PASSWORD_PAGE';
export const HIDE_AUTH_PAGES = 'auth/HIDE_AUTH_PAGES';
export const LOG_OUT = 'auth/LOG_OUT';
export const RESET_LOGIN_ERRORS = 'auth/RESET_LOGIN_ERRORS';
export const SET_LOGIN_ERRORS = 'auth/SET_LOGIN_ERRORS';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const REGISTER_USER = 'auth/REGISTER_USER';
export const SET_REGISTRATION_ERRORS = 'auth/SET_REGISTRATION_ERRORS';
export const RESET_REGISTRATION_ERRORS = 'auth/RESET_REGISTRATION_ERRORS';
