import React from 'react';

import Button from '../Button/Button';
import './buttonLinkEmpty.scss';

/**
 * Usage:
 * <ButtonLinkEmpty className="ikt-ws_make-own-cabinet">
 *     MAKE YOUR OWN CABINET
 * </ButtonLinkEmpty>
 */

const ButtonLinkEmpty = props => {
  return (
    <Button
      {...props}
      className={`link-empty${props.className ? ` ${props.className}` : ``}`}
    >
      {props.children}
    </Button>
  );
};

export default ButtonLinkEmpty;
