import React from 'react';

import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import FooterSignUp from '../common/FooterSignUp';
import SuccessIcon from '../../assets/images/btnIcons/success.svg';

class SuccessPage extends React.Component {
    render () {
        return (
            <div className="rows is-marginless thank-you-page">
                <div className="row img-content">
                    <div className="success-img">
                        <img src={SuccessIcon}/>
                    </div>
                </div>
                <div className="row text-content">
                    <div className="thank-you-title">Thank you!</div>
                    <div className="thank-you-text">Your order is being processed. We will let you know about status changes via email.</div>
                    <ButtonTeal className="back-to-shop-btn" to="/shop">BACK TO SHOP</ButtonTeal>
                </div>
                <FooterSignUp/>
            </div>
        )
    }
}

export default SuccessPage;
