const FILTER_PRICE_TYPE = 'price';
const FILTER_DISTANCE_TYPE = 'distance';

export const filterProducts = (selectedFilters, products) => {
  const filteredProducts =
    products &&
    products.filter(product => {
      let isAddProduct = true;
      let isAllFiltersEmpty = true;
      isAddProduct = selectedFilters.reduce((accumulator, filter) => {
        const departmentFilterId = 1;
        const sizeFilterId = 4;
        const styleFilterId = 5;
        const materialFilterId = 6;
        const colorFilterId = 7;
        let isSutisfyCurrentFilter = false;

        if (
          filter.filterType !== FILTER_PRICE_TYPE &&
          filter.filterType !== FILTER_DISTANCE_TYPE
        ) {
          if (filter.selectedOptions.length > 0) {
            isAllFiltersEmpty = false;
            if (filter.filterId === departmentFilterId) {
              filter.selectedOptions.forEach(function(selctedFilterOptionId) {
                if (product.departmentIds.includes(selctedFilterOptionId)) {
                  isSutisfyCurrentFilter = true;
                }
              });
            } else if (filter.filterId === sizeFilterId) {
              filter.selectedOptions.forEach(function(selctedFilterOptionId) {
                if (product.sizeId === selctedFilterOptionId) {
                  isSutisfyCurrentFilter = true;
                }
              });
            } else if (filter.filterId === styleFilterId) {
              filter.selectedOptions.forEach(function(selctedFilterOptionId) {
                if (product.styleId === selctedFilterOptionId) {
                  isSutisfyCurrentFilter = true;
                }
              });
            } else if (filter.filterId === materialFilterId) {
              filter.selectedOptions.forEach(function(selctedFilterOptionId) {
                if (product.materialId === selctedFilterOptionId) {
                  isSutisfyCurrentFilter = true;
                }
              });
            } else if (filter.filterId === colorFilterId) {
              filter.selectedOptions.forEach(function(selctedFilterOptionId) {
                if (product.colorIds.includes(selctedFilterOptionId)) {
                  isSutisfyCurrentFilter = true;
                }
              });
            }
          } else {
            isSutisfyCurrentFilter = true;
          }
        } else if (filter.filterType === FILTER_PRICE_TYPE) {
          if (filter.isActive) {
            let isSatisfyPriceFrom = false;
            let isSatisfyPriceTo = false;

            if ((filter.priceFrom || filter.priceTo) && filter.isActive) {
              isAllFiltersEmpty = false;
            }

            if (filter.isActive) {
              if (
                (filter.priceFrom && product.price >= filter.priceFrom) ||
                !filter.priceFrom
              ) {
                isSatisfyPriceFrom = true;
              }

              if (
                (filter.priceTo && product.price <= filter.priceTo) ||
                !filter.priceTo
              ) {
                isSatisfyPriceTo = true;
              }

              if (isSatisfyPriceFrom && isSatisfyPriceTo) {
                isSutisfyCurrentFilter = true;
              }
            }
          } else {
            isSutisfyCurrentFilter = true;
          }
        } else if (filter.filterType === FILTER_DISTANCE_TYPE) {
          if (filter.isActive) {
            let radius = parseInt(filter.radius);

            if (radius && radius > 0) {
              isAllFiltersEmpty = false;
              if (product.distance && product.distance <= radius) {
                isSutisfyCurrentFilter = true;
              } else {
                isSutisfyCurrentFilter = false;
              }
            } else {
              isSutisfyCurrentFilter = true;
            }
          } else {
            isSutisfyCurrentFilter = true;
          }
        }
        return accumulator && isSutisfyCurrentFilter;
      }, true);

      return isAddProduct || isAllFiltersEmpty;
    });

  return filteredProducts || [];
};
