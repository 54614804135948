import React from 'react';

const MoreIcon = (props) => {
    const { color = '#1899A5', className = '', onClick = () => {} } = props;

    return (
        <svg onClick={onClick}
             className={className}
             width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>UI/Buttons/Teal/more</title>
            <desc>Created with Sketch.</desc>
            <g id="Dev" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Export" transform="translate(-1501.000000, -333.000000)" fill={color}>
                    <path d="M1509,333 L1509,339 L1515,339 L1515,341 L1509,341 L1509,347 L1507,347
                    L1507,341 L1501,341 L1501,339 L1507,339 L1507,333 L1509,333 Z"
                          id="UI/Buttons/Teal/more"></path>
                </g>
            </g>
        </svg>
    );
}

export default MoreIcon;
