import React from 'react';

import './shopChipsFilterContent.scss';

class ShopChipsFilterContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    renderFilterOptions(filterData, selectedFilterData) {
        return filterData.options && filterData.options.map((option, index) => {
            let optionJSX;

            const isActive = selectedFilterData.selectedOptions &&
                selectedFilterData.selectedOptions.includes(option.id);

            optionJSX = (<div key={index}
                className={`ikt-ws_shop-chips-filter-item${isActive ? ` ikt-ws_is-active` : ``}`}
                onClick={this.onOptionsClick(option.id)}>
                {option.value}
            </div>);

            return optionJSX;
        })
    }

    onAllOptionClick = () => {
        return () => {
            const { selectAllFilterOptionAndFilterProducts, filterData }  = this.props;
            selectAllFilterOptionAndFilterProducts(filterData.id);
        };
    }

    onOptionsClick = (optionId) => {
        return () => {
            const { toggleSelectedFilterOptionsAndFilterProducts, filterData} = this.props;
            toggleSelectedFilterOptionsAndFilterProducts({filterId: filterData.id, optionId});
        }
    }

    render() {
        const { filterData, selectedFilterData, toggleSelectedFilterOptions }  = this.props;

        const isAllOptionActive = selectedFilterData.selectedOptions &&
            selectedFilterData.selectedOptions.length === 0;

        return (
            <div className="ikt-ws_shop-filter-chips-content">
                <div className={`ikt-ws_shop-filter-all-item${ isAllOptionActive ? ` ikt-ws_is-active` : `` }` }
                     onClick={this.onAllOptionClick()}>
                    All
                </div>
                {
                    this.renderFilterOptions(filterData, selectedFilterData, toggleSelectedFilterOptions)
                }
            </div>
        )
    }
};

export default ShopChipsFilterContent;

