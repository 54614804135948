import React from 'react';
import ButtonBlue from '../../common/ButtonBlue/ButtonBlue';
import { renderPrice } from '../../../helpers/priceHelpers';
import { Link } from 'react-router-dom';

import './productCard.scss';
import { materials, sizes, styles, colors } from '../../../epics/epicsData';

class ProductCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeColorIndex: 0
    };
  }

  onColorClick = index => {
    return () => {
      this.setState({
        activeColorIndex: index
      });
    };
  };

  render() {
    const { data } = this.props;
    const price = renderPrice(data.price);
    const material = materials.find(material => {
      return material.id === data.materialId;
    });
    const size = sizes.find(size => {
      return size.id === data.sizeId;
    });
    const style = styles.find(style => {
      return style.id === data.styleId;
    });
    const productCardColors = colors.filter(color => {
      return data.colorIds.includes(color.id);
    });

    return (
      <div
        className={`ikt-ws_product-card${
          this.props.className ? ` ${this.props.className}` : ``
        }`}
      >
        <div className="ikt-ws_product-card-top">
          <Link
            className="ikt-ws_product-card-image"
            to={`/product-details/${data.id}`}
            style={{ backgroundImage: `url(${data.image})` }}
          ></Link>
          <div className="ikt-ws_product-card-style">{style.value}</div>
        </div>
        <div className="ikt-ws_product-card-bottom">
          <div className="ikt-ws_product-card-title">{data.title}</div>
          <div className="ikt-ws_product-card-material-n-size">
            {`${material.value} (${size.value})`}
          </div>
          <div className="ikt-ws_product-card-colors">
            {productCardColors &&
              productCardColors.map((color, index) => {
                const { activeColorIndex } = this.state;
                const colorValueLowerCase = color.value.toLowerCase();
                let colorBtnContentBorderColor;
                if (
                  colorValueLowerCase === '#fff' ||
                  colorValueLowerCase === '#ffffff'
                ) {
                  colorBtnContentBorderColor = '#d7d7d7';
                } else {
                  colorBtnContentBorderColor = colorValueLowerCase;
                }

                const isActive = activeColorIndex === index;
                const activeColorBorder = '#000';

                colorBtnContentBorderColor = isActive
                  ? activeColorBorder
                  : colorBtnContentBorderColor;
                const colorBtnContentBgColor = isActive ? '' : color.value;

                return (
                  <div
                    key={index}
                    className={`ikt-ws_product-card-color-btn-content${
                      isActive ? ` ikt-ws_is-active` : ``
                    }`}
                    onClick={this.onColorClick(index)}
                  >
                    <div
                      className="ikt-ws_product-card-color-btn-outer"
                      style={{
                        backgroundColor: colorBtnContentBgColor,
                        borderColor: colorBtnContentBorderColor
                      }}
                    >
                      <div
                        className="ikt-ws_product-card-color-btn-inner"
                        style={{ backgroundColor: color.value }}
                      ></div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="ikt-ws_product-card-price">{price}</div>
          <ButtonBlue
            className="ikt-ws_product-card-more-info-btn"
            to={`/product-details/${data.id}`}
          >
            MORE INFO
          </ButtonBlue>
        </div>
      </div>
    );
  }
}

export default ProductCard;
