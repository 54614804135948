import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getCitiesSuccessful,
  getCitiesFailed
} from '../store/actions/cityActions';
import * as CityActionTypes from '../store/actionTypes/cityActionTypes';
import { dummyCities } from './epicsData';

export const getCities = actions$ => {
  return actions$.pipe(
    ofType(CityActionTypes.GET_CITIES),
    delay(2000),
    mapTo(getCitiesSuccessful(dummyCities)),
    catchError((error, caught) => merge(of(getCitiesFailed(error)), caught))
  );
};
