import React from 'react';

import DropdownIcon from '../../../assets/images/components/DropdownIcon';
import './dropdownImage.scss';

/**
 * Usage in components:
 *
 * <DropdownImage className="some-class"
 *     items={[{ value: 'test1', image: imageLink1 },
 *          { value: 'test2', image: imageLink2 },
 *          { value: 'test3', image: imageLink2 }]}
 *     placeholder="Please Select"
 *     showPlaceholder={true}
 *     initialItemIndex={1}
 *     onClick={(index) => { console.log("Item with index selected: ", index); }}>
 * </DropdownImage>
 */

class DropdownImage extends React.Component {
  constructor(props) {
    super(props);

    this.placeholderText = props.placeholder
      ? props.placeholder
      : 'Please Select';
    this.state = {
      isActive: false,
      isShowPlaceholderState: true,
      currentItemIndex: props.initialItemIndex || 0
    };

    this.dropdownTriggerRef = React.createRef();
  }

  dropdownToggle = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({
        isActive: !this.state.isActive
      });
    }
  };

  hideDropdown = event => {
    const currTriggerBtn = event.target.closest(
      '.ikt-ws_dropdown-image-trigger'
    );

    if (!currTriggerBtn || currTriggerBtn !== this.dropdownTriggerRef.current) {
      this.setState({
        isActive: false
      });
    }
  };

  onItemClick = item => {
    return () => {
      const { disabled, onClick, items } = this.props;

      if (!disabled) {
        this.setState({
          currentItemIndex: items.indexOf(item),
          isShowPlaceholderState: false
        });
        if (onClick) {
          onClick(item.id);
        }
      }
    };
  };

  componentDidMount() {
    document.body.addEventListener('click', this.hideDropdown, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.hideDropdown);
  }

  renderDropdownButtonText = () => {
    const { currentItemIndex, isShowPlaceholderState } = this.state;
    const { items, showPlaceholder } = this.props;
    let buttonText = '';
    if (showPlaceholder && isShowPlaceholderState) {
      buttonText = this.placeholderText;
    } else if (items && items[currentItemIndex].value) {
      buttonText = items[currentItemIndex].value;
    }

    return buttonText;
  };

  renderDropdownButtonImage() {
    const { currentItemIndex, isShowPlaceholderState } = this.state;
    const { items, showPlaceholder } = this.props;
    let buttonImage;
    if (
      (showPlaceholder && isShowPlaceholderState) ||
      !items[currentItemIndex].image
    ) {
      buttonImage = (
        <div className="ikt-ws_dropdown-image-image placeholder">?</div>
      );
    } else {
      buttonImage = (
        <div
          className="ikt-ws_dropdown-image-image"
          style={{ backgroundImage: `url(${items[currentItemIndex].image})` }}
        ></div>
      );
    }

    return buttonImage;
  }

  renderDropdownOptionImage(item) {
    let optionImage;
    if (!item.image) {
      optionImage = (
        <div className="ikt-ws_dropdown-image-image placeholder">?</div>
      );
    } else {
      optionImage = (
        <div
          className="ikt-ws_dropdown-image-image"
          style={{ backgroundImage: `url(${item.image})` }}
        ></div>
      );
    }

    return optionImage;
  }

  render() {
    const { isActive, currentItemIndex } = this.state;
    const { items, disabled, error } = this.props;

    let color = '#000';
    if (disabled) {
      color = 'rgba(0, 0, 0, 0.5)';
    }

    let className = `ikt-ws_dropdown-image${
      isActive ? ` ikt-ws_is-active ` : ``
    }${disabled ? ` disabled` : ``}`;
    className = `${className}${
      this.props.className ? ` ${this.props.className}` : ``
    }`;
    className = `${className}${error ? ` error` : ``}`;

    return (
      <div className={className}>
        <div
          className="ikt-ws_dropdown-image-trigger"
          ref={this.dropdownTriggerRef}
        >
          <button
            className="ikt-ws_dropdown-image-button"
            onClick={this.dropdownToggle}
          >
            {this.renderDropdownButtonImage()}
            <span className="ikt-ws_dropdown-image-button-text">
              {this.renderDropdownButtonText()}
            </span>
            <DropdownIcon
              className="ikt-ws_dropdown-image-chevron"
              color={color}
            />
          </button>
        </div>
        <div className="ikt-ws_dropdown-image-menu">
          <div className="ikt-ws_dropdown-image-content">
            {items &&
              items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ikt-ws_dropdown-image-item"
                    onClick={this.onItemClick(item)}
                  >
                    {this.renderDropdownOptionImage(item)}
                    <div>{item.value}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default DropdownImage;
