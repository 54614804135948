import React from 'react';

import Button from '../Button/Button';
import './buttonGold.scss';

/**
 * Usage:
 * <ButtonGold className="ikt-ws_make-own-cabinet">
 *     MAKE YOUR OWN CABINET
 * </ButtonGold>
 */

const ButtonGold = props => {
  return (
    <Button
      {...props}
      className={`gold${props.className ? ` ${props.className}` : ``}`}
    >
      {props.children}
    </Button>
  );
};

export default ButtonGold;
