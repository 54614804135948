import pin from "../../assets/images/contacts/pin.svg";
import mapbuilding from "../../assets/images/contacts/mapbuilding.png";

const LocationPin = ({ text }) => (
  <div className="pin">
    <img icon={mapbuilding} className="building-icon" />
    <img icon={pin} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);
export default LocationPin;
