import * as FiltersActionTypes from '../actionTypes/filtersActionTypes';

const FILTER_PRICE_TYPE = 'price';
const initState = {
  filters: [],
  filtersError: null,
  filtersBusy: false,
  selectedFilters: []
};

const filtersReducer = (state = initState, action) => {
  let selectedFilters, filterData, filterId;

  switch (action.type) {
    case FiltersActionTypes.GET_FILTERS:
      return {
        ...state,
        filtersBusy: true
      };
    case FiltersActionTypes.GET_FILTERS_SUCCESS:
      const filters = action.payload;
      let initSelectedFilters;

      if (!state.selectedFilters || state.selectedFilters.length === 0) {
        initSelectedFilters =
          filters &&
          filters.map(filter => {
            let selectedFilter;

            if (filter.type === FILTER_PRICE_TYPE) {
              selectedFilter = {
                filterId: filter.id,
                filterType: filter.type,
                isActive: false,
                priceFrom: null,
                priceTo: null
              };
            } else {
              selectedFilter = {
                filterId: filter.id,
                filterType: filter.type,
                selectedOptions: []
              };
            }

            return selectedFilter;
          });
      } else {
        initSelectedFilters = state.selectedFilters;
      }

      return {
        ...state,
        filters,
        selectedFilters: initSelectedFilters,
        filtersError: null,
        filtersBusy: false
      };
    case FiltersActionTypes.GET_FILTERS_ERROR:
      return {
        ...state,
        filtersError: action.payload.message,
        filtersBusy: false
      };
    case FiltersActionTypes.TOGGLE_SELECTED_FILTER_OPTIONS:
      const newSelectedFilterData = action.payload;
      selectedFilters = state.selectedFilters.map(filter => {
        if (filter.filterId === newSelectedFilterData.filterId) {
          const optionIndex = filter.selectedOptions.indexOf(
            newSelectedFilterData.optionId
          );
          if (optionIndex === -1) {
            filter.selectedOptions.push(newSelectedFilterData.optionId);
          } else {
            filter.selectedOptions.splice(optionIndex, 1);
          }
        }

        return filter;
      });

      return {
        ...state,
        selectedFilters
      };
    case FiltersActionTypes.SELECT_ALL_FILTER_OPTION:
      filterId = action.payload;
      selectedFilters = state.selectedFilters.map(selectedFilter => {
        if (selectedFilter.filterId === filterId) {
          selectedFilter.selectedOptions = [];
        }

        return selectedFilter;
      });

      return {
        ...state,
        selectedFilters
      };
    case FiltersActionTypes.ACTIVATE_PRICE_FILTER:
      filterData = action.payload;
      selectedFilters = state.selectedFilters.map(selectedFilter => {
        if (selectedFilter.filterId === filterData.filterId) {
          selectedFilter.isActive = true;
          selectedFilter.priceFrom = filterData.priceFrom;
          selectedFilter.priceTo = filterData.priceTo;
        }

        return selectedFilter;
      });

      return {
        ...state,
        selectedFilters
      };
    case FiltersActionTypes.DEACTIVATE_PRICE_FILTER:
      filterId = action.payload;
      selectedFilters = state.selectedFilters.map(selectedFilter => {
        if (selectedFilter.filterId === filterId) {
          selectedFilter.isActive = false;
        }

        return selectedFilter;
      });

      return {
        ...state,
        selectedFilters
      };
    case FiltersActionTypes.ACTIVATE_DISTANCE_FILTER:
      filterData = action.payload;
      selectedFilters = state.selectedFilters.map(selectedFilter => {
        if (selectedFilter.filterId === filterData.filterId) {
          selectedFilter.isActive = true;
          selectedFilter.radius = filterData.radius;
        }

        return selectedFilter;
      });

      return {
        ...state,
        selectedFilters
      };
    case FiltersActionTypes.DEACTIVATE_DISTANCE_FILTER:
      filterId = action.payload;
      selectedFilters = state.selectedFilters.map(selectedFilter => {
        if (selectedFilter.filterId === filterId) {
          selectedFilter.isActive = false;
        }

        return selectedFilter;
      });

      return {
        ...state,
        selectedFilters
      };
    default:
      return state;
  }
};

export default filtersReducer;
