import * as SignUpActionTypes from '../actionTypes/signUpActionTypes';

const initState = {
  email: ''
};

const signUpReducer = (state = initState, action) => {
  switch (action.type) {
    case SignUpActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      };
    default:
      return state;
  }
};

export default signUpReducer;
