import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {addProduct} from "../../store/actions/cartActions";

import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import Loader from '../common/Loader/Loader';
import SettingsCard from './CabinetSettingsCard';

class CabinetDetails extends React.Component {
    isAllDataReady() {
        const { customCabinets, materials, sizes, colors } = this.props;
        return customCabinets && Object.keys(customCabinets).length > 0 &&
            materials && materials.length > 0 &&
            sizes && sizes.length > 0 &&
            colors && colors.length > 0
    }

    render () {
        const {customCabinets, sizes} = this.props;
        const cabinetId = parseInt(this.props.match.params.id);
        const cabinet = customCabinets.filter( item => item.id === cabinetId)[0];
        const isShowCabinet = this.isAllDataReady();
        return (
            <div className="rows cabinet-details">
                {
                    isShowCabinet
                        ?
                            (<>
                                <div className="row">
                                    <div className="columns is-marginless">
                                        <div className="column is-4 is-offset-2">
                                            <img className="cabinet-img" src={cabinet.image} />
                                        </div>
                                        <div className="column rows is-4 is-offset-1">
                                            <div className="row cabinet-title">{cabinet.title}</div>
                                            <div className="row columns">
                                                <div className="column is-2">
                                                    <div className="custom-btn">
                                                        Custom
                                                    </div>
                                                </div>
                                                <div className="column is-5 is-offset-5">
                                                    <ButtonTeal className="edit-btn">EDIT</ButtonTeal>
                                                </div>
                                            </div>
                                            <div className="row product-size">
                                                {`Size ${sizes.find(item => item.id === cabinet.sizeId).value}`}
                                            </div>
                                            <div className="row columns product-settings">
                                                <div className="column">
                                                    <SettingsCard title="Skeleton" name="Select Material" />
                                                </div>
                                                <div className="column">
                                                    <SettingsCard title="Handles" name="Select handles" />
                                                </div>
                                            </div>
                                            <div className="row product-settings">
                                                <SettingsCard title="Doors" name="Select Material" />
                                            </div>
                                            <div className="row">
                                                <ButtonTeal
                                                    className="add-to-cart-btn"
                                                    onClick={() => this.props.addProduct(cabinet)}
                                                >
                                                    ADD TO CART
                                                </ButtonTeal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column is-offset-2 is-2 note-title">
                                        Notes
                                    </div>
                                    <div className="column is-offset-2 is-8 note-text">
                                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                                    </div>
                                </div>
                            </>)
                        :
                            (<div className="ikt-ws_loader cabinet-loader">
                                <Loader />
                            </div>)
                }
            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        customCabinets: state.userAccount.customCabinets,
        sizes: state.sizes.sizes,
        materials: state.materials.materials,
        colors: state.colors.colors,
        isAuthorized: state.auth.isAuthorized,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProduct: (product) => { dispatch(addProduct(product)) }
    }
};

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CabinetDetails));
