import { withRouter } from 'react-router';
import {
  checkAuthorization,
} from '../../store/actions/authActions';
import { connect } from 'react-redux';

const Auth0Callback = props => {
  const params = new URL(document.location.href.replace('#', '?')).searchParams;
  const state = params.get('state');
  const accessToken = params.get('access_token');
  props.checkAuthorization({auth0Jwt: accessToken});
  props.history.push(state || '/');
  return null;
};

const mapStateToProp = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    checkAuthorization: options => dispatch(checkAuthorization(options))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Auth0Callback));
