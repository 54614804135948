import React from 'react';

import Input from '../common/Input/Input';
import ButtonShaded from "../common/ButtonShaded/ButtonShaded";
import ButtonTeal from "../common/ButtonTeal/ButtonTeal";
import PaymentCard from './PaymentCard';

const cardsData = [
    {
        cardType: 'MasterCard',
        cardNumber: '5989',
        cardName: 'Johannes Ellmeier'
    }
];

class PaymentPage extends React.Component {

    cardInputOne;
    cardInputTwo;
    cardInputTree;
    cardInputFour;
    cardInputMonth;
    cardInputYear;

    constructor(props) {
        super(props);

        this.state = {
            cardNumber: [],
            cardName: '',
            cardValidDate: [],
            cvv: '',
            focused: false,
            selectedCard: {}
        };

        this.cardInputOne = React.createRef();
        this.cardInputTwo = React.createRef();
        this.cardInputTree = React.createRef();
        this.cardInputFour = React.createRef();
        this.cardInputMonth = React.createRef();
        this.cardInputYear = React.createRef();

    }

    onInputFocus = e => {
        e.target.select();
        this.setState({focused: true});
    };

    onInputBlur = () => {
        this.setState({focused: false});
    };

    onCardNumberInputsChange = (id) => {
        const {onChange} = this.props;
        const {cardNumber} = this.state;
        const inputRefs = [
            this.cardInputOne,
            this.cardInputTwo,
            this.cardInputTree,
            this.cardInputFour
        ];
        const value = inputRefs[id].current.value;

        if (value.length <= 4) {
            cardNumber[id] = value;
        } else {
            if (id !== 3) {
                const elem = inputRefs[id + 1];
                elem.current.focus();
            } else {
                this.cardInputOne.current.focus();
            }
        }
        onChange('cardNumber', cardNumber);
        this.setState({
            cardNumber
        })
    };

    onNameChange = (val) => {
        const {onChange} = this.props;
        onChange('cardName', val);
        this.setState({
            cardName: val
        })
    };

    onCardDateChange = (id) => {
        const {onChange} = this.props;
        const {cardValidDate} = this.state;
        const inputRefs = [
            this.cardInputMonth,
            this.cardInputYear
        ];
        const value = inputRefs[id].current.value;
        if (value.length <= 2) {
            cardValidDate[id] = value;
        } else {
            if (id === 0) {
                const elem = inputRefs[id + 1];
                elem.current.focus();
            } else {
                this.cardInputMonth.current.focus();
            }
        };
        onChange('cardValidDate', cardValidDate);
        this.setState({
            cardValidDate
        });
    };

    onSelectCard = (id) => {
        const selectedCard = cardsData[id];
        this.setState({
            selectedCard
        })
    };

    onCvvChange = (val) => {
        const {onChange} = this.props;
        const {selectedCard} = this.state;
        selectedCard.cvv = val;
        onChange('cvv', val);
        this.setState({
            selectedCard,
            cvv: val
        })
    };

    render () {
        const {errors} = this.props;
        const {cardNumber, cardName, cardValidDate, cvv} = this.props.data;
        const {selectedCard} = this.state;
        return (
            <>
                <div className="row payment-cards">
                    <ButtonTeal className="payment-cards-btn">CREDIT CARD</ButtonTeal>
                    <ButtonShaded className="payment-cards-btn payment-cards-btn-passive">PAYPAL</ButtonShaded>
                    <ButtonShaded className="payment-cards-btn payment-cards-btn-passive">STRIPE</ButtonShaded>
                </div>
                {cardsData.map((el, index) =>
                    <PaymentCard
                        data={el}
                        id={index}
                        isCardSelected={selectedCard.cardNumber && el.cardNumber === selectedCard.cardNumber}
                        key={index}
                        onSelectCard={this.onSelectCard}
                        onCvvChange={this.onCvvChange}
                    />)
                }
                <div className="row payment-card-details">
                    <div className="column is-2">
                        <div className="payment-card-title">New Card</div>
                        <div className="payment-card-numbers">
                            <Input
                                borderRadius="left"
                                className="payment-card-number"
                                id="0"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardNumberInputsChange(0)}
                                placeholder={"XXXX"}
                                error={errors.cardNumber}
                                ref={this.cardInputOne}
                                value={cardNumber ? cardNumber[0] : ''}
                            />
                            <Input
                                borderRadius="none"
                                className="payment-card-number"
                                id="1"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardNumberInputsChange(1)}
                                placeholder={"XXXX"}
                                error={errors.cardNumber}
                                ref={this.cardInputTwo}
                                value={cardNumber ? cardNumber[1] : ''}
                            />
                            <Input
                                borderRadius="none"
                                className="payment-card-number"
                                id="2"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardNumberInputsChange(2)}
                                placeholder={"XXXX"}
                                error={errors.cardNumber}
                                ref={this.cardInputTree}
                                value={cardNumber ? cardNumber[2] : ''}
                            />
                            <Input
                                borderRadius="right"
                                className="payment-card-number"
                                id="3"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardNumberInputsChange(3)}
                                placeholder={"XXXX"}
                                error={errors.cardNumber}
                                ref={this.cardInputFour}
                                value={cardNumber ? cardNumber[3] : ''}
                            />
                        </div>
                        <Input
                            className="payment-card-input"
                            onChange={e => this.onNameChange(e.target.value)}
                            value={cardName ? cardName : ''}
                            placeholder={"Name on Card"}
                            error={errors.cardName}
                        />
                        <div className="payment-card-date">
                            <Input
                                borderRadius="left"
                                className="payment-card-month"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardDateChange(0)}
                                placeholder={"MM"}
                                error={errors.cardValidDate}
                                ref={this.cardInputMonth}
                                value={cardValidDate ? cardValidDate[0] : ''}
                            />
                            <Input
                                borderRadius="right"
                                className="payment-card-month"
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                onChange={e => this.onCardDateChange(1)}
                                placeholder={"YY"}
                                error={errors.cardValidDate}
                                ref={this.cardInputYear}
                                value={cardValidDate ? cardValidDate[1] : ''}
                            />
                        </div>
                        <Input
                            className="payment-card-input-short"
                            onChange={e => this.onCvvChange(e.target.value)}
                            value={cvv ? cvv : ''}
                            error={errors.cvv}
                            placeholder={"CVV"}
                        />
                    </div>
                    <div className="column">
                    </div>
                </div>
            </>
        )
    }
}

export default PaymentPage;

