import React from 'react';

import FooterBar from './FooterBar';
import FooterMenu from './FooterMenu';

const Footer = () => {
  return (
    <footer className="footer">
      <FooterMenu />
      <FooterBar />
    </footer>
  );
};

export default Footer;
