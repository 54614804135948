import * as MaterialsActionTypes from '../actionTypes/materialsActionTypes';

export const getMaterials = () => {
  return { type: MaterialsActionTypes.GET_MATERIALS };
};

export const getMaterialsSuccessful = materials => {
  return {
    type: MaterialsActionTypes.GET_MATERIALS_SUCCESS,
    payload: materials
  };
};

export const getMaterialsFailed = error => {
  return { type: MaterialsActionTypes.GET_MATERIALS_ERROR, payload: error };
};
