import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getSingleProduct } from '../../store/actions/singleProductActions';
import { addProduct } from '../../store/actions/cartActions';
import { withRouter } from 'react-router';
import Dropdown from '../common/Dropdown/Dropdown';
import ButtonTeal from '../common/ButtonTeal/ButtonTeal';
import ButtonBlue from '../common/ButtonBlue/ButtonBlue';
import Loader from '../common/Loader/Loader';
import Counter from '../common/Counter/Counter';
import { renderPrice } from '../../helpers/priceHelpers';
import SimilarProductsSlider from './SimilarProductsSlider/SimilarProductsSlider';
import ProductImageSlider from './ProductImageSlider/ProductImageSlider';
import FooterSignUp from '../common/FooterSignUp';
import { deepClone } from '../../helpers/deepCloneHelper';
import { renderDate } from '../../helpers/renderDate';

import starChecked from '../../assets/images/stars/starChecked.svg';
import starNotChecked from '../../assets/images/stars/starNotChecked.svg';
import starHalfChecked from '../../assets/images/stars/starHalfChecked.svg';
import currUserImage from '../../assets/images/users/photo3.jpg';

import './productDetailsPage.scss';

class ProductDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeColorIndex: 0,
      productsAmount: 1,
      activeImageIndex: 0,
      reviewRate: 0
    };
  }

  isColorSectionDataReady = (colors, product) => {
    return colors && colors.length > 0 && product && product.colorIds;
  };

  onColorClick = index => {
    return () => {
      this.setState({
        activeColorIndex: index
      });
    };
  };

  renderColors = () => {
    const { colors, product } = this.props;

    return (
      this.isColorSectionDataReady(colors, product) && (
        <div className="ikt-ws_product-details-colors">
          <div className="ikt-ws_product-details-colors-text">Color</div>
          <div className="ikt-ws_product-details-colors-options">
            {product.colorIds.map((colorId, index) => {
              const color = colors.find(currColor => {
                return currColor.id === colorId;
              });

              const { activeColorIndex } = this.state;
              const colorValueLowerCase = color.value.toLowerCase();
              let colorBtnContentBorderColor;
              if (
                colorValueLowerCase === '#fff' ||
                colorValueLowerCase === '#ffffff'
              ) {
                colorBtnContentBorderColor = '#d7d7d7';
              } else {
                colorBtnContentBorderColor = colorValueLowerCase;
              }

              const isActive = activeColorIndex === index;
              const activeColorBorder = '#000';

              colorBtnContentBorderColor = isActive
                ? activeColorBorder
                : colorBtnContentBorderColor;
              const colorBtnContentBgColor = isActive ? '' : color.value;

              return (
                <div
                  key={index}
                  className={`ikt-ws_product-details-color-btn-content${
                    isActive ? ` ikt-ws_is-active` : ``
                  }`}
                  onClick={this.onColorClick(index)}
                >
                  <div
                    className="ikt-ws_product-details-color-btn-outer"
                    style={{
                      backgroundColor: colorBtnContentBgColor,
                      borderColor: colorBtnContentBorderColor
                    }}
                  >
                    <div
                      className="ikt-ws_product-details-color-btn-inner"
                      style={{ backgroundColor: color.value }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  };

  getSizeData = (sizes, product) => {
    let size = {};

    if (sizes && sizes.length > 0 && product && product.sizeId !== undefined) {
      size = sizes.filter(currSize => {
        return currSize.id === product.sizeId;
      })[0];
    }

    return size;
  };

  renderSizes = () => {
    const { sizes, product } = this.props;
    const size = this.getSizeData(sizes, product);

    return (
      size.value && (
        <div className="ikt-ws_product-details-sizes">
          <div className="ikt-ws_product-details-size-text">Size</div>
          <Dropdown
            className="ikt-ws_product-details-size-dropdown"
            items={[size]}
            disabled={true}
          />
        </div>
      )
    );
  };

  onCounterChange = amount => {
    this.setState({
      productsAmount: amount
    });
  };

  renderQuantity() {
    return (
      this.isAllDataReady() && (
        <div className="ikt-ws_product-details-quantity">
          <div className="ikt-ws_product-details-quantity-text">Qty</div>
          <Counter onChange={this.onCounterChange} />
        </div>
      )
    );
  }

  isDepartmentsSectionDataReady = (departments, product) => {
    return (
      departments && departments.length > 0 && product && product.departmentIds
    );
  };

  renderDepartments = () => {
    const { departments, product } = this.props;

    return (
      this.isDepartmentsSectionDataReady(departments, product) && (
        <div className="ikt-ws_product-details-departments">
          <div className="ikt-ws_product-details-departments-text">
            Departments
          </div>
          <div className="ikt-ws_product-details-departments-chips">
            {product.departmentIds.map((departmentId, index) => {
              const department = departments.find(currDepartment => {
                return currDepartment.id === departmentId;
              });

              return (
                <div
                  key={index}
                  className="ikt-ws_product-details-departments-chips-item"
                >
                  {department.value}
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  };

  renderStyle(style) {
    return (
      style &&
      style.value && (
        <div className="ikt-ws_product-details-product-style">
          {style && style.value}
        </div>
      )
    );
  }

  renderPrice(product) {
    return (
      product &&
      product.price && (
        <div className="ikt-ws_product-details-product-price">
          {renderPrice(product.price)}
        </div>
      )
    );
  }

  isAllDataReady() {
    const {
      product,
      materials,
      styles,
      colors,
      departments,
      sizes
    } = this.props;

    if (
      Object.keys(product).length > 0 &&
      materials &&
      materials.length > 0 &&
      styles &&
      styles.length > 0 &&
      colors &&
      colors.length > 0 &&
      departments &&
      departments.length > 0 &&
      sizes &&
      sizes.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  onAddProduct = () => {
    const { product, addProduct, colors } = this.props;
    const { productsAmount, activeColorIndex } = this.state;
    toast.success(
      `Product "${product.title}" was added to cart (amount equal ${productsAmount})`
    );
    let cartProduct = deepClone(product);
    delete cartProduct.colorIds;
    delete cartProduct.details;
    delete cartProduct.reviews;

    const colorId = product.colorIds[activeColorIndex];

    // TODO: add selected sizeId if it will be decided to use more then one size
    // in product sizes dropdown

    cartProduct.colorId = colorId;
    cartProduct.amount = productsAmount;
    addProduct(cartProduct);
  };

  renderAddToCartBtn() {
    return (
      this.isAllDataReady() && (
        <ButtonTeal
          onClick={this.onAddProduct}
          className="ikt-ws_product-details-add-to-cart-btn"
        >
          ADD TO CART
        </ButtonTeal>
      )
    );
  }

  onSliderImageClick = index => {
    this.setState({
      activeImageIndex: index
    });
  };

  renderDetails() {
    const { product } = this.props;

    let productDetailsContent;
    if (product.details) {
      productDetailsContent = product.details.split(/\r?\n/);
    }

    return (
      product &&
      product.details && (
        <div className="ikt-ws_product-details-page-reviews">
          <div className="ikt-ws_product-details-page-reviews-heading">
            Details
          </div>
          <div className="ikt-ws_product-details-page-reviews-content">
            {productDetailsContent.map((detailsParagraph, index) => {
              return (
                <p
                  key={index}
                  className="ikt-ws_product-details-page-reviews-paragraph"
                >
                  {detailsParagraph}
                </p>
              );
            })}
          </div>
        </div>
      )
    );
  }

  calculateRateData(reviewRate) {
    let rate = reviewRate;
    if (rate % 1 !== 0) {
      rate =
        reviewRate % 1 >= 0.5
          ? parseInt(reviewRate) + 1
          : parseInt(reviewRate) + 0.5;
    }
    let rateContent = [];
    const MAX_RATE = 5;
    for (let i = 0; i < MAX_RATE; i++) {
      if (rate % 1 !== 0 && i === parseInt(rate)) {
        rateContent.push({
          state: 'halfChecked'
        });
      } else if (i < rate) {
        rateContent.push({
          state: 'checked'
        });
      } else if (i >= rate) {
        rateContent.push({
          state: 'notChecked'
        });
      }
    }

    return rateContent;
  }

  renderRate(rate) {
    const rateContent = this.calculateRateData(rate);

    return (
      <div className="ikt-ws_product-details-page-product-reviews-rate">
        {rateContent.map((rate, index) => {
          let rateElement;
          switch (rate.state) {
            case 'checked':
              rateElement = <img src={starChecked} />;
              break;
            case 'notChecked':
              rateElement = <img src={starNotChecked} />;
              break;
            case 'halfChecked':
              rateElement = <img src={starHalfChecked} />;
              break;
          }

          return (
            <div
              key={index}
              className="ikt-ws_product-details-page-product-reviews-rate-star"
            >
              {rateElement}
            </div>
          );
        })}
      </div>
    );
  }

  onPostReviewStarClick = index => {
    return () => {
      this.setState({
        reviewRate: index
      });
    };
  };

  renderPostReview() {
    const { reviewRate } = this.state;
    let postRateContent = [];
    const MAX_RATE = 5;

    for (let i = 0; i < MAX_RATE; i++) {
      postRateContent.push({
        state: i < reviewRate ? 'checked' : 'notChecked'
      });
    }

    return (
      <div className="ikt-ws_product-details-page-product-reviews-post-review">
        <div className="ikt-ws_product-details-page-product-reviews-post-review-user-photo-wrapper">
          <div
            className="ikt-ws_product-details-page-product-reviews-post-review-user-photo"
            style={{ backgroundImage: `url(${currUserImage})` }}
          ></div>
        </div>
        <div className="ikt-ws_product-details-page-product-reviews-post-review-controls">
          <div className="ikt-ws_product-details-page-product-reviews-post-review-heading">
            Your Review
          </div>
          <div className="ikt-ws_product-details-page-product-reviews-post-review-rate">
            {postRateContent.map((rate, index) => {
              let rateElement;
              switch (rate.state) {
                case 'checked':
                  rateElement = <img src={starChecked} />;
                  break;
                case 'notChecked':
                  rateElement = <img src={starNotChecked} />;
                  break;
                case 'halfChecked':
                  rateElement = <img src={starHalfChecked} />;
                  break;
              }

              return (
                <div
                  key={index}
                  onClick={this.onPostReviewStarClick(index + 1)}
                  className="ikt-ws_product-details-page-product-post-review-rate-star"
                >
                  {rateElement}
                </div>
              );
            })}
          </div>
          <textarea
            placeholder="Please enter..."
            className="ikt-ws_product-details-page-product-reviews-post-textarea"
          ></textarea>
          <ButtonBlue className="ikt-ws_product-details-page-add-review-btn">
            ADD REVIEW
          </ButtonBlue>
        </div>
      </div>
    );
  }

  renderReviews() {
    const { product } = this.props;

    return (
      product &&
      product.reviews && (
        <div className="ikt-ws_product-details-page-reviews-container">
          <div className="ikt-ws_product-details-page-reviews-product-reviews">
            <div className="ikt-ws_product-details-page-reviews-heading">
              <div className="ikt-ws_product-details-page-reviews-heading-text">
                Reviews
              </div>
              <div className="ikt-ws_product-details-page-reviews-heading-reviews-amount">
                {product.reviews.length}
              </div>
            </div>
            <div className="ikt-ws_product-details-page-product-reviews-content">
              {product.reviews.map((review, index) => {
                const reviewDate = renderDate(review.timestamp);

                return (
                  <div
                    key={index}
                    className="ikt-ws_product-details-page-product-reviews-item"
                  >
                    <div className="ikt-ws_product-details-page-product-reviews-user-photo-wrapper">
                      <div
                        className="ikt-ws_product-details-page-product-reviews-user-photo"
                        style={{ backgroundImage: `url(${review.image})` }}
                      ></div>
                    </div>
                    <div className="ikt-ws_product-details-page-product-reviews-info-wrapper">
                      <div className="ikt-ws_product-details-page-product-reviews-user-name-n-timestamp">
                        <div className="ikt-ws_product-details-page-product-reviews-user-name">
                          {review.userName}
                        </div>
                        <div className="ikt-ws_product-details-page-product-reviews-timestamp">
                          {reviewDate}
                        </div>
                      </div>
                      {this.renderRate(review.rate)}
                      <div className="ikt-ws_product-details-page-product-reviews-text">
                        {review.textContent}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {this.renderPostReview()}
        </div>
      )
    );
  }

  renderSimilarProductsSlider() {
    const { product } = this.props;
    return (
      product &&
      product.similarProducts && (
        <div className="ikt-ws_product-details-page-similar-products">
          <div className="ikt-ws_product-details-page-similar-product-heading">
            Similar Products
          </div>
          <SimilarProductsSlider products={product.similarProducts} />
        </div>
      )
    );
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let execRes = /product-details\/(\d+)/gi.exec(location.pathname);
      if(execRes) {
        const productId = parseInt(execRes[1]);
        this.props.getSingleProduct(productId);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }
    });

    const productId = parseInt(this.props.match.params.id);
    this.props.getSingleProduct(productId);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  isAllDataReady() {
    const { product, materials, styles } = this.props;

    const isAllDataReady =
      Object.keys(product).length > 0 &&
      materials &&
      materials.length > 0 &&
      styles &&
      styles.length > 0;

    return isAllDataReady;
  }

  render() {
    const { product, materials, styles } = this.props;
    const { activeImageIndex } = this.state;

    let material = materials.find(currMaterial => {
      return currMaterial.id === product.materialId;
    });

    let style = styles.find(currStyle => {
      return currStyle.id === product.styleId;
    });

    const amountOfImages = 14;
    let images = [];
    if (product.image) {
      for (let i = 0; i < amountOfImages; i++) {
        images.push(product.image);
      }
    }

    return (
      <div className="ikt-ws_product-details-page-container">
        {this.isAllDataReady() ? (
          <>
            <div className="ikt-ws_product-details-page-general-info">
              <div className="ikt-ws_product-details-page-general-info-top">
                <div className="ikt-ws_product-details-page-images-section">
                  <div
                    className="ikt-ws_product-details-page-big-image"
                    style={{
                      backgroundImage: product.image
                        ? `url(${images[activeImageIndex]})`
                        : ``
                    }}
                  ></div>
                  <div className="ikt-ws_product-details-page-image-slider">
                    {product.image && (
                      <ProductImageSlider
                        images={images}
                        onImageClick={this.onSliderImageClick}
                      ></ProductImageSlider>
                    )}
                  </div>
                </div>
                <div className="ikt-ws_product-details-page-main-info">
                  <div className="ikt-ws_product-details-product-title">
                    {product.title}
                  </div>
                  <div className="ikt-ws_product-details-product-material-n-style">
                    <div className="ikt-ws_product-details-product-material">
                      {material && material.value}
                    </div>
                    {this.renderStyle(style)}
                  </div>
                  {this.renderColors()}
                  {this.renderSizes()}
                  {this.renderQuantity()}
                  {this.renderPrice(product)}
                  {this.renderAddToCartBtn()}
                  {this.renderDepartments()}
                </div>
              </div>
              {this.renderDetails()}
            </div>
            {this.renderReviews()}
            {this.renderSimilarProductsSlider()}
          </>
        ) : (
          <div className="ikt-ws_loader">
            <Loader />
          </div>
        )}
        <FooterSignUp />
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    product: state.singleProduct.singleProduct,
    materials: state.materials.materials,
    styles: state.styles.styles,
    colors: state.colors.colors,
    departments: state.departments.departments,
    sizes: state.sizes.sizes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSingleProduct: productId => dispatch(getSingleProduct(productId)),
    addProduct: product => dispatch(addProduct(product))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(ProductDetailsPage));
