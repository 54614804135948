import React, { Component } from 'react';
import RegistrationArrow from '../../../assets/images/components/RegistrationArrow';
import ButtonGold from '../../common/ButtonGold/ButtonGold';
import ButtonLinkEmpty from '../../common/ButtonLinkEmpty/ButtonLinkEmpty';
import CheckboxTeal from '../../common/CheckboxTeal/CheckboxTeal';
import Input from '../../common/Input/Input';
import Dropdown from '../../common/Dropdown/Dropdown';
import { Link, withRouter } from 'react-router-dom';

import './resetPassword.scss';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.resetPasswordWrapperRef = React.createRef();
    this.emailInputRef = React.createRef();

    this.state = {
      isMembershipCheckboxChecked: false,
      backToLoginBtnHover: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.shown && this.props.shown) {
      this.resetPasswordWrapperRef.current &&
        this.resetPasswordWrapperRef.current.scrollTo(0, 0);
    }
  }

  toggleBackToLoginBtnHover = () => {
    this.setState({
      backToLoginBtnHover: !this.state.backToLoginBtnHover
    });
  };

  goToLoginPage = () => {
    this.props.showLoginPage();
  };

  onPasswordReset = () => {
    const { resetPassword } = this.props;
    const email = this.emailInputRef.current.value;
    resetPassword(email);
  };

  onEmailInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.onPasswordReset();
    }
  };

  render() {
    const { backToLoginBtnHover } = this.state;
    const { className, email } = this.props;

    const isAddLink = this.props.location.pathname === '/reset-password';

    return (
      <div
        className={`ikt-ws_reset-password-container${
          className ? ` ${className}` : ``
        } `}
      >
        <div
          className="ikt-ws_reset-password-wrapper"
          ref={this.resetPasswordWrapperRef}
        >
          <div className="ikt-ws_reset-password-text">Reset password</div>
          <Input
            placeholder="Email..."
            className="ikt-ws_reset-password-email"
            defaultValue={email ? email : ''}
            ref={this.emailInputRef}
            onKeyDown={this.onEmailInputKeyDown}
          />
          <ButtonGold
            className="ikt-ws_reset-password-reset-password-btn"
            onClick={this.onPasswordReset}
          >
            RESET PASSWORD
          </ButtonGold>
          <ButtonLinkEmpty
            className="ikt-ws_reset-password-back-to-login"
            onMouseEnter={this.toggleBackToLoginBtnHover}
            onMouseLeave={this.toggleBackToLoginBtnHover}
            onClick={this.goToLoginPage}
            to={isAddLink ? `/login` : null}
          >
            <RegistrationArrow
              color={backToLoginBtnHover ? `#1da6b2` : `#1899A5`}
            />
            <div className="ikt-ws_reset-password-back-to-login-text">
              Back to Login
            </div>
          </ButtonLinkEmpty>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
