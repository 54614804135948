import React from 'react';
import Slider from 'rc-slider';

import CheckmarkFilterIcon from '../../../../assets/images/components/CheckmarkFilterIcon';
import './shopDistanceFilterContent.scss';
import 'rc-slider/assets/index.css';

class ShopDistanceFilterContent extends React.Component {
  constructor(props) {
    super(props);

    const { selectedFilterData } = props;

    this.state = {
      isDistanceCheckMarkHover: false,
      radius: selectedFilterData.radius || 0
    };

    this.radiusRef = React.createRef();
  }

  toggleDistanceCheckmarkHover = () => {
    this.setState({
      isDistanceCheckMarkHover: !this.state.isDistanceCheckMarkHover
    });
  };

  onInputKeyDown = event => {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode === ENTER_KEY_CODE) {
      this.onActivateFilter();
    }
  };

  onRadiusChange = value => {
    this.setState({
      radius: value
    });

    const { activateDistanceFilterAndFilterProducts, filterData } = this.props;

    activateDistanceFilterAndFilterProducts({
      filterId: filterData.id,
      radius: value
    });
  };

  render() {
    const { isDistanceCheckMarkHover, radius } = this.state;
    const { selectedFilterData } = this.props;

    const isActive = selectedFilterData.isActive;

    let distanceCheckmarkColor;
    if (isDistanceCheckMarkHover) {
      distanceCheckmarkColor = '#fff';
    }

    return (
      <div className="ikt-ws_shop-filter-distance-content">
        <div
          className={`ikt-ws_shop-distance-filter-bottom${
            isActive ? ` ikt-ws_is-active` : ``
          }`}
        >
          <Slider
            min={0}
            max={1000}
            defaultValue={0}
            step={1}
            onChange={this.onRadiusChange}
          />

          <div className="ikt-ws_shop-distance-filter-distance-checkmark">
            {radius ? (
              <div>
                {radius} <span className="metric">km</span>
              </div>
            ) : (
              <div>Any</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ShopDistanceFilterContent;
