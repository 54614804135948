import * as StylesActionTypes from '../actionTypes/stylesActionTypes';

const initState = {
  styles: [],
  stylesError: null,
  stylesBusy: false
};

const stylesReducer = (state = initState, action) => {
  switch (action.type) {
    case StylesActionTypes.GET_STYLES:
      return {
        ...state,
        stylesBusy: true
      };
    case StylesActionTypes.GET_STYLES_SUCCESS:
      return {
        ...state,
        styles: action.payload,
        stylesError: null,
        stylesBusy: false
      };
    case StylesActionTypes.GET_STYLES_ERROR:
      return {
        ...state,
        stylesError: action.payload.message,
        stylesBusy: false
      };
    default:
      return state;
  }
};

export default stylesReducer;
