import * as FaturedProductsActionTypes from '../actionTypes/featuredProductsActionTypes';

export const getFeaturedProducts = searchParams => {
  return {
    type: FaturedProductsActionTypes.GET_FEATURED_PRODUCTS,
    payload: searchParams
  };
};

export const getFeaturedProductsSuccessful = products => {
  return {
    type: FaturedProductsActionTypes.GET_FEATURED_PRODUCTS_SUCCESS,
    payload: products
  };
};

export const getFeaturedProductsFailed = error => {
  return {
    type: FaturedProductsActionTypes.GET_FEATURED_PRODUCTS_ERROR,
    payload: error
  };
};

export const applyFeaturedProductFilters = filters => {
  return {
    type: FaturedProductsActionTypes.APPLY_PRODUCTS_FILTERS,
    payload: filters
  };
};
