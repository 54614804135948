import React from 'react';

class DepartmentCard extends React.Component {
    render () {
        const {data} = this.props;
        return (
            <div style={{backgroundImage: `url(${data.img})`}} className="dep-card">

                <div className="dep-card-title">
                    {data.value}
                </div>
            </div>
        )
    }
}

export default DepartmentCard;
