import React from 'react';
import './input.scss';

/**
 * Usage in components:
 *
 * <Input className="some-class" />
 */

const Input = React.forwardRef((props, ref) => {
  const { errorMessage, error, borderRadius, className, ...rest } = props;
  let containerClassName = `ikt-ws_input-container${
    className ? ` ${className}` : ``
  }`;
  containerClassName = `${containerClassName}${
    errorMessage || error ? ` error` : ``
  }`;

  const borderRadiusComponent = `${borderRadius ? borderRadius : 'both'}`;

  let borderRadiusClass;
  switch (borderRadiusComponent) {
    case 'left':
      borderRadiusClass = 'left-border-radius';
      break;
    case 'right':
      borderRadiusClass = 'right-border-radius';
      break;
    case 'none':
      borderRadiusClass = 'none-border-radius';
      break;
  }

  containerClassName = `${containerClassName}${
    borderRadiusClass ? ` ${borderRadiusClass}` : ``
  }`;

  return (
    <div className={containerClassName}>
      <input className="ikt-ws_input" ref={ref} {...rest} />
      {errorMessage && (
        <div className="ikt-ws_input-error-message">{errorMessage}</div>
      )}
    </div>
  );
});

export default Input;
