import React from 'react';
import ButtonTeal from '../../common/ButtonTeal/ButtonTeal';
import FooterSignUp from '../../common/FooterSignUp';
import MakeCabinetGetStartedPlaceholder from '../../../assets/images/placeholders/make-cabinet-get-started-placeholder1@2x.png';
import './getStarted.scss';

class GetStarted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="ikt-ws_make-cabinet-get-started-container">
        <div className="ikt-ws_make-cabinet-get-started-wrapper">
          <div className="columns is-gapless">
            <div className="column is-12-touch is-6-desktop is-parent is-vertical ikt-ws_make-cabinet-get-started-left-col">
              <div className="is-child ikt-ws_make-cabinet-get-started-heading">
                Create your custom cabinet
              </div>
              <div className="is-child ikt-ws_make-cabinet-get-started-description">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat, vel
                illum dolore eu feugiat nulla facilisis at vero eros et accumsan
                et iusto odio dignissim qui blandit praesent luptatum zzril
                delenit augue duis dolore te feugait nulla facilisi.
              </div>
              <div className="is-child">
                <ButtonTeal
                  to="/make-cabinet/cabinet-builder"
                  className="ikt-ws_make-cabinet-get-started-btn"
                >
                  GET STARTED
                </ButtonTeal>
              </div>
            </div>
            <div className="column is-12-touch is-6-desktop ikt-ws_make-cabinet-get-started-right-col">
              <img src={MakeCabinetGetStartedPlaceholder} />
            </div>
          </div>
        </div>
        <FooterSignUp />
      </div>
    );
  }
}

export default GetStarted;
